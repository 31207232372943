import React from "react";
import styled from "styled-components";
import Study from "./Study";
import { descend, prop, sort } from "ramda";

export default ({ studies, theme }) => {
  const sortedStudies = sort(descend(prop("study_start")), studies);

  return (
    <Studies>
      {sortedStudies.map((study) => (
        <Study theme={theme} key={study.study_id} study={study} />
      ))}
    </Studies>
  );
};

const Studies = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
