import _ from "lodash";
import roundTimestamp from '../utils/roundTimestamp';
import isTimestamp from '../utils/isTimestamp';

function EventsFiltered({ data, filters, thisStudy }) {

  // -----------------------------------------------------------------
  // applies given filters dynamically, else just return the data back
  // -----------------------------------------------------------------

  if (!filters.filters) {
    return data;
  }


  let mainFiltered = filterData({ filters: filters.filters, data, thisStudy });

  return mainFiltered
}

const filterData = ({ filters, data, thisStudy }) => {

  let construct = data;

  Object.keys(filters).forEach(filter => {


    if (filters[filter].names) {

      const names = filters[filter].names



      construct = construct.filter(row => {
        let currentKeys = []
        Object.keys(filters[filter].keys).forEach(filterKey => {
          currentKeys.push(row[filterKey])
        })

        const lookupName = thisStudy.lookup[names.lookup.type][currentKeys.join('_')]
        if (lookupName) {
          return names.ids.includes(lookupName[names.lookup.formatting])
        }
      })

    }

    // for each dataKey
    Object.keys(filters[filter].keys).forEach(filterKey => {
      // if (filters[filter].keys[filterKey].type === "discreet") {
      //   construct = construct.filter(row =>
      //     filters[filter].keys[filterKey].filterValues.includes(row[filterKey])
      //   );
      // } else 
      if (filters[filter].keys[filterKey].type === "boolean") {
        const toggle = filters[filter].keys[filterKey].filterValues
        if (toggle === true) {
          construct = construct.filter(row => (row[filterKey] !== null && row[filterKey] !== "-1"));
        } else if (toggle === false) {
          // do nothing
          // construct = construct.filter(row => row[filterKey] === null);
        }
      }
      else if (filters[filter].keys[filterKey].type === "range") {

        let val1 = filters[filter].keys[filterKey].filterValues[0]
        let val2 = filters[filter].keys[filterKey].filterValues[1]


        if (isTimestamp(val1)) {
          val1 = roundTimestamp(val1)
        }

        if (isTimestamp(val2)) {
          val2 = roundTimestamp(val2)
        }

        construct = construct.filter(
          row =>
            row[filterKey] >= val1 &&
            row[filterKey] <= val2 &&
            row[filterKey] !== null
        );
      }
    });
  });

  return construct;
};

const compressFilters = ({ filters, thisStudy }) => {
  let construct = _.cloneDeep(filters);

  // selected approach
  Object.keys(filters.filters).forEach(filter => {
    const keys = filters.filters[filter].keys;
    const names = filters.filters[filter].names;
    construct.filters[filter] = {
      keys: minimizeMotions({
        approachSelected: construct.settings.approachSelected,
        keys,
        names,
        thisStudy
      })
    };
  });
  return construct;
};

const generalizeFilters = ({ filters, data }) => {
  let construct = {
    settings: {
      approachSelected: null
    },
    filters: {}
  };

  const allKeys = Object.keys(data[0]);
  const numberOfUsers = getNumberOfUsers({ allKeys });

  if (shouldGeneralize({ filters })) {
    Array(numberOfUsers - 1)
      .fill(1)
      .forEach((_, i) => {
        const userIndex = i + 2;

        Object.keys(filters.filters).forEach(filterName => {
          const newFilterName = makeGeneralized({
            index: userIndex,
            name: filterName
          });
          const names = filters.filters[filterName].names;
          construct.filters[newFilterName] = {
            keys: {},
            names: names
          };

          Object.keys(filters.filters[filterName].keys).forEach(filterKey => {
            const newFilterKey = makeGeneralized({
              index: userIndex,
              name: filterKey
            });
            let currentData = filters.filters[filterName].keys[filterKey];
            construct.filters[newFilterName].keys[newFilterKey] = currentData;
            if (filters.settings.approachSelected) {
              construct.settings.approachSelected = {
                [newFilterKey]: {
                  values: currentData.filterValues,
                  armR: `${newFilterKey.slice(0, newFilterKey.length - 1)}R`
                }
              };
            }
          });
        });
      });
  } else {
    construct = null
  }

  return construct;
};

const getAdditionalKeys = ({ mainKey, allKeys }) => {
  const approachRegex = /arm\d+[A-Z]/;
  const normalRegex = /[A-Za-z]+\d+/;

  if (mainKey.match(approachRegex)) {
    return allKeys
      .filter(d => d.match(approachRegex) && d !== mainKey)
      .filter(d => d.slice(-1) === mainKey.slice(-1));
  } else if (mainKey.match(normalRegex)) {
    return allKeys.filter(d => d.match(normalRegex) && d !== mainKey);
  } else {
    return [];
  }
};

const shouldGeneralize = ({ filters }) => {
  return _.every(
    Object.keys(filters.filters).map(key => {
      const numbersOnly = key.replace(/\D/g, "");

      if (numbersOnly.length) {
        if (Number(numbersOnly[0]) > 1) {
          return false;
        }
      } else {
        if (Number(numbersOnly > 1)) {
          return false;
        }
      }
      return true;
    })
  );
};

const minimizeMotions = ({ approachSelected, keys, names, thisStudy }) => {
  let construct = {};

  Object.keys(approachSelected).forEach(approach => {
    // motion exist?
    if (keys[approach] && keys[approachSelected[approach].armR]) {
      // const selectedMotions = names.forEach(name => {
      //   const armA = approach;
      //   const armR = approachSelected[approach].armR;

      //   construct = {
      //     [armA]: {
      //       filterValues: [],
      //       type: keys[armA].type
      //     },
      //     [armR]: {
      //       filterValues: [],
      //       type: keys[armR].type
      //     }
      //   };

      //   Object.keys(thisStudy.lookup.motions).forEach(d => {
      //     if (names.includes(thisStudy.lookup.motions[d].namesFull)) {
      //       approachSelected[approach].values.forEach(arm => {
      //         if (Number(arm) === Number(d.slice(0, 1))) {
      //           construct[armA].filterValues.push(Number(d.slice(0, 1)));
      //           construct[armR].filterValues.push(Number(d.slice(1)));
      //         }
      //       });
      //     }
      //   });
      // });
    } else {
      construct = {
        keys: keys,
        names: names
      };
    }
  });
  return construct;
};

const validMotions = ({ thisStudy, approachSelected }) => {
  let construct = {};

  Object.keys(approachSelected).forEach(approach => {
    let validArmA = approachSelected[approach].values;
    let validArmR = [];

    validArmA.forEach(armA => {
      // filter all motions with this arm
      Object.keys(thisStudy.lookup.motions).forEach(motion => {
        const motionName = "Through";

        if (
          thisStudy.lookup.motions[motion].namesFull === motionName &&
          Number(motion[0]) === Number(armA)
        ) {
          validArmR.push(Number(motion.slice(1)));
        }
      });
    });

    construct[approach] = validArmA;
    construct[approachSelected[approach].armR] = _.uniq(validArmR);
  });

  return construct;
};

const makeGeneralized = ({ index, name }) => {
  let construct = [];
  name.split("").forEach(letter => {
    if (!isNaN(letter)) {
      construct.push(index);
    } else {
      construct.push(letter);
    }
  });

  return construct.join("");
};

const getNumberOfUsers = ({ allKeys }) => {
  let construct = [];
  allKeys.forEach(d => {
    const numbersOnly = d.replace(/\D/g, "");
    if (numbersOnly.length) {
      construct.push(numbersOnly[0]);
    } else {
      construct.push(numbersOnly);
    }
  });

  return Math.max(...construct);
};

export default EventsFiltered;
export {
  compressFilters,
  generalizeFilters,
  getAdditionalKeys,
  shouldGeneralize,
  filterData,
  minimizeMotions,
  validMotions,
  makeGeneralized
};
