import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import * as compose from "lodash.flowright";
import { graphql } from "react-apollo";
import GetDPI from "../../../../Data/Fetching/QueryLibrary/GetDPI";

const CanvasTraces = ({ data,theme, hoveringRow, selectedRows, dpiSetting }) => {
  const canvas = useRef(null);

  useEffect(() => {
    // define canvas context & clear canvas
    const ctx = canvas.current.getContext("2d");
    // ctx.clearRect(0, 0, width,height);

    // let dpi = window.devicePixelRatio;
    let height = +getComputedStyle(canvas.current)
      .getPropertyValue("height")
      .slice(0, -2);
    let width = +getComputedStyle(canvas.current)
      .getPropertyValue("width")
      .slice(0, -2);

    let dpi = dpiSetting.value
    canvas.current.setAttribute("height", height * dpi);
    canvas.current.setAttribute("width", width * dpi);

    const xScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, width * 2]);

    const yScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, height * 2]);

    let lineData = data;

    let styling = {
      cicleSize: null,
      fillStyle: null,
      strokeStyle: null,
      lineWidth: null,
      user1: {
        strokeStyle: null
      },
      user2: {
        strokeStyle: null
      }
    };

    // // if selections have been made
    // if (selectedRows !== null && selectedRows.length >= 1) {
    //     if (hoveringRow !== null) {
    //         if (!selectedRows.includes(hoveringRow)) {
    //             selectedRows = selectedRows.concat(hoveringRow)
    //         }
    //     }
    //     lineData = data.filter(d=>selectedRows.includes(d.row_id))
    // } else if (hoveringRow !== null) {
    //     lineData = data.filter(d=>d.row_id===hoveringRow)
    // } else {
    //     lineData = data
    // }

    // if (hoveringRow !== null || (selectedRows !== null && selectedRows.length >=1)) {
    //     styling = {
    //         ...styling,
    //         lineWidth: 3,
    //         circleSize: 6,
    //         circleColor: "red",
    //         user1: {
    //             strokeStyle: 'aqua'
    //         },
    //         user2: {
    //             strokeStyle: 'lightgreen'
    //         }
    //     }
    // } else {
    styling = {
      ...styling,
      lineWidth: 1,
      circleSize: 4,
      circleColor: theme.secondaryLight,
      user1: {
        strokeStyle: theme.positive
      },
      user2: {
        strokeStyle: theme.negative
      }
    };

    // line generator
    const line = d3
      .line()
      .x(d => xScale(d.x_pix))
      .y(d => yScale(d.y_pix))
      .curve(d3.curveBasis)
      .context(ctx);

    lineData.forEach((d, i) => {
      // line 1
      ctx.beginPath();
      line(d.lines.line1);
      ctx.lineWidth = styling.lineWidth;
      ctx.strokeStyle = styling.user1.strokeStyle;
      ctx.stroke();
      ctx.lineCap = "round";

      // line 2
      if (d.lines.line2.length >= 1) {
        ctx.beginPath();
        line(d.lines.line2);
        ctx.lineWidth = styling.lineWidth;
        ctx.strokeStyle = styling.user2.strokeStyle;
        ctx.stroke();
        ctx.lineCap = "round";
      }

      if (d.x_pix && d.y_pix) {
        // circle
        // ctx.shadowBlur = 25;
        // ctx.shadowColor = styling.circleColor;
        ctx.beginPath();
        ctx.arc(
          xScale(d.x_pix),
          yScale(d.y_pix),
          styling.circleSize,
          0,
          2 * Math.PI
        );
        ctx.fillStyle = styling.circleColor;
        ctx.strokeStyle = styling.circleColor;
        ctx.fill();
        ctx.stroke();
        // ctx.shadowBlur = 0;
      }
    });
  }, [data, hoveringRow, selectedRows, dpiSetting, theme.positive, theme.negative, theme.primary, theme.secondaryLight]);

  return <Canvas ref={canvas} />;
};

export default compose(
  graphql(GetDPI, {
    props: ({data: { dpiSetting }}) => ({
      dpiSetting
    })
  })
)(CanvasTraces);

const Canvas = styled.canvas`
  position: absolute;
  height: 100%;
  width: 100%;
`;


