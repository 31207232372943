import React, { useState } from "react";
import styled from "styled-components";
import Downloadable from "./Downloadable";

export default ({ downloadables, show }) => {
  const [inProp, setInProp] = useState(false);
  return (
    <Wrapper className={show ? 'show' : 'hide'}>
      <Downloadables>
        {downloadables.map((resource, i) => (
          <Downloadable key={i} resource={resource} />
        ))}
      </Downloadables>
    </Wrapper>
  );
};

const Wrapper = styled.div`

  &.hide {
    max-height:0;
    overflow-y: hidden;
    border: none;
  }

    &.show {
    max-height: 1000px;
    transition: max-height .2s linear;
    /* border-top: solid 1px ${props=>props.theme.primaryLight}; */
    
  }
`;

const Downloadables = styled.div`
  display: flex;
  flex-flow: row wrap;
  background-color: ${({ theme }) => theme.textLight};
  padding-left: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
`;
