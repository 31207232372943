import { useEffect, useState } from 'react';
import useGetPaginatedDataV2 from "../../../../Data/Fetching/useGetPaginatedDataV2";
import { ByColumn } from "../../../../Data/Handlers/DataHandler";
import AddLines from "../../../../Data/Handlers/Events/AddLines";
import { PieChartifyEvents } from "../../../../Data/Handlers/PieChart/PieChartify";
import { EventsFiltered } from "../../../../Data/Handlers/DataHandler";
import _ from "lodash";


const useGpsMapDataFetcher = ({ comp, thisStudy }) => {
  const {
    dataFormatted: eventsData,
    loading: eventsLoading,
    error: eventsError,
  } = useGetPaginatedDataV2({ compID: `${comp.compID}events`, thisStudy });
  const {
    dataFormatted: linesData,
    loading: linesLoading,
    error: linesError,
  } = useGetPaginatedDataV2({ compID: `${comp.compID}lines`, thisStudy });
  const {
    dataFormatted: schematicData,
    loading: schematicLoading,
    error: schematicError,
  } = useGetPaginatedDataV2({ compID: "000", thisStudy });
  const [status, setStatus] = useState('loading');
  const [combinedEvents, setCombinedEvents] = useState(null);
  const [eventsDataFiltered, setEventsDataFiltered] = useState(null);
  const [piechartData, setPiechartData] = useState(null);
  const [filters, setFilters] = useState(null);
  const [filterToggle, setfilterToggle] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(null);


  useEffect(() => {

    if (
      eventsLoading ||
      linesLoading ||
      schematicLoading ||
      (!eventsData && !eventsError) ||
      (!linesData && !linesError) ||
      (!schematicData && !schematicError)
    ) {
      setStatus("loading");
    } else if (
      eventsError ||
      linesError ||
      schematicError ||
      eventsData === "empty" ||
      linesData === "empty" ||
      schematicData === "empty"
    ) {
      setStatus("error");
    } else if (eventsData && schematicData && linesData) {
      const combined = AddLines(eventsData.events.data, linesData.lines.data);

      if (!combined) {
        setStatus("error");
      } else {
        const fetchedFilters = ByColumn({
          data: combined,
          filtering: comp.filtering,
          thisStudy,
        });
        setFilters(fetchedFilters);
        setEventsDataFiltered(
          EventsFiltered({
            data: combined,
            filters: fetchedFilters.applied,
            thisStudy,
          })
        );
        setPiechartData(
          PieChartifyEvents({
            data: combined,
            thisStudy,
            lookupPath: comp.lookupPath,
          })
        );
        setCombinedEvents(combined);
        setStatus("success");
      }
    }
  }, [
    eventsData,
    eventsLoading,
    eventsError,
    linesData,
    linesLoading,
    linesError,
    schematicData,
    schematicLoading,
    schematicError,
    comp.filtering,
    comp.lookupPath,
    thisStudy,
  ]);

  // update applied filters
  useEffect(() => {
    if (filters && combinedEvents) {
      setAppliedFilters(_.cloneDeep(filters.applied));
      // const newFiltered  = EventsFiltered({data:eventsData,filters:newApplied,thisStudy})
      // setEventsDataFiltered(EventsFiltered({data:eventsData,filters:newApplied,thisStudy}));
    }
  }, [filterToggle]);

  // update data on filters applied
  useEffect(() => {
    if (appliedFilters && combinedEvents) {
      setEventsDataFiltered(
        EventsFiltered({
          data: combinedEvents,
          filters: appliedFilters,
          thisStudy,
        })
      );
    }
  }, [appliedFilters, combinedEvents]);


  return {
    setAppliedFilters,
    schematicData,
    status,
    combinedEvents,
    filters,
    setFilters,
    filterToggle,
    appliedFilters,
    setEventsDataFiltered,
    piechartData,
    filterToggle,
    setfilterToggle,
    eventsDataFiltered,
  }
};

export default useGpsMapDataFetcher;
