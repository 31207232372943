import React, { useState, useEffect, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import Coloring from "../Utils/Coloring";
import * as d3 from "d3";
import moment from "moment";
import Naming from "../Utils/Naming";
import MotionOrder from "../Utils/MotionOrder";
import FilterError from "../Filtering/FilterError";
import {
  generateLegendKeys,
  toolTipFormat,
} from "../../../../Data/Handlers/utils/helpers";

export default ({
  data,
  domainY,
  lookupPath,
  approach,
  activatedKeys,
  thisStudy,
  comp,
}) => {
  const height = 300;
  const [innerWidth, setInnerWidth] = useState(0);
  const [legendKeys, setLegendKeys] = useState([]);
  const ref = useRef();
  const domainX = [d3.min(data, (d) => d.date), d3.max(data, (d) => d.date)];

  // set initial size
  useEffect(() => {
    if (ref) {
      setInnerWidth(ref.current.offsetWidth);
    }
  }, [data]);

  // resize chart
  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(ref.current.offsetWidth);
    };

    if (ref) {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  });


  // Read approach keys from data
  useEffect(() => {
    if (data && data.length) {
      const {
        legend: { type },
      } = lookupPath;
      const legendKeys = generateLegendKeys(data);

      setLegendKeys(
        type === "motions" ? MotionOrder(legendKeys, thisStudy) : legendKeys
      );
    }
  }, [data, lookupPath.tooltip.type, thisStudy]);

  if (!data.length) {
    return (
      <div ref={ref}>
        <FilterError msg="No data, please adjust your filters." />
      </div>
    );
  }

  return (
    <div ref={ref}>
      <LineChart
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        width={innerWidth}
        height={height}
        data={data}
      >
        <XAxis
          tick={{ fontSize: 12 }}
          dataKey="date"
          scale="time"
          type="number"
          domain={domainX}
          allowDataOverflow={true}
          tickFormatter={(ms) => moment.unix(ms).utc().format("MMM Do, HH:mm")}
        />
        <YAxis tick={{ fontSize: 12 }} tickCount={8} domain={domainY} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          formatter={toolTipFormat}
          labelFormatter={(ms) => moment.unix(ms).utc().format("MMM Do, HH:mm")}
        />
        {legendKeys.map((key, i) => (
          <Line
            key={i}
            name={Naming({
              thisStudy,
              lookupPath,
              key,
              part: "tooltip",
              approach: approach ? approach : null,
            })}
            type="monotone"
            dataKey={key}
            stroke={
              activatedKeys[key]
                ? Coloring({
                  key,
                  lookup: thisStudy.lookup,
                  type: lookupPath.legend.type,
                  formatting: lookupPath.legend.formatting,
                })
                : "lightgrey"
            }
            strokeWidth={1.2}
            dot={comp.styling.dots}
            isAnimationActive={true}
            connectNulls={false}
          />
        ))}
      </LineChart>
    </div>
  );
};
