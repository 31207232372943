export const Comp112  =  {
    compID: 112,
    compType: 1,
    title: "Counts Per Approach",
    styling: {
      dots: true,
    },
    settings: {
      hasDropdown: false,
    },
    databox: [],
    lookupPath: {
      dropdown: {
        type: "approaches",
        formatting: "namesFull",
      },
      legend: {
        type: "approaches",
        formatting: "namesFull",
      },
      tooltip: {
        type: "approaches",
        formatting: "namesFull",
      },
    },
  }