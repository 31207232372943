import React from "react";
import styled, { keyframes } from "styled-components";

const Success = () => {
  return (
    <Wrapper>
      <Svg
        id="successAnimation"
        className="animated"
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <Path
          id="successAnimationResult"
          fill="#000000"
          d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
        />
        <Circle
          id="successAnimationCircle"
          cx="35"
          cy="35"
          r="24"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          fill="transparent"
        />
        <Check
          id="successAnimationCheck"
          stroke="#000000"
          stroke-width="2"
          points="23 34 34 43 47 27"
          fill="transparent"
        />
      </Svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
    display:flex;
    justify-content: center;
`

const scaleAnimation = keyframes`
    0% {
        opacity: 0;
        transform: scale(1.5);
        }
    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

const drawCircle = keyframes`
    0% {
        stroke-dashoffset: 151px;
    }
    100% {
        stroke-dashoffset: 0;
    }
`

const drawCheck = keyframes`
    0%{
        stroke-dashoffset: 36px;
    }
    100% {
        stroke-dashoffset: 0;
    }
`

const fadeOut = keyframes`
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
`

const fadeIn  = keyframes`
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
`

const Circle = styled.circle`
    stroke-dasharray: 200px 200px;
    stroke: #6D8DF4;

    &#successAnimationCircle {
        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both ${drawCircle},0.3s linear 0.9s 1 both ${fadeOut};
    }
`

const Check = styled.polyline`
    stroke-dasharray: 50px 50px;
    stroke: #6D8DF4;

    &#successAnimationCheck {
        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both ${drawCheck},0.3s linear 0.9s 1 both ${fadeOut};
    }
`

const Path = styled.path`
    fill: #6D8DF4;
    opacity:0;

    &#successAnimationResult {
        animation: 0.3s linear 0.9s both ${fadeIn};
    }
`

const Svg = styled.svg`
    &.animated {
        animation: ${scaleAnimation} 1s ease-out 0s 1 both ;
    }
`

export default Success;
