export const Comp122 =    {
    compID: 122,
    compType: 4,
    title: "Speed Plot",
    styling: {
      divSize: 75,
      justifyContent: "center",
    },
    lookupPath: {
      combined: true,
      dropdown: [
        { type: "approaches", formatting: "namesFull" },
        { type: "motions", formatting: "namesFull" },
      ],
    },
  }