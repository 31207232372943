import React from "react";
import styled from "styled-components";

export default ({ onClick, text, borderRadius = 5, theme }) => (
  <Button theme={theme} onClick={() => onClick()}>
    {text}
  </Button>
);

const Button = styled.button`
  width: 100%;
  height: 5vh;
  font-size: 0.9em;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: ${(props) => props.borderRadius}px;
  border: none;
  padding: 6px 16px;
  min-width: 64px;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.textLight};
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: ${(props) => props.theme.primaryDark};
  }

  &:focus {
    outline: none;
  }
`;
