import React, { useState, useEffect } from "react";
import {Checkbox} from '@material-ui/core';
import styled from "styled-components";

const CustomCheckbox = ({ currentFilters, setFilters, filter, theme }) => {
  const [toggle, setToggle] = useState(false);
  // const filters = _.cloneDeep(currentFilters);

  const handleChange = (e) => {
    setToggle(!toggle);

    const newFilters = {
      ...currentFilters,
      applied: {
        ...currentFilters.applied,
        filters: {
          ...currentFilters.applied.filters,
          [filter.dataKeys.join("")]: {
            keys: {
              [filter.dataKeys.join("")]: {
                filterValues: !toggle,
                type: "boolean",
              },
            },
          },
        },
      },
    };

    setFilters(newFilters);
  };

  // reset
  useEffect(() => {
    if (!currentFilters.applied.filters) {
      setToggle(false);
    }
  }, [currentFilters]);

  return (
    <Wrapper>
      {filter.title}:{" "}
      <Checkbox
        color="secondary.light"
        type="checkbox"
        checked={toggle}
        onChange={(e) => handleChange(e.target.checked)}
      />
    </Wrapper>
  );
};

export default CustomCheckbox;

const Wrapper = styled.div`
  margin-right: 2em;
  display:flex;
  align-items:center;
`;



