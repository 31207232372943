import React from "react";
import styled from "styled-components";
import { Route, useParams, useRouteMatch } from "react-router-dom";
import Menu from "../Menu/Menu";
import StudiesLanding from "./StudiesLanding";
import UpdateTitle from "../../utils/UpdateTitle";
import Spinner from "../Components/Spinner";
import GlobalTopBar from "./TopBar/GlobalTopBar";
import CompPicker from "./CompPicker";
import useStudiesRouterController from './usestudiesroutercontroller';

const StudiesRouter = ({ authUser, iframeView, theme }) => {
  const { path } = useRouteMatch();
  const { study_id } = useParams();
  const { thisStudy, routesToRender } = useStudiesRouterController({ authUser, study_id });



  if (!routesToRender || !authUser) {
    return <Spinner />
  }


  return (
    <div>

      <GlobalTopBar
        authUser={authUser}
        iframeView={iframeView}
        theme={theme}
      />

      <UpdateTitle
        title={`TEST`}
      />
      {!thisStudy.hideMenu && (
        <Menu
          studyID={study_id}
          thisStudy={thisStudy}
          authUser={authUser}
          iframeView={iframeView}
          theme={theme}
        />
      )}


      <Padder hideMenu={thisStudy.hideMenu}>


        <Route
          exact
          path={`${path}`}
          component={() =>
            StudiesLanding({ study_id, thisStudy, authUser, theme })
          }
        />

        {routesToRender.map((route, i) => (
          <Route
            key={i}
            path={`${path}/${route.path}`}
            render={() => {


              return route.components.map((comp, i) => (
                <CompPicker
                  comp={comp}
                  i={i}
                  key={comp.compID}
                  thisStudy={thisStudy}
                  authUser={authUser}
                  theme={theme}
                />
              ));
            }}
          />
        ))}
      </Padder>
    </div>)
};

export default StudiesRouter;

const Padder = styled.div`
  padding-left: ${(props) => (props.hideMenu ? "0x" : "70px")};
`;
