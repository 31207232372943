import {
  curry,
  pipe,
  equals,
  always,
  T,
  cond,
  pathOr,
  groupBy,
  map,
  prop,
  tap
} from "ramda";

const lookupName = curry((thisStudy, {
  type,
  defaultName,
  formatting
}, key) => pipe(
  pathOr(defaultName, ["lookup", type, key, formatting])
)(thisStudy))

const generateNormalKey = curry((thisStudy, lookupPath, part, key) => {
  const { type, formatting } = lookupPath[part];

  const getName = pipe(
    pathOr("not available", ["lookup", type, key, formatting])
  )

  return getName(thisStudy);
});

const generateCombinedKey = curry((thisStudy, lookupPath, part, key) => {
  const { type: type1, formatting: formatting1 } = lookupPath[part][0];
  const { type: type2, formatting: formatting2 } = lookupPath[part][1];
  const armA = key[0];

  const approach = lookupName(thisStudy, {
    type: type1,
    formatting: formatting1,
    defaultName: "n/a"
  }, armA);

  const motion = lookupName(thisStudy, {
    type: type2,
    formatting: formatting2,
    defaultName: "n/a"
  }, key);

  return `${approach} - ${motion}`;
});

const newNaming = curry((thisStudy, lookupPath, key, part, combined) => {
  const normalKey = lookupName(thisStudy, {
    type: lookupPath[part].type,
    formatting: lookupPath[part].formatting,
    defaultName: "not available"
  })
  const combinedKey = generateCombinedKey(thisStudy, lookupPath, part);

  return cond([
    [equals("All"), (key) => key],
    [equals("-1"), always("n/a")],
    [() => combined, combinedKey],
    [T, normalKey],
  ])(key);
});

const Naming = ({ thisStudy, lookupPath, key, part, combined = false }) => {

  // ------------------------------------------------------------
  // generates formatted string when given lookup objects and key
  // ------------------------------------------------------------

  if (thisStudy && lookupPath) {
    return newNaming(thisStudy, lookupPath, key, part, combined)
  }

  return null
};

export default Naming;
