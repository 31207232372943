import React, { useState } from "react";
import styled from "styled-components";
import Map from "../Map/Map";
import OverviewTab from "../Overview/Tab/OverviewTab";
import OverviewBox from "../Overview/OverviewBox";
import Table from "../Table/Table";

const MapChart = ({
  schematicData,
  eventsDataFiltered,
  piechartData,
  showSchematic,
  showBackground,
  hoveringRow,
  setHoveringRow,
  selectedRows,
  setSelectedRows,
  interactive,
  tableRows,
  setTableRows,
  thisStudy,
  comp,
  dpi,
  theme,
}) => {
  const [display, setDisplay] = useState("table");

  return (
    <Wrapper styling={{ justifyContent: comp.styling.align }}>
      <Map
        schematicData={schematicData}
        eventsDataFiltered={eventsDataFiltered}
        showSchematic={showSchematic}
        showBackground={showBackground}
        withTraces={true}
        withImageDropdown={false}
        hoveringRow={hoveringRow}
        setHoveringRow={setHoveringRow}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        interactive={interactive}
        tableRows={tableRows}
        thisStudy={thisStudy}
        comp={comp}
        dpi={dpi}
        study_id={thisStudy.study_id}
        theme={theme}
      />

      <Overview>
        <OverviewTab display={display} setDisplay={setDisplay} theme={theme} />
        <Table
          display={display === "table"}
          data={eventsDataFiltered}
          setHoveringRow={setHoveringRow}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          tableRows={tableRows}
          setTableRows={setTableRows}
          columns={comp.table.columns}
          thisStudy={thisStudy}
          theme={theme}
        />

        <OverviewBox
          display={display === "overview"}
          data={piechartData}
          comp={comp}
          thisStudy={thisStudy}
          theme={theme}
        />
      </Overview>
    </Wrapper>
  );
};

const Overview = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
`;

export default MapChart;
