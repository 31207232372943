import _ from "lodash";
import { } from 'ramda';
import { trimData } from "../utils/trimData";
import multipleGrouping from "./multipleGrouping";

function ByGrouping({ data, column, includeCombined }) {

  // ------------------------------------------------------------------------
  // formats data with each motion as a separate key/line for the linecharts.
  // ------------------------------------------------------------------------


  let linesData;

  if (data) {
    if (data.length) {
      let construct = {};

      if (includeCombined) {
        linesData = multipleGrouping({
          data:data,
          groupAValue:'date',
          groupA:'posixTime',
          groupB:'armA',
          sumKey:'value'
        })

        construct["All"] = trimData(linesData,true)
      }


      // Group By Column
      let byClass = _.groupBy(data.filter(d=>d[column]!==-1), column);

      Object.keys(byClass).forEach(col => {

        // not by approach? 
        if (column !== "armA") {
          // groups by column and approach
          linesData = multipleGrouping({
            data: byClass[col],
            groupAValue: "date",
            groupA: "posixTime",
            groupB: "armA",
            sumKey: "value"
          });
        } 
        else {
          // groups by approach & motion
          linesData = multipleGrouping({
            data: byClass[col],
            groupAValue: "date",
            groupA: "posixTime",
            groupB: "armR",
            sumKey: "value",
            joinWith: col
          });
        }

        construct[col] = trimData(linesData,true);
      });

      return construct;
    }
  }
  return "empty";
}

export default ByGrouping;
