import React from "react";
import styled from "styled-components";

export default ({ titleShort, titleLong, open, theme }) => {
  return (
    <Wrapper>
      <TitleLong className={open && "show"}>{titleLong}</TitleLong>
      <TitleShort className={!open && "show"}>{titleShort}</TitleShort>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 10px;
  font-size: 0.7em;
  text-transform: uppercase;
  font-weight: 700;
  margin: 17px 0 5px;
`;

const TitleShort = styled.div`
  position: absolute;
  top: 0;
  left: 50%;

  transition: all 250ms ease-in-out;
  opacity: 0;
  transform: translateX(-100%);
  &.show {
    opacity: 0.2;
    transform: translateX(-50%);
  }
`;
const TitleLong = styled.div`
  position: absolute;
  top: 0;
  left: 25px;
  white-space: nowrap;

  transition: all 250ms ease-in-out;
  opacity: 0;
  transform: translateX(-100%);
  &.show {
    opacity: 0.4;
    transform: translateX(0%);
  }
`;
