import gql from "graphql-tag";

const QUERY = gql`
  query events($study_id: ID!, $lastkey: lastkey) {
    events(
      study_id: $study_id
      lastkey: $lastkey
      eventClass: [2]
      conditionals: [{ key: "eventId", condition: "<=", value: 1000 }]
    ) {
      data {
        study_id
        row_id
        arm1A
        arm1R
        arm2A
        arm2R
        id1
        id2
        class1
        class2
        dMargin
        deltaVMag
        dwellTime
        eventId
        eventClass
        eventSeverity
        tMargin
        t_posix
        v1
        v2
        x_pix
        y_pix
        videoFileNamesSmall
      }
      lastkey {
        study_id
        row_id
      }
    }
  }
`;

const dataHandler = (data) => data;

const q243events = {
  QUERY,
  dataHandler,
};

export default q243events;
