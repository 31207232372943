import React from "react";
import styled from "styled-components";
import * as d3 from "d3";

const DataGroup = ({ d, height, width }) => {
  // convert Ryans pixel data to dynamic scales
  const xScale = d3
    .scaleLinear()
    .domain([0, 2560])
    .range([0, width]);
  const yScale = d3
    .scaleLinear()
    .domain([0, 2560])
    .range([0, height]);

  return (
    <g>
      <Line
        x1={xScale(d.pixX0)}
        y1={yScale(d.pixY0)}
        x2={xScale(d.pixX1)}
        y2={yScale(d.pixY1)}
        // key={i}
      />
    </g>
  );
};

const Schematic = ({ width, height, data }) => {
  return data.map((d, i) => <DataGroup d={d} width={width} height={height} key={i} />);
};

const Line = styled.line`
  stroke: white;
`;

export default Schematic;
