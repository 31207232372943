import React from "react";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { FaRoad } from "react-icons/fa";
import Studies from "./Studies";

export default ({ intersection, theme }) => {
  return (
    <Intersection>
      <Title>
        <IconContext.Provider value={{ color: "black", size: "1.5em" }}>
          <div style={{ marginRight: "1em" }}>
            <FaRoad />
          </div>
        </IconContext.Provider>
        <h2>{intersection.intersection}</h2>
      </Title>
      <Studies theme={theme} studies={intersection.studies} />
    </Intersection>
  );
};

const Intersection = styled.div`
  flex: 0 1 100%;
`;

const Title = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 1em;

  h2 {
    font-size: 1.2em;
    font-weight: 600;
  }
`;
