import React, { useState, useEffect, useReducer } from "react";
import styled from "styled-components";
import Link from "@material-ui/core/Link";
import Spinner from "../Components/Spinner";
import { Redirect } from "react-router-dom";
import LogIn from "../../Data/Fetching/QueryLibrary/LogIn";
import GetAuth from "../../Data/Fetching/QueryLibrary/GetAuth";
import * as compose from "lodash.flowright";
import { graphql } from "react-apollo";
import Button from "../Components/Button";
import { MSGS, initialState } from "./loginReducer";
import loginReducer from "./loginReducer";

const Login = ({ authStatus, LogIn, theme }) => {
  const [state, dispatch] = useReducer(loginReducer, initialState);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleLogin = async (state) => {
    const { email, password } = state;

    setLoading(true);

    if (email.validated && password.validated) {
      try {
        await LogIn({
          variables: {
            email: email.value,
            password: password.value,
          },
        });
      } catch (err) {
        setErrorMsg(localStorage.getItem("login-error"));
      }
    } else {
      setErrorMsg("Please pass valid email & password");
      dispatch({
        type: MSGS.RESET,
      });
    }

    setLoading(false);
  };

  // login trigger spinner
  useEffect(() => {}, [handleLogin, errorMsg]);

  // event listener for enter submit
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        handleLogin(state);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [state.email, state.password]);

  if (authStatus.status === "loggedIn") {
    return <Redirect to="/" />;
  } else if (loading) {
    return <Spinner theme={theme} />;
  } else if (authStatus.status === "loggedOut") {
    return (
      <>
        <TopSection theme={theme}>
          <TopText theme={theme}>
            Street
            <br />
            Simplified
          </TopText>
        </TopSection>
        <LoginCard theme={theme}>
          <Title theme={theme}>Login</Title>

          {/* show error message */}
          {errorMsg && <ErrorMsg theme={theme}>{errorMsg}</ErrorMsg>}

          {/* Login Form */}
          <Input
            theme={theme}
            type="email"
            label="Email Address"
            required
            autoComplete="email"
            validated={state.email.validated}
            onChange={(e) =>
              dispatch({
                type: MSGS.SET_EMAIL,
                payload: {
                  value: e.target.value,
                },
              })
            }
          />
          <Input
            theme={theme}
            type="password"
            required
            label="Password"
            autoComplete="current-password"
            validated={state.password.validated}
            onChange={(e) =>
              dispatch({
                type: MSGS.SET_PASSWORD,
                payload: {
                  value: e.target.value,
                },
              })
            }
          />
          <Button
            theme={theme}
            onClick={() => handleLogin(state)}
            text="Login"
          />

          <ForgotBox theme={theme}>
            <Link href={`/forgot`}>
              <p>Forgot your password?</p>
            </Link>
          </ForgotBox>
        </LoginCard>
      </>
    );
  }
};

export default compose(
  graphql(GetAuth, {
    props: ({ data: { authStatus } }) => ({
      authStatus,
    }),
  }),
  graphql(LogIn, { name: "LogIn" })
)(Login);

const Input = styled.input`
  border: solid 1px ${(props) => props.theme.primary};
  margin-bottom: 20px;
  width: 100%;
  height: 6vh;
  border-radius: 5px;
  padding: 10px;
  background-color: ${(props) => props.theme.greyScale};
  color: ${(props) => props.theme.textDark};
  font-size: 1em;
  font-weight: 400;

  &:hover {
    border: solid 1px #000;
  }

  &:focus {
    border: solid 2px
      ${(props) =>
        props.validated ? props.theme.primaryDark : props.theme.error};
    outline: none;
  }
`;

const TopSection = styled.div`
  background: ${(props) => props.theme.gradient};
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopText = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 20px;

  color: ${(props) => props.theme.textLight};
  font-size: 5vw;
`;

const ErrorMsg = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.error};
`;

const LoginCard = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 20px;
  margin: 10vh auto;
`;

const ForgotBox = styled.div`
  margin-top: 10px;
  p {
    color: ${(props) => props.theme.primary};
  }
`;

const Title = styled.h2`
  font-size: 1.4em;
  font-weight: 500;
  color: ${(props) => props.theme.textDark};
`;
