import { useEffect, useState } from 'react';
import MotionOrder from "../Utils/MotionOrder";
import { generateLegendKeys } from "../../../../Data/Handlers/utils/helpers";

const useLegendController = ({ lookupPath, thisStudy, setActivatedKeys, lines }) => {
  const [legendKeys, setlegendKeys] = useState([]);


  useEffect(() => {
    if (lines.length) {
      const {
        legend: { type },
      } = lookupPath;

      const legendKeys = generateLegendKeys(lines);

      setlegendKeys(
        type === "motions" ? MotionOrder(legendKeys, thisStudy) : legendKeys
      );
    }
  }, [lines, lookupPath.legend.type, thisStudy]);

  useEffect(() => {
    let keys = {};

    legendKeys.forEach((d) => {
      keys[d] = true;
    });
    setActivatedKeys(keys);
  }, [legendKeys, setActivatedKeys]);

  return {
    legendKeys,
  }
};

export default useLegendController;
