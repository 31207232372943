export const Comp245 = {
  compID: 245,
  compType: 4,
  title: "Red Light Running TMC",
  styling: {
    divSize: 40,
    justifyContent: "center",
  },
  lookupPath: {
    combined: false,
    dropdown: {
      type: "special",
      formatting: "",
      specialLookup: {
        1: "",
      },
    },
  },
};
