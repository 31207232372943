export const Comp142 = {
  compID: 142,
  compType: 4,
  title: "Bike Road Users TMC",
  styling: {
    divSize: 30,
    justifyContent: "center",
  },
  lookupPath: {
    combined: false,
    dropdown: {
      type: "special",
      formatting: "",
      specialLookup: {
        1: "AM Peak",
        2: "PM Peak",
        3: "All",
      },
    },
  },
};
