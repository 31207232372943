import validator from "validator";

export const initialState = {
  email: {
    validated: false,
    value: "",
  },
  password: {
    validated: false,
    value: "",
  },
};

export const MSGS = {
  SET_EMAIL: "SET_EMAIL",
  SET_PASSWORD: "SET_PASSWORD",
  RESET:'RESET'
};

export default (state, action) => {
  switch (action.type) {
    case MSGS.SET_EMAIL: {
      const { value } = action.payload;
      const validated = validator.isEmail(value);

      return {
        ...state,
        email: {
          validated,
          value,
        },
      };
    }
    case MSGS.SET_PASSWORD: {
      const { value } = action.payload;
      const validated = validator.isLength(value, { min: 8, max: undefined });

      return {
        ...state,
        password: {
          validated,
          value,
        },
      };
    }
    case MSGS.RESET: {
      return {
        email:{
          validated:false,
          value:''
        },
        password:{
          validated:false,
          value:''
        }
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};