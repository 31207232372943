import React, { useEffect } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";

const View1 = ({
  authUser,
  setStep,
  setLoading,
  setEmail,
  email,
  errorMsg,
  setErrorMsg,
  theme,
}) => {
  useEffect(() => {
    if (authUser) {
      setEmail(authUser.email);
    }
  }, [authUser, errorMsg, setEmail]);

  const beginReset = async (_email) => {
    setLoading(true);
    try {
      await Auth.forgotPassword(_email);
      setLoading(false);
      setErrorMsg(null);
      setStep(2);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setErrorMsg(err.message);
    }
  };

  if (!authUser) {
    return (
      <>
        <SubText theme={theme}>Please enter the registered email.</SubText>
        {errorMsg && <ErrorMsg theme={theme}>{errorMsg}</ErrorMsg>}
        <Input
          theme={theme}
          required
          label="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button theme={theme} onClick={() => beginReset(email)}>
          Update Password
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Heading theme={theme}>Hi {authUser.client_name}, welcome!</Heading>
        <SubText theme={theme}>
          We’re excited to have you on board. Please take a moment to update
          your password to get started
        </SubText>
        {errorMsg && <ErrorMsg theme={theme}>{errorMsg}</ErrorMsg>}
        <Button theme={theme} onClick={() => beginReset(email)}>
          Update Password
        </Button>
      </>
    );
  }
};

const Button = styled.button`
  width: 100%;
  height: 5vh;
  font-size: 0.9em;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  padding: 6px 16px;
  min-width: 64px;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.textLight};
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: ${(props) => props.theme.primaryDark};
  }
`;

const Input = styled.input`
  border: solid 1px ${(props) => props.theme.secondaryLight};
  margin-bottom: 20px;
  width: 100%;
  height: 6vh;
  border-radius: 5px;
  padding: 10px;
  background-color: ${(props) => props.theme.greyScale};
  color: ${(props) => props.theme.textDark};
  font-size: 1em;
  font-weight: 400;

  &:hover {
    border: solid 1px #000;
  }

  &:focus {
    border: solid 2px ${(props) => props.theme.secondaryDark};
  }
`;

const Heading = styled.div`
  padding-top: 25px;
  font-size: 28px;
  text-align: center;
`;

const SubText = styled.h2`
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 1.2em;
  font-weight: 500;
  color: ${(props) => props.theme.textDark};
`;

const ErrorMsg = styled.div`
  color: red;
  font-size: 14px;
`;

export default View1;
