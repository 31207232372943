import React from "react";
import styled from "styled-components";

export default ({ title, theme, children }) => {
  return (
    <Wrapper theme={theme}>
      <TopLine theme={theme} />
      <Head theme={theme}>{title}</Head>
      <div>{children}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 20px;
  background-color: ${(props) => props.theme.textLight};
  border-radius: 3px;
  overflow: auto;
  box-shadow: 0 2px 10px -4px rgba(0, 0, 0, 0.2);
`;

const TopLine = styled.div`
  background: ${(props) => props.theme.gradient};
  height: 7px;
`;

const Head = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.whiteBoxBorder};
  padding: 7px 10px;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
`;
