export const Comp133 = {
    compID: 133,
    compType: 1,
    title: "Vulnerable Road User Counts Per Approach",
    styling: {
      dots: true,
    },
    settings: {
      hasDropdown: true,
    },
    databox: [
      {
        title: "Overview",
        stats: [
          {
            title: "Total Count",
            type: "totalCount",
          },
        ],
      },
    ],
    lookupPath: {
      dropdown: {
        type: "roadUsers",
        formatting: "full",
      },
      legend: {
        type: "approaches",
        formatting: "namesFull",
      },
      tooltip: {
        type: "approaches",
        formatting: "namesFull",
      },
    },
  }