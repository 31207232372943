import React from "react";
import styled from "styled-components";

export default ({ theme }) => {
  return (
    <TopSection theme={theme}>
      <TopText theme={theme}>
        Street
        <br />
        Simplified
      </TopText>
    </TopSection>
  );
};

const TopSection = styled.div`
  grid-area: 2/1/3/-1;
  background: ${({ theme }) => theme.gradient};
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopText = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 20px;
  color: ${({ theme }) => theme.textLight};
  font-size: 5vw;
`;
