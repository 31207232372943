const CleanImages = (data) => {
    if (data) {
        if (data.images) {
            if (data.images.length) {
                return data
            }
        } 
    } 

    return "empty"
}

export default CleanImages;