import sumRows from "./sumRows";
import {
  groupBy,
  prop,
  pipe,
  filter,
  uniq,
  pluck,
  toPairs,
  map,
  curry,
} from "ramda";

const transFn = curry(
  (uniqB, joinWith, sumKey, sumRows, groupAValue, groupB, data) => {
    const [a, groupedByA] = data;
    const groupedByB = groupBy(prop(groupB))(groupedByA);

    const row = { [groupAValue]: a };
    uniqB.forEach((b) => {
      row[joinWith ? `${joinWith}_${b}` : b] = sumRows(groupedByB[b], sumKey);
    });

    return row;
  }
);

const multipleGrouping = ({
  data,
  groupAValue,
  groupA,
  groupB,
  sumKey,
  joinWith,
}) => {

  const uniqB = pipe(
    pluck(groupB),
    uniq,
    filter((x) => x !== -1)
  )(data);

  const transformFn = transFn(
    uniqB,
    joinWith,
    sumKey,
    sumRows,
    groupAValue,
    groupB
  );

  return pipe(
    groupBy(prop(groupA)),
    toPairs,
    map(transformFn),
  )(data)

};

export default multipleGrouping;
