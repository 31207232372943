import React from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";

const OverviewTab = ({ display, setDisplay, theme }) => {
  return (
    <Menu>
      <MenuItem
        key={0}
        text="Table"
        active={display === "table"}
        setDisplay={setDisplay}
        theme={theme}
      />
      <MenuItem
        key={1}
        text="Overview"
        active={display === "overview"}
        setDisplay={setDisplay}
        theme={theme}
      />
    </Menu>
  );
};

const Menu = styled.div`
  flex: 0 5%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
`;
export default OverviewTab;
