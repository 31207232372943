import _ from 'lodash'

const sumRows = (arr,key) => {

    if (arr) {
      const filtered = arr.filter(d=>d[key]!==null)
      if (filtered.length) {
        return _.sumBy(filtered,d=>d[key])
      } else {
        return null
      }
    } else {
      return null
    }
}

    
  

export default sumRows