export const Comp124 =   {
    compID: 124,
    compType: 5,
    styling: {
      align: "flex-start",
    },
    piechart: {
      display: false,
      title: "",
    },
    title: "Median Vehicle Maps",
    lookupPath: {
      dropdown: {
        type: "approaches",
        formatting: "namesFull",
      },
      piechart: {
        type: "approaches",
        formatting: "namesFull",
      },
    },
  }