import { trimData } from "../utils/trimData";
import multipleGrouping from "./multipleGrouping";

const ByApproach = (data, fill = false) => {
  if (data) {
    if (data.length) {
      const groupedData = multipleGrouping({
        data,
        groupAValue: "date",
        groupA: "posixTime",
        groupB: "armA",
        sumKey: "value"
      });

      return {
        "aggregated": trimData(groupedData, true)
      };
    }
  }
  return "empty";
};


export default ByApproach;
