import React, { useState } from "react";
import styled from "styled-components";
import StudyInformation from "./StudyInformation";
import Downloadables from "./Downloadables";
import { isNil, isEmpty } from "ramda";

export default ({ study, theme }) => {
  const [displayDownloadables, setDisplayDownloadables] = useState(false);
  const hasDownloadables =
    !isNil(study.downloadables) && !isEmpty(study.downloadables);

  return (
    <Study theme={theme}>
      <StudyInformation
        theme={theme}
        study={study}
        displayDownloadables={displayDownloadables}
        setDisplayDownloadables={setDisplayDownloadables}
        hasDownloadables={hasDownloadables}
      />
      {hasDownloadables ? (
        <DownloadCard>
          <Downloadables
            theme={theme}
            downloadables={study.downloadables}
            show={displayDownloadables}
          />
        </DownloadCard>
      ) : null}
    </Study>
  );
};

const Study = styled.div`
  margin-bottom: 1em;
  flex: 0 1 90%;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: ${(props) => props.theme.white};

  @media (max-width: 960px) {
    flex: 0 1 100%;
  }
`;

const DownloadCard = styled.div``;
