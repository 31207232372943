export const Comp212 = {
  compID: 212,
  compType: 2,
  styling: {
    align: "left",
    mapDefaults: {
      circle: true,
      lines: false,
    },
  },
  settings: {
    quarterStatsTransformation: {
      type: "byEvent",
      countDuplicates: false,
    },
  },
  title: "Near Misses Locations And Videos",
  filtering: [
    // row 1
    {
      title: "General",
      filters: [
        {
          dataKeys: ["videoFileNamesSmall"],
          dataType: "boolean",
          title: "Events with Video",
        },
        {
          dataKeys: ["t_posix"],
          dataType: "range",
          width: 400,
          title: "Time Period",
          lookup: {
            type: "time",
            formatting: "short",
          },
        },
        {
          dataKeys: ["tMargin"],
          dataType: "range",
          width: 400,
          title: "PET",
          lookup: {
            type: "number",
            formatting: 1,
          },
        },
        {
          dataKeys: ["eventSeverity"],
          dataType: "range",
          width: 400,
          title: "Severity",
          lookup: {
            type: "number",
            formatting: 1,
          },
        },
      ],
    },
    // row 2
    {
      title: "User 1",
      filters: [
        {
          dataKeys: ["class1"],
          dataType: "discreet",
          title: "Type",
          lookup: {
            type: "roadUsers",
            formatting: "full",
          },
        },
        {
          dataKeys: ["arm1A"],
          dataType: "discreet",
          title: "Approach",
          lookup: {
            type: "approaches",
            formatting: "namesFull",
          },
        },
        {
          dataKeys: ["arm1A", "arm1R"],
          dataType: "discreet",
          title: "Motion",
          lookup: {
            type: "motions",
            formatting: "namesFull",
          },
        },
        {
          dataKeys: ["v1"],
          dataType: "range",
          width: 400,
          title: "Speed",
          lookup: {
            type: "number",
            formatting: 1,
          },
        },
      ],
    },
    {
      title: "User 2",
      filters: [
        {
          dataKeys: ["class2"],
          dataType: "discreet",
          title: "Type",
          lookup: {
            type: "roadUsers",
            formatting: "full",
          },
        },
        {
          dataKeys: ["arm2A"],
          dataType: "discreet",
          title: "Approach",
          lookup: {
            type: "approaches",
            formatting: "namesFull",
          },
        },
        {
          dataKeys: ["arm2A", "arm2R"],
          dataType: "discreet",
          title: "Motion",
          lookup: {
            type: "motions",
            formatting: "namesFull",
          },
        },
        {
          dataKeys: ["v2"],
          dataType: "range",
          width: 400,
          title: "Speed",
          lookup: {
            type: "number",
            formatting: 1,
          },
        },
      ],
    },
  ],
  table: {
    columns: [
      {
        title: "Id",
        data: [
          {
            dataKeys: ["eventId"],
            type: "number",
            formatting: 0,
          },
        ],
        delimiter: "",
        fontSize: "14px",
        align: "left",
        minWidth: "10px",
      },
      {
        title: "",
        data: [
          {
            dataKeys: ["videoFileNamesSmall"],
            type: "link",
            formatting: "",
          },
        ],
        fontSize: "14px",
        align: "left",
        minWidth: "5px",
      },
      {
        title: "PET",
        data: [{ dataKeys: ["tMargin"], type: "number", formatting: 1 }],
        fontSize: "14px",
        align: "left",
        minWidth: "10px",
      },
      {
        title: "Severity",
        data: [
          {
            dataKeys: ["eventSeverity"],
            type: "number",
            formatting: 0,
          },
        ],
        delimiter: "",
        fontSize: "14px",
        align: "left",
        minWidth: "10px",
      },
      {
        title: "Event Type",
        data: [
          {
            dataKeys: ["class1"],
            type: "roadUsers",
            formatting: "short",
          },
          {
            dataKeys: ["class2"],
            type: "roadUsers",
            formatting: "short",
          },
        ],
        delimiter: "-",
        fontSize: "14px",
        align: "left",
        minWidth: "10px",
      },
      {
        title: "Motion 1",
        data: [
          {
            dataKeys: ["arm1A"],
            type: "approaches",
            formatting: "namesFull",
          },
          {
            dataKeys: ["arm1A", "arm1R"],
            type: "motions",
            formatting: "namesFull",
          },
        ],
        delimiter: "-",
        fontSize: "14px",
        align: "left",
        minWidth: "100px",
      },
      {
        title: "Motion 2",
        data: [
          {
            dataKeys: ["arm2A"],
            type: "approaches",
            formatting: "namesFull",
          },
          {
            dataKeys: ["arm2A", "arm2R"],
            type: "motions",
            formatting: "namesFull",
          },
        ],
        delimiter: "-",
        fontSize: "14px",
        align: "left",
        minWidth: "100px",
      },
      {
        title: "Speed 1 (mph)",
        data: [
          {
            dataKeys: ["v1"],
            type: "number",
            formatting: 1,
          },
        ],
        fontSize: "14px",
        maxWidth: "",
        minWidth: "10px",
      },
      {
        title: "Speed 2 (mph)",
        data: [
          {
            dataKeys: ["v2"],
            type: "number",
            formatting: 1,
          },
        ],
        fontSize: "14px",
        maxWidth: "",
        minWidth: "10px",
      },
      {
        title: "Time",
        data: [
          {
            dataKeys: ["t_posix"],
            type: "time",
            formatting: "full",
          },
        ],
        fontSize: "14px",
        align: "left",
        minWidth: "100px",
      },
    ],
  },
  overview: {
    piechart: {
      title: "Road Users Involved",
    },
  },
  lookupPath: {
    piechart: {
      type: "roadUsers",
      formatting: "full",
    },
  },
};
