import React from "react";
import styled from "styled-components";
import CustomCheckbox from "./CustomCheckbox";
import CustomSlider from "./CustomSlider";
import Dropdown from "./Dropdown";
import { ByColumn } from "../../../../Data/Handlers/DataHandler";
import _ from "lodash";
import ScreenshotBtn from '../../../Components/screenshotbtn';
import { useMachine } from '@xstate/react';
import { screenshot__machine } from '../../../../machines/screenshot';

const Filtering = ({
  currentFilters,
  setFilters,
  setfilterToggle,
  filterToggle,
  setSelectedRows,
  comp,
  eventsData,
  thisStudy,
  theme,
  screenshotElement,
  allowScreenshot
}) => {
  const [screenshotState, screenshotDispatch] = useMachine(screenshot__machine);
  const toggleFilterOn = () => {
    setfilterToggle(!filterToggle);
  };

  const filters = _.cloneDeep(currentFilters);

  const toggleFilterOff = () => {
    if (setSelectedRows) {
      setSelectedRows([]);
    }
    setfilterToggle(!filterToggle);
    // reset filters
    setFilters(
      ByColumn({ data: eventsData, filtering: comp.filtering, thisStudy })
    );
  };

  return (
    <Wrapper>
      {comp.filtering.map((row, i) => (
        <Row key={i}>
          <Subhead key={i}>{row.title}</Subhead>
          {row.filters.map((filter, i) => {
            // on/off switch?
            if (filter.dataType === "boolean") {
              return (
                <CustomCheckbox
                  key={i}
                  currentFilters={filters}
                  filter={filter}
                  setFilters={setFilters}
                />
              );
            }

            // slider?
            else if (
              filter.dataType === "range" &&
              filters[filter.dataKeys.join("")]
            ) {
              return (
                <CustomSlider
                  key={i}
                  filter={filter}
                  currentFilters={filters}
                  setFilters={setFilters}
                  width={filter.width}
                  theme={theme}
                />
              );

              // dropdown?
            } else if (
              filter.dataType === "discreet" &&
              filters[filter.dataKeys.join("")]
            ) {
              return (
                <Dropdown
                  key={i}
                  thisStudy={thisStudy}
                  filter={filter}
                  currentFilters={filters}
                  setFilters={setFilters}
                />
              );
            }
          })}
        </Row>
      ))}
      <ButtonRow>
        <div>
          <ButtonApply theme={theme} onClick={toggleFilterOn}>
            Apply Filters
        </ButtonApply>
          <ButtonClear theme={theme} onClick={toggleFilterOff}>
            Clear Filters
          </ButtonClear>

        </div>
        {allowScreenshot && (
          <ScreenshotBtn isDisabled={screenshotState.matches('processing')} theme={theme} onClick={() => screenshotDispatch({
            type: 'SCREENSHOT',
            payload: {
              node: screenshotElement
            }
          })}>{screenshotState.matches("processing") ? "Loading..." : "Save As File"}</ScreenshotBtn>

        )}


      </ButtonRow>

    </Wrapper>
  );
};

export default Filtering;

const Wrapper = styled.div`
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
`;

const Subhead = styled.div`
  width: 100px;
  font-weight: bold;
`;

const ButtonRow = styled.div`
  padding: 10px;
  display:flex;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin-right: 5px;
  font-weight: bold;
  &:hover {
    opacity: 0.8;
  }
`;

const ButtonApply = styled(Button)`
  background-color: ${(props) => props.theme.primary};
`;
const ButtonClear = styled(Button)`
  background-color: ${(props) => props.theme.secondary};
`;
