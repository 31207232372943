import _ from "lodash";
import {prop,head,last,sum,pluck,pipe,map,tap} from 'ramda';
import SumRow from '../utils/SumRow';
import moment from 'moment'

const PHF = (peakHour, peakQuarter) => {
  if (peakHour && peakQuarter) {
    return (peakHour / (peakQuarter * 4)).toFixed(2);
  } else {
    return null;
  }
};

const peakQuarter = data => {

  let peakQuarter = {
    start: null,
    stop: null,
    count: null
  };

  data.forEach(d => {
    const currentCount = SumRow(d);
    if (currentCount > peakQuarter.count) {
      peakQuarter.start = Number(d.date);
      peakQuarter.stop = Number(d.date)+900;
      peakQuarter.count = currentCount;
    }
  });

  return peakQuarter;
};

const peakHour = data => {

  let peakHour = {
    start: null,
    stop: null,
    count: null
  };

  data.forEach((d, i) => {
    // slice out current hour
    const currentHour = data.slice(i, i + 4);
    if (currentHour.length === 4) {
      // counts for the current hour
      let count = null;

      currentHour.forEach(d => {
        count += SumRow(d);
      });

      if (count > peakHour.count) {
        peakHour.start = Number(currentHour[0].date);
        peakHour.stop = Number(currentHour[currentHour.length - 1].date) + 900;
        peakHour.count = count;
      }
    }
  });
  return peakHour;
};

const TimeOfDay = timestamp => {
  let date = Number(timestamp) 
  date = moment(date)
  if (date.isValid()) {
    return moment.unix(date).utc().format("A")
  } else {
    return null
  }

};

const TimeFormatting = date => {
  const addZero = i => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };

  if (date !== null) {
    const h = addZero(date.getUTCHours());
    const m = addZero(date.getUTCMinutes());

    return `${h}:${m}`;
  } else {
    return null;
  }
};

const SplitByDay = data => {
  const finalObj = {};

  const days = _.uniq(data.map(d => new Date(d.date * 1000).getUTCDate()));

  days.forEach(d => {
    finalObj[d] = [];
  });

  data.forEach(d => {
    const day = new Date(d.date * 1000).getUTCDate();
    finalObj[day].push(d);
  });

  return finalObj;
};

const HasValues = data => {
  // takes one day data and checks if any recorded values
  let recordedVals = [];

  data.forEach(d => {
    let count = null;
    const keys = Object.keys(d).filter(key => key !== "date");
    keys.forEach(k => {
      if (d[k] !== null) {
        count += d[k];
      }
    });
    recordedVals.push(count);
  });
  if (_.compact(recordedVals).length >= 1) {
    return true;
  } else {
    return false;
  }
};

const DailyAvg = data => {

  const totalDays = pipe(
    pluck('date'),
    map(x=>Number(x)),
    arr => ((last(arr) - head(arr)) / 3600) / 24,
  )(data)

  const totalCount = pipe(
    map(SumRow),
    sum,
  )(data)

  if (totalDays<1){
    return null
  }

  const dailyAvg = totalCount / totalDays
  return Number(dailyAvg.toFixed()).toLocaleString()

  // let totalByDay = [];
  // Object.keys(ByDay).forEach(i => {
  //   let dailySum = null;
  //   ByDay[i].forEach(d => {
  //     dailySum += SumRow(d);
  //   });
  //   totalByDay.push(dailySum);
  // });

  // return Number(_.mean(totalByDay).toFixed()).toLocaleString();
};

const TotalCount = data => {
  let count = null;

  data.forEach(row => {
    count += SumRow(row);
  });

  return count.toLocaleString();
};

const generateMotions = (data) => {
  
  // -----------------------------------------------------------------------------------
  // takes a set of data and a static armA id. returns an array with the correct motions
  // -----------------------------------------------------------------------------------

  return Object.keys(data[0]).filter(key => key !== "date").filter(d=>d!=="aggregated");
};

const AmPm = data => {
  let construct = {
    AM: [],
    PM: []
  };

  data.forEach(row => { 
    const classified = TimeOfDay(row.date);
    if (classified === "AM") construct.AM.push(row);
    else if (classified === "PM") construct.PM.push(row);
  });

  return construct;
};

export {
  peakHour,
  TimeOfDay,
  TimeFormatting,
  SplitByDay,
  HasValues,
  DailyAvg,
  TotalCount,
  peakQuarter,
  PHF,
  generateMotions,
  AmPm
};
