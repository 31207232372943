import React from "react";
import styled from "styled-components";
import { matchPath } from "react-router-dom";
import allStudies from "../../../Data/Handlers/utils/allStudies";
import useStateWithUpdater from "../../../utils/useStateWithUpdater";
import { NavLink } from "react-router-dom";
import { FiHome } from "react-icons/fi";
import { formatDate } from '../../../Data/Handlers/utils/DateHelper';
import IntersectionFromStudy from '../../../Data/Handlers/utils/IntersectionFromStudy';

export default function StudySwitcher({authUser}) {
  const studies = allStudies(authUser);
  const match = matchPath(window.location.pathname, {
    path: "/studies/:study_id"
  });
  const study_id = match && match.params.study_id;
  const [currentStudyObj] = useStateWithUpdater(
    studies.find(obj => obj.study_id === study_id),
    study_id
  );

  const intersection = IntersectionFromStudy(authUser,study_id)
  const recDate =
    currentStudyObj &&
    formatDate(currentStudyObj.study_start, 'date')

  return (
    <>
      <Selected>
        {(currentStudyObj&&intersection) ? (
          <SelectedName to={`/studies/${study_id}`}>
            <FiHome style={{ marginRight: 10 }} /> 
            {intersection.intersection} | {recDate}
          </SelectedName>
        ) : null}
      </Selected>
    </>
  );
}

const Selected = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

const SelectedName = styled(NavLink)`
  margin-right: 4px;
  font-size: 0.8em;
  display: flex;
  align-items: center;
`;
