import { useEffect } from 'react';
import { modalMachine } from './modalMachine';
import { useMachine } from '@xstate/react';
import { useApolloClient } from '@apollo/react-hooks';
import SignURL from '../../../Data/Fetching/QueryLibrary/SignURL';


const useModal = ({ data, study_id }) => {
  const client = useApolloClient();
  const makeSignedUrl = (client, query, url, study_id) => client.query({
    query,
    fetchPolicy: 'no-cache',
    variables: {
      url: `client_studies/${study_id}/matlab_results/videos/eventVideos/${url}`
    }
  })
  const [state, send] = useMachine(modalMachine.withConfig({
    services: {
      makeSignedUrl: (c) => makeSignedUrl(client, SignURL, c.activeVideo, study_id)
    }
  }));

  useEffect(() => {
    send({
      type: 'SET_DATA',
      payload: {
        newData: data
      }
    })
  }, [data, send])

  return [state, send]
};

export default useModal;
