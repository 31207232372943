export const Comp114 ={
    compID: 114,
    compType: 1,
    title: "Right-Turns On Red By Approach",
    styling: {
      dots: true,
    },
    settings: {
      hasDropdown: false,
    },
    databox: [],
    lookupPath: {
      legend: {
        type: "approaches",
        formatting: "namesFull",
      },
      tooltip: {
        type: "approaches",
        formatting: "namesFull",
      },
    },
  }