export const Comp134 =   {
    compID: 134,
    compType: 4,
    title: "Vulnerable Road Users TMC",
    styling: {
      divSize: 30,
      justifyContent: "center",
    },
    lookupPath: {
      combined: false,
      dropdown: {
        type: "roadUsers",
        formatting: "full",
        specialLookup: {
          1: "Bikes",
          2: "Pedestrians",
          3: "Both",
        },
      },
    },
  }