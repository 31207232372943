import gql from "graphql-tag";

const QUERY = gql`
  query schematic($study_id: ID!, $lastkey:lastkey) {
    schematic(study_id: $study_id,lastkey:$lastkey, objectClass: [1, 2, 3, 4, 5]) {
      imageUrl
      data {
        pixX0
        pixX1
        pixY0
        pixY1
        posX0
        posX1
        posY0
        posY1
      }
      lastkey {
        study_id
        row_id
      }
    }
  }
`;

const dataHandler = data => data;

const q000 = {
  QUERY,
  dataHandler
};

export default q000;
