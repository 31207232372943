import React, { useState, useEffect } from "react";
import Spinner from "../../../Components/Spinner";
import CompStyleWrapper from "../style_components/CompStyleWrapper";
import Settings from "../Map/Settings";
import Filtering from "../Filtering";
import MapChart from "./MapChart";
import FilterError from "../Filtering/FilterError";
import useGpsMapDataFetcher from './useGpsMapDataFetcher';
import useMapLogic from './useMapLogic';

const GpsMap = ({ comp, thisStudy, authUser, theme }) => {
  const {
    schematicData,
    status,
    combinedEvents,
    filters,
    setFilters,
    piechartData,
    filterToggle,
    setfilterToggle,
    eventsDataFiltered,
  } = useGpsMapDataFetcher({ comp, thisStudy });

  const {
    hoveringRow,
    setHoveringRow,
    backgroundSwitch,
    schematicSwitch,
    setSelectedRows,
    showSchematic,
    setInteractive,
    interactive,
    showBackground,
    selectedRows,
    tableRows,
    setTableRows,
    setShowBackground
  } = useMapLogic();


  // VIEW RENDERING //
  if (status === "error") {
    console.log(`${comp.compID} couldn't load.`);
    return <></>;
  }

  if (status === "loading") {
    return (
      <CompStyleWrapper title={`${comp.title}`} theme={theme}>
        <Spinner theme={theme} />
      </CompStyleWrapper>
    );
  }

  if (status === "success") {
    return (
      <CompStyleWrapper theme={theme} title={`${comp.title}`}>
        <Filtering
          currentFilters={filters}
          setFilters={setFilters}
          setfilterToggle={setfilterToggle}
          setSelectedRows={setSelectedRows}
          filterToggle={filterToggle}
          comp={comp}
          eventsData={combinedEvents}
          thisStudy={thisStudy}
          theme={theme}
          screenshotElement={window.document.getElementById(`gps-map-${comp.compID}`)}
          allowScreenshot={authUser.admin}
        />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Settings
            showSchematic={showSchematic}
            schematicSwitch={schematicSwitch}
            setInteractive={setInteractive}
            interactive={interactive}
            withInteractiveSwitch={true}
            withDropdown={false}
            showBackground={showBackground}
            setShowBackground={setShowBackground}
            backgroundSwitch={backgroundSwitch}
            thisStudy={thisStudy}
            theme={theme}
          />
        </div>
        {eventsDataFiltered.length ? (
          <MapChart
            schematicData={schematicData.schematic}
            eventsDataFiltered={eventsDataFiltered}
            piechartData={piechartData}
            showSchematic={showSchematic}
            showBackground={showBackground}
            hoveringRow={hoveringRow}
            setHoveringRow={setHoveringRow}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            interactive={interactive}
            tableRows={tableRows}
            setTableRows={setTableRows}
            comp={comp}
            thisStudy={thisStudy}
            theme={theme}
          />
        ) : (
          <FilterError
            theme={theme}
            msg="No data, please adjust your filters."
          />
        )}
      </CompStyleWrapper>
    );
  }
};

export default GpsMap;
