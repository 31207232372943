import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Spinner from "../../../Components/Spinner";
import CompStyleWrapper from "../style_components/CompStyleWrapper";
import Map from "../Map/Map";
import useGetData from "../../../../Data/Fetching/useGetData";
import Settings from "../Map/Settings";
import CustomPieChart from "./CustomPieChart";
import { PieChartifyQuarterStats } from "../../../../Data/Handlers/PieChart/PieChartify";
import useGetPaginatedDataV2 from "../../../../Data/Fetching/useGetPaginatedDataV2";

const GpsDropdown = ({ comp, thisStudy, authUser, theme }) => {
  // fetching data
  const {
    dataFormatted: imageData,
    loading: imageLoading,
    error: imageError,
  } = useGetData({ compID: comp.compID, thisStudy });
  const {
    dataFormatted: schematicData,
    schematicLoading,
    schematicError,
  } = useGetPaginatedDataV2({ compID: "000", thisStudy });
  const {
    dataFormatted: piechartData,
    loading: piechartLoading,
    error: piechartError,
  } = useGetPaginatedDataV2({ compID: `${comp.compID}piechart`, thisStudy });

  const [showSchematic, setshowSchematic] = useState(true);
  const [imageUrls, setImageUrls] = useState([]);
  const [showBackground, setShowBackground] = useState(true);
  const [status, setStatus] = useState("loading");



  useEffect(() => {
    if (
      imageLoading ||
      schematicLoading ||
      piechartLoading ||
      (!imageData && !imageError) ||
      (!schematicData && !schematicError) ||
      (!piechartData && !piechartError)
    ) {
      setStatus("loading");
    } else if (
      imageError ||
      schematicError ||
      piechartError ||
      imageData === "empty" ||
      schematicData === "empty"
    ) {
      setStatus("error");
    } else if (imageData && schematicData && piechartData) {
      setImageUrls(imageData.images[0].urls);
      setStatus("success");
    }
  }, [
    imageData,
    schematicData,
    piechartData,
    imageLoading,
    schematicLoading,
    piechartLoading,
    imageError,
    schematicError,
    piechartError,
  ]);

  const schematicSwitch = () => {
    setshowSchematic(!showSchematic);
  };

  // loading?
  if (status === "loading") {
    return (
      <CompStyleWrapper theme={theme} title={`${comp.title}`}>
        <Spinner theme={theme} />
      </CompStyleWrapper>
    );
  }

  // error or empty?
  if (status === "error") {
    return <></>;
  }

  if (status === "success") {
    return (
      <CompStyleWrapper theme={theme} title={`${comp.title}`}>
        <Settings
          className="settings"
          theme={theme}
          showSchematic={showSchematic}
          schematicSwitch={schematicSwitch}
          withInteractiveSwith={false}
          data={imageData.images}
          lookupPath={comp.lookupPath}
          thisStudy={thisStudy}
          withDropdown={true}
          setImageUrls={setImageUrls}
          setShowBackground={setShowBackground}
          showBackground={showBackground}
          comp={comp}
          allowScreenshots={authUser.admin}
        />
        <Wrapper style={{ justifyContent: comp.styling.align }}>
          <Map
            theme={theme}
            withTraces={false}
            withImageDropdown={true}
            showSchematic={showSchematic}
            schematicData={schematicData.schematic}
            withOverlayImage={true}
            showBackground={showBackground}
            imageUrls={imageUrls}
            study_id={thisStudy.study_id}
            comp={comp}
          />

          {comp.piechart.display && piechartData !== "empty" && (
            <PieChartWrapper theme={theme}>
              <Fixer>
                <Inner>
                  <CustomPieChart
                    theme={theme}
                    data={PieChartifyQuarterStats({
                      data: Object.values(piechartData)[0],
                      thisStudy,
                      lookupPath: comp.lookupPath,
                    })}
                    thisStudy={thisStudy}
                    lookupPath={comp.lookupPath}
                    title={comp.piechart.title}
                  />
                </Inner>
              </Fixer>
            </PieChartWrapper>
          )}
        </Wrapper>
      </CompStyleWrapper>
    );
  }
};

const Wrapper = styled.div`
  display: flex;
`;

const PieChartWrapper = styled.div`
  flex: 2;
`;

const Fixer = styled.div`
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 65%;
  }
`;
const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export default GpsDropdown;
