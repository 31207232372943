const SumRow = row => {
    let count = null;
  
    const keys = Object.keys(row).filter(key => key !== "date");
    keys.forEach(key => {
      if (row[key] !== null) {
        count += row[key];
      }
    });
  
    return count;
}

export default SumRow;

