import { createMachine, assign } from "xstate";
import * as EmailValidator from "email-validator";

export const signup__machine = createMachine(
  {
    id: "signup",
    context: {
      email: "",
      name: "",
      admin: false,
      error: undefined,
    },
    initial: "editing",
    states: {
      editing: {
        on: {
          EDIT_EMAIL: {
            actions: "editEmail",
          },
          EDIT_NAME: {
            actions: "editName",
          },
          EDIT_ADMIN: {
            actions: "editAdmin",
          },
          SUBMIT: [
            {
              target: "submitting",
              cond: "isValidated",
            },
            {
              target: "editing",
            },
          ],
        },
      },
      submitting: {
        on: {
          SUCCESS: {
            target: "success",
          },
          REJECTED: {
            target: "failed",
          },
        },
      },
      success: {
        type: "final",
      },
      failed: {
        entry: "setError",
        type: "final",
      },
    },
  },
  {
    actions: {
      editEmail: assign((c, e) => {
        const { target } = e.payload;
        return {
          email: target,
          error: "",
        };
      }),
      editName: assign((c, e) => {
        const { target } = e.payload;
        return {
          name: target,
          error: "",
        };
      }),
      editAdmin: assign((c, e) => {
        const { target } = e.payload;
        return {
          admin: target,
          error: "",
        };
      }),
      setError: assign((c, e) => {
        const { error } = e.payload;
        console.log(e.payload);

        return {
          error: error.message,
        };
      }),
    },
    guards: {
      isValidated: (c, e) => {
        const validation = [EmailValidator.validate(c.email), c.name !== ""];
        return validation.every((x) => x === true);
      },
    },
  }
);
