import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';


export const screenshotAndSave = async (node) => {

  try {
    const blob = await domtoimage.toBlob(node, {
      cacheBust: true,
    });

    new Promise(resolve => setTimeout(() => {
      resolve(
        saveAs(blob, 'screenshot.png')
      )
    }, 1000))
  } catch (err) {
    console.log(err)
  }
}



