import { map, groupBy, toPairs, omit, prop, pipe } from 'ramda';

export const byIntersections = pipe(
  (data) => ({
    ...omit(["studies", "__typename"], data),
    intersections: pipe(
      map(study => ({
        ...study,
        ...study.intersection
      })),
      groupBy(prop("intersection_id")),
      toPairs,
      map(([key, array]) => array),
      map(arr => ({
        ...omit(["study_id", "study_start", "study_end", "downloadables", "__typename"], arr[0]),
        studies: arr.map(x => omit(["intersection", "intersection_id", "__typename"], x))
      }))
    )(data.studies)
  }))
