import gql from "graphql-tag";

const QUERY = gql`
  query lines($study_id: ID!, $lastkey: lastkey) {
    lines(
      study_id: $study_id
      lastkey: $lastkey
      eventClass: [1]
      conditionals: [{ key: "eventId", condition: "<=", value: 1000 }]
    ) {
      data {
        row_id
        t
        u_id
        x_pix
        y_pix
        eventId
        eventClass
      }
      lastkey {
        study_id
        row_id
      }
    }
  }
`;

const dataHandler = (data) => data;

const q212lines = {
  QUERY,
  dataHandler,
};

export default q212lines;
