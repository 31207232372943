import { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { findIndex, propEq } from 'ramda';
import gql from "graphql-tag";
import { byIntersections } from '../../utils/helpers';

const CLIENT_QUERY = gql`
    query clientById($client_id: ID!) {
      clientById(client_id: $client_id) {
        client_id
        studies(show_on_viz: true) {
          study_id
          study_start
          study_end
          show_on_viz
          intersection {
            intersection_id
            intersection
            all_streets {
              speed_limit
              street
            }
          }
          downloadables {
            file
            name
          }
        }
      }
    }
`;


const FETCH_ALL_USERS = gql`
  query {
    clients {
      client_id
      client_name
    }
  }
`


export const useIntersectionCardsController = (authUser) => {

  const [fetchAllUsers, { data: allUsersData }] = useLazyQuery(FETCH_ALL_USERS)
  const [idx, setIdx] = useState(null);
  const [intersections, setIntersections] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [allUsers, setAllUsers] = useState(null)
  const [currentUserId, setCurrentUserId] = useState(null)
  const { data } = useQuery(CLIENT_QUERY, {
    variables: {
      client_id: currentUserId || authUser.client_id
    }
  });






  // FETCH ALL USERS (ADMIN) //
  useEffect(() => {
    if (authUser && authUser.admin) {
      fetchAllUsers();
    }
  }, [authUser]);

  // SET ALL USERS //
  useEffect(() => {
    if (allUsersData && allUsersData.clients) {
      setAllUsers(allUsersData.clients.sort((a, b) => a.client_name > b.client_name ? 1 : -1))  // sort by client name
    }
  }, [allUsersData])

  // SET INTERSECTIONS //
  useEffect(() => {
    if (data && data.clientById) {
      setIntersections(byIntersections(data.clientById).intersections)
    }
  }, [data, authUser])

  // SET SHOWDROPDOWN //
  useEffect(() => {
    if (authUser && authUser.admin && allUsers && idx) {
      setShowDropdown(true);
    }
  }, [authUser, allUsers, idx])

  // SET INITIAL IDX //
  useEffect(() => {
    if (authUser && allUsers) {
      const id = findIndex(propEq('client_id', authUser.client_id))(allUsers);
      setIdx(id);
    }
  }, [authUser, allUsers])

  // SET CURRENT USER //
  useEffect(() => {
    if (allUsers && idx !== null) {
      setCurrentUserId(allUsers[idx].client_id)
    }
  }, [allUsers, idx])


  return {
    intersections,
    allUsers,
    idx,
    setIdx,
    showDropdown
  }

}
