import moment from 'moment'

export default (timestamp) => {
    if (typeof timestamp === "number") {
        const length = timestamp.toString().length
        if (length===10||length===12) {
            if (moment.unix(timestamp).utc().isValid()) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    } else if (typeof timestamp === "string") {
        const length = timestamp.length
        if (length===10||length===12) {
            if (moment.unix(timestamp).utc().isValid()) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    } else  {
        return false
    }
}