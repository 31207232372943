import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import _ from 'lodash';
import styled from "styled-components";
import MapFunctionality from '../Utils/MapFunctionality';

const TracesGroup = ({
  width,
  height,
  d,
  hoveringRow,
  setHoveringRow,
  selectedRows,
  setSelectedRows,
  settings,
  theme,
}) => {
  const [showCircle, setShowCircle] = useState(null);
  const [showLines, setShowLines] = useState(null);
  
  const handleClick = id => {
    if (selectedRows.includes(id)) {
      setSelectedRows(_.remove(selectedRows,d=>d!==id))
    } else {
      setSelectedRows([...selectedRows,id])
    }
  }

  useEffect(() => {
    const show = MapFunctionality({hoveringRow,selectedRows,row_id:d.row_id, settings})
    setShowCircle(show.circle)
    setShowLines(show.lines)
  }, [hoveringRow, selectedRows,d,settings]);

  const xScale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([0, width]);

  const yScale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([0, height]);

  const line = d3
    .line()
    .x(d => xScale(d.x_pix))
    .y(d => yScale(d.y_pix));

  return (
    <WrapperGroup>
      <Group id={d.row_id} className={`lines ${showLines && "show"}`}>
        <Line1 d={line(d.lines.line1)} theme={theme} />
        <Line2 d={line(d.lines.line2)} theme={theme} />
      </Group>
      <Group
        id={d.row_id}
        className={`circle ${showCircle && "show"}`}
        onMouseEnter={() => setHoveringRow({location: "map",id:d.row_id})}
        onMouseLeave={() => setHoveringRow(null)}
        onClick={()=>handleClick(d.row_id)}
      >
        {(d.x_pix&&d.y_pix) ? (
           <Circle cx={xScale(d.x_pix)} cy={yScale(d.y_pix)} theme={theme }/>
        ) : null}  
      </Group>
    </WrapperGroup>
  );
};

const WrapperGroup = styled.g`
  opacity: 1;
`;

const Group = styled.g`
  opacity: 0;
  &.show {
    opacity: 1;
  }
`;

const SvgTraces = ({ width, height, data, hoveringRow, setHoveringRow, selectedRows, setSelectedRows,comp,theme }) => {
  if (!data) {
    return <>Put Spinner here</>;
  }


  return data.map((d, i) => (
    <TracesGroup
      theme={theme}
      d={d}
      width={width}
      height={height}
      hoveringRow={hoveringRow}
      setHoveringRow={setHoveringRow}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      key={i}
      settings={comp.styling.mapDefaults}
    />
  ));
};

const Line1 = styled.path`
  fill: transparent;
  stroke: ${(props)=>props.theme.positive};
  stroke-width: 4px;
  opacity: 1;
  pointer-events: none;
`;

const Line2 = styled.path`
  fill: transparent;
  stroke: ${(props)=>props.theme.negative};
  stroke-width: 4px;
  opacity: 1;
  pointer-events: none;
`;

const Circle = styled.circle`
  cursor: pointer;
  z-index: 11;
  opacity: 1;
  fill: ${(props)=>props.theme.secondaryLight};
  r: 3;
`;

export default SvgTraces;
