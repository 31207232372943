import gql from 'graphql-tag';

export default gql`
    mutation signup(
      $email: String!
      $admin: Boolean!
      $client_name: String!
      $created_by: String!
    ) {
      signup(
        email: $email
        admin: $admin
        client_name: $client_name
        created_by: $created_by
      ) {
        client_id
        client_name
        email
        password
        admin
        created_by
      }
    }
`;
