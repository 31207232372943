import React, { useState, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import styled from "styled-components";
import { formatDate } from "../../../../Data/Handlers/utils/DateHelper";

const CustomSlider = ({ currentFilters, setFilters, filter, width, theme }) => {
  const options = currentFilters[filter.dataKeys.join("")].options;
  const [value, setValue] = useState([options.min, options.max]);

  const valueFormat = (val) => {
    if (filter.lookup.type === "time") {
      return formatDate(val, "short");
    } else if (filter.lookup.type === "number") {
      return val.toFixed(filter.lookup.formatting);
    }
  };

  useEffect(() => {
    if (!currentFilters.applied.filters) {
      setValue([options.min, options.max]);
    }
  }, [currentFilters, options.max, options.min]);

  const handleChange = (e, v) => {
    setValue(v);

    const newFilters = {
      ...currentFilters,
      applied: {
        ...currentFilters.applied,
        filters: {
          ...currentFilters.applied.filters,
          [filter.dataKeys.join("")]: {
            keys: {
              [filter.dataKeys.join("")]: {
                filterValues: [v[0], v[1]],
                type: "range",
              },
            },
          },
        },
      },
    };
    setFilters(newFilters);
  };

  return (
    <Container style={{ width: width < 400 ? `400px` : `${width}px` }}>
      <FilterTitle>{filter.title}</FilterTitle>
      <SliderWrapper>
        <CurrentValue>{valueFormat(value[0])}</CurrentValue>
        <StyledSlider
          theme={theme}
          min={options.min}
          max={options.max}
          value={value}
          onChange={handleChange}
          step={0.1}
        />
        <CurrentValue>{valueFormat(value[1])}</CurrentValue>
      </SliderWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-right: 2em;
`;

const StyledSlider = styled(Slider)`
  flex: 0 80%;
  color: ${(props) => props.theme.primary + "!important"};
`;

const CurrentValue = styled.div`
  flex: 0 10%;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  margin-right: 5px;
`;
const FilterTitle = styled.div`
  width: 100%;
  padding-left: 15px;
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default CustomSlider;
