import Nullify from "../utils/Nullify";

function CleanData(data) {
  let newData = null;

  if (data) {
    if (data.quarterStats) {
      newData = data.quarterStats.data;
    } else {
      newData = data;
    }

    let formattedData = newData.map((d) => ({
      posixTime: Number(d.posixTime),
      value: Nullify(d.value),
      armA: Number(d.armA),
      armR: Number(d.armR),
      roadUserClass: Number(d.roadUserClass),
    }));

    //if (_.compact(formattedData.map(d => d.value)).length === 0)
    if (formattedData.filter((d) => d !== null).length === 0) {
      return null;
    } else {
      return formattedData;
    }
  } else {
    return null;
  }
}

export default CleanData;
