import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Views/Home/Home";
import styled from "styled-components";
import StudiesRouter from "./Views/Studies/StudiesRouter";
import SignUp from "./Views/Users/SignUp";
import ForgotPassword from "./Views/Users/ForgotPassword";
import Login from "./Views/Users/Login";
import SecureRoute from "./AuthRouteHoc";
import IframeSecuredRoute from "./IframeSecuredRoute";
import { useMachine } from "@xstate/react";
import { theme__machine } from "./machines/theme";

export default () => {
  const [state, send] = useMachine(theme__machine);

  return (
    <Router>
      <Padder>
        <Switch>
          <IframeSecuredRoute
            path="/iframe/studies/:study_id"
            component={StudiesRouter}
            theme={state.context.theme}
            updateTheme={send}
          />
          <SecureRoute
            path="/studies/:study_id"
            component={StudiesRouter}
            theme={state.context.theme}
            updateTheme={send}
          />
          <SecureRoute
            path="/admin/signup"
            component={SignUp}
            theme={state.context.theme}
            updateTheme={send}
          />
          <Route
            path="/login"
            render={(props) => (
              <Login
                {...props}
                theme={state.context.theme}
                updateTheme={send}
              />
            )}
          />
          <SecureRoute
            exact
            path="/"
            component={Home}
            theme={state.context.theme}
            updateTheme={send}
          />
          <Route
            path="/forgot"
            render={(props) => (
              <ForgotPassword {...props} theme={state.context.theme} />
            )}
          />
        </Switch>
      </Padder>
    </Router>
  );
};

const Padder = styled.div`
  padding-top: 50px;
`;
