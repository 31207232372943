import _ from "lodash";

const DropDownSwitch = ({
  state,
  appliedFilters,
  selection,
  options,
  approach
}) => {
  // ----------------------------------------------------------------------
  // takes array of values and returns the correct elements to be selected
  // ----------------------------------------------------------------------

  // create an empty object to store state
  let construct;

  const allOptions = Object.keys(options).map(d=>({
    label: options[d].label,
    value: options[d].index
  }))

  const newSelection = makeSelection({ selection, options: allOptions, state });

  construct = {
    dropdown: newSelection,
    appliedFilters: createAppliedFilters({
      selection: newSelection,
      options,
      appliedFilters
    })
  };
  // if the dropdown is for approaches
  if (approach) {
    construct.appliedFilters.settings = updateSettings({
      approach,
      selection: newSelection,
      options
    });
  }
  return construct;
};

const makeSelection = ({ selection, options, state }) => {
  let sel = selection;

  // if there's a selection
  if (sel.length) {
    // & first element is "all"
    if (sel[0].value === -1) {
      sel = sel.slice(1, sel.length);
    }

    // & last element is "all"
    if (sel[sel.length - 1].value === -1) {
      // selection length < state length
      if (sel.length < state.length) {
        sel = sel.filter(d => d.value !== -1);
      } else {
        sel = [{ label: "all", value: -1 }];
      }
    }
    // all but "all" selected
    if (sel.length + 1 === options.length) {
      if (!sel.filter(d => d.value === -1).length) {
        sel = [{ label: "all", value: -1 }];
      }
    }
  } else {
    if (state.length) {
      sel = [...state];
    } else {
      sel = [state]
    }
  }
  return sel;
};

const createAppliedFilters = ({ selection, options, appliedFilters }) => {

  console.log(selection)
  console.log(options)
  console.log(appliedFilters)

  let construct = appliedFilters;

  const filterName = Object.keys(Object.values(options)[0].filterValues).join('')

  if (selection.filter(d => d.value === -1).length === selection.length) {
    if (construct.filters) {
      construct.filters[filterName]=null
    } else {
      construct.filters = {}
    }
  } else {

    let newOptions = {}
    // only options that are selected and no 'all'
    Object.keys(options).forEach(option=>{
      if (selection.map(sel=>sel.value).includes(options[option].index) && option !== "all") {
        newOptions[option]=options[option]
      } 
    })

    const keys = getNewFilterValues({ options:newOptions });
    const names = {
      ids:Object.keys(newOptions),
      lookup:Object.values(newOptions)[0].lookup
    }
  
    const filterNames = Object.keys(newOptions).map(option=>Object.keys(newOptions[option].filterValues).join(''))
    
    filterNames.forEach(filterName => {

      if (construct.filters) {
        if (construct.filters[filterName]) {
          construct.filters[filterName].keys = updateFilterValues({
            keys,
            currentFilter: construct.filters[filterName]
          });
          construct.filters[filterName].names = names
        } else {
          construct.filters[filterName] = {
            keys: keys,
            names: names
          };
        }
      } else {
        construct.filters = {
          [filterName]: {
            keys: keys,
            names:names
          }
        };
      }
    });
  }
  return construct;
};

const getNewFilterValues = ({ options }) => {
  let construct = {};

  Object.keys(options).forEach(option=>{
    Object.keys(options[option].filterValues).forEach(filterName=>{
      const newValue = options[option].filterValues[filterName]
      if (construct[filterName]){
        construct[filterName].filterValues.push(...newValue)
      } else {
        construct[filterName] = {
          filterValues: newValue,
          type:options[option].type
        }
      }
      construct[filterName].filterValues = _.uniq(construct[filterName].filterValues)
    })
  })
  return construct;
};

const updateSettings = ({ approach, selection, options }) => {
  let construct = {
    approachSelected: null
  };

  
  approach.forEach(key => {
    let selected = []

    Object.keys(options).forEach(option=>{
      if (selection.map(d=>d.value).filter(d=>d!==-1).includes(options[option].index)){
        selected.push(...options[option].filterValues[key])
      }
    })

    if (selected.length) {
      // update the settings object to reflect new selection
      construct.approachSelected = {
        [key]: {
          values: selected,
          armR: `${key.slice(0, -1)}R`
        }
      };
    }
  });
  return construct;
};

const updateFilterValues = ({ keys, currentFilter }) => {
  let construct = {};

  Object.keys(currentFilter.keys).forEach(filter => {
    const latest = keys[filter].filterValues
    construct[filter] = {
      filterValues: latest,
      type: currentFilter.keys[filter].type
    };
  });
  return construct;
};

export default DropDownSwitch;
export {
  makeSelection,
  createAppliedFilters,
  updateSettings,
  updateFilterValues,
  getNewFilterValues
};
