import { createMachine } from 'xstate';
import { screenshotAndSave } from '../utils/screenshot';

export const screenshot__machine = createMachine({
  id: "screenshot",
  context: {},
  initial: 'idle',
  states: {
    idle: {
      on: {
        SCREENSHOT: {
          target: 'processing'
        }
      }
    },
    processing: {
      invoke: {
        src: (_, event) => screenshotAndSave(event.payload.node),
        onDone: {
          actions: () => console.log("done!"),
          target: 'idle'
        },
        onError: 'idle'
      }
    },
  }
})

