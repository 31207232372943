import React from "react";
import styled from "styled-components";

export default ({ msg, theme }) => {
  return <FilterError>{msg}</FilterError>;
};

const FilterError = styled.div`
  text-align: center;
  height: 125px;
`;
