import React from "react";
import styled from "styled-components";
import Coloring from "../Utils/Coloring";
import Naming from "../Utils/Naming";
import useLegendController from './uselegendcontroller';

export default ({
  lines,
  thisStudy,
  lookupPath,
  activatedKeys,
  setActivatedKeys,
}) => {

  const { legendKeys } = useLegendController({ thisStudy, activatedKeys, setActivatedKeys, lines, lookupPath })

  const setLegendToggle = (key) => {
    // toggle legend key on / off
    setActivatedKeys({
      ...activatedKeys,
      [key]: !activatedKeys[key],
    });
  };

  return (
    <Legend>
      {legendKeys.map((key, i) => (
        <LegendItem
          color={
            activatedKeys[key]
              ? Coloring({
                key,
                lookup: thisStudy.lookup,
                type: lookupPath.legend.type,
                formatting: lookupPath.legend.formatting,
              })
              : "lightgrey"
          }
          key={i}
          onClick={() => setLegendToggle(key)}
        >
          {Naming({ thisStudy, lookupPath, key, part: "legend" })}
        </LegendItem>
      ))}
    </Legend>
  );
};

const Legend = styled.div`
  display: flex;
  align-items: center;
`;

const LegendItem = styled.div`
  font-size: 0.75em;
  background-color: ${(props) => props.color};
  color: white;
  padding: 4px 10px;
  border-radius: 10px;
  margin: 0 2px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
