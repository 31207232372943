import React, { useState } from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import MenuBar from "./MenuBar";
import MenuGroupTitle from "./MenuGroupTitle";
import { FiCornerDownLeft, FiHome } from "react-icons/fi";
import { SiteStructure } from "../../SiteStructure";
import WithMenuStyling from "./components/WithMenuStyling";
import Spinner from "../Components/Spinner";

export default ({ studyID, thisStudy, iframeView, theme }) => {
  const active_routes = thisStudy && thisStudy.active_routes;
  const [open, setOpen] = useState(false);
  const mainPath = iframeView
    ? `/iframe/studies/${studyID}`
    : `/studies/${studyID}`;

  if (!thisStudy) return <Spinner theme={theme} />;

  return (
    <WithMenuStyling open={open} setOpen={setOpen} theme={theme}>
      {!iframeView && (
        <>
          <MenuItem
            route="/"
            title="Back to All Studies"
            icon={<FiCornerDownLeft />}
            open={open}
            setOpen={setOpen}
            theme={theme}
          />
          <MenuBar open={open} theme={theme} />
        </>
      )}

      <MenuItem
        route={mainPath}
        title="Study Dashboard"
        icon={<FiHome />}
        open={open}
        setOpen={setOpen}
        theme={theme}
      />

      <MenuBar open={open} theme={theme} />

      {SiteStructure.map((section, i) => (
        <div key={i}>
          <MenuGroupTitle
            key={i}
            titleShort={section.titleShort}
            titleLong={section.titleLong}
            open={open}
            theme={theme}
          />

          {section.routes
            .filter((rte) => active_routes.map(x => Number(x.route)).includes(rte.id))
            .map(({ path, title, icon }, i) => (
              <MenuItem
                key={i}
                route={`${mainPath}/${path}`}
                title={title}
                icon={icon}
                open={open}
                setOpen={setOpen}
                theme={theme}
              />
            ))}
        </div>
      ))}

      <FlexSpacer />
    </WithMenuStyling>
  );
};

const FlexSpacer = styled.div`
  flex: 1;
`;
