import { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import * as queries from "./Queries";

export default ({ compID, thisStudy }) => {
  const study_id = thisStudy.study_id;
  const QUERY = queries["q" + compID].QUERY;
  const dataHandler = queries["q" + compID].dataHandler;
  const [dataFormatted, setDataFormatted] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data, error, fetchMore } = useQuery(QUERY, {
    errorPolicy: "all",
    variables: {
      study_id,
    },
  });

  useEffect(() => {
    if (data) {
      if (data[Object.keys(data)[0]].lastkey) {
        const key_ = Object.keys(data)[0];
        const { row_id } = data[key_].lastkey;

        fetchMore({
          query: QUERY,
          variables: {
            study_id,
            lastkey: {
              study_id,
              row_id,
            },
          },
          updateQuery: (previousResults, { fetchMoreResult }) => {
            const currentRowIds = previousResults[key_].data.map(
              (x) => x.row_id
            );
            const newEntries = fetchMoreResult[key_].data.filter(
              (x) => !currentRowIds.includes(x.row_id)
            );

            return {
              [key_]: {
                data: [...previousResults[key_].data, ...newEntries],
                lastkey: fetchMoreResult[key_].lastkey,
                // previousResults[key_].data.length &&
                // fetchMoreResult[key_].data.length &&
                // !newEntries.length
                //   ? null
                //   : fetchMoreResult[key_].lastkey,

                __typename: previousResults[key_].__typename,
              },
            };
          },
        });
      } else {
        setLoading(false);
        setDataFormatted(dataHandler(data));
      }
    }
  }, [data, study_id, dataHandler]);

  return {
    dataFormatted,
    loading,
    error,
  };
};
