import React from "react";
import styled from "styled-components";

const MenuBar = ({ open, theme }) => (
  <Wrapper>
    <Bar theme={theme} />
  </Wrapper>
);
export default MenuBar;

const Wrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bar = styled.div`
  height: 1px;
  width: calc(100% - 34px);

  background-color: ${(props) => props.theme.whiteBoxBorder};
`;
