function allStudies(authUser){
    let studies = [];
    authUser.intersections.map(int=>
        int.studies.map(study=>
            studies.push(study)
        )    
    )
    return studies
}

export default allStudies;