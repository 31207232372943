import gql from "graphql-tag";
import CleanImages from '../../Handlers/Images/CleanImages'

const QUERY = gql`
  query images($study_id: ID!) {
    images(study_id: $study_id, comp_id: 234) {
      dropdown {
        type
        key
      }
      urls {
        url
        divIndex
        imageID
      }
    }
  }
`;

const dataHandler = data => CleanImages(data);

const q234 = {
  QUERY,
  dataHandler
};

export default q234;
