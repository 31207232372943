import React from "react";
import { Helmet } from "react-helmet";

export default function UpdateTitle({ title }) {
  return (
    <Helmet>
      <title>{title} | Street Simplified</title>
    </Helmet>
  );
}
