import React from "react";
import CompStyleWrapper from "../style_components/CompStyleWrapper";
import Spinner from "../../../Components/Spinner";
import Chart from "./Chart";
import FilterBar from "./FilterBar";
import DataBox from "./DataBox";
import { Domain } from "../../../../Data/Handlers/utils/helpers";
import useLineChartController from './uselinechartcontroller';

export default ({ comp, thisStudy, theme }) => {
  const {
    chartIndex,
    setChartIndex,
    data,
    status,
    hasDropdown,
    volumeNormalizer,
    setvolumeNormalizer,
    activatedKeys,
    setActivatedKeys
  } = useLineChartController({ comp, thisStudy })

  // data being fetched?
  if (status === "loading")
    return (
      <CompStyleWrapper theme={theme} title={`${comp.title}`}>
        <Spinner theme={theme} />
      </CompStyleWrapper>
    );

  // error or empty occured?
  if (status === "error") {
    return <></>;
  }

  if (status === "success") {
    return (
      <CompStyleWrapper title={`${comp.title}`} theme={theme}>
        <DataBox
          data={data[chartIndex]}
          databox={comp.databox}
          approach={hasDropdown ? chartIndex : null}
          thisStudy={thisStudy}
          theme={theme}
        />
        <FilterBar
          data={volumeNormalizer ? [] : data}
          chartIndex={chartIndex}
          setChartIndex={setChartIndex}
          setvolumeNormalizer={setvolumeNormalizer}
          volumeNormalizer={volumeNormalizer}
          lookupPath={comp.lookupPath}
          setActivatedKeys={setActivatedKeys}
          activatedKeys={activatedKeys}
          thisStudy={thisStudy}
          hasDropdown={hasDropdown}
          theme={theme}
        />

        <div style={{ margin: "10px" }}>
          <Chart
            data={volumeNormalizer ? [] : data[chartIndex]}
            domainY={volumeNormalizer ? [0, 1] : Domain(data)}
            volumeNormalizer={volumeNormalizer}
            lookupPath={comp.lookupPath}
            approach={hasDropdown ? chartIndex : null}
            activatedKeys={activatedKeys}
            thisStudy={thisStudy}
            comp={comp}
            theme={theme}
          />
        </div>
      </CompStyleWrapper>
    );
  }
};
