export const Comp246 = {
  compID: 246,
  compType: 4,
  title: "RLR bar plot by direction",
  styling: {
    divSize: 60,
    justifyContent: "center",
  },
  lookupPath: {
    combined: false,
    dropdown: {
      type: "special",
      formatting: "",
      specialLookup: {
        1: "",
      },
    },
  },
};
