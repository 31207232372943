import React, { useState } from "react";
import styled from "styled-components";

const MenuItem = ({ active, text, setDisplay, theme }) => {
  const [hover, setHover] = useState(false);

  let classes = [];

  if (active || (active && hover)) {
    classes = "active";
  }

  if (hover && !active) {
    classes = "hover";
  }

  return (
    <Item
      theme={theme}
      className={classes}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => setDisplay(text.toLowerCase())}
    >
      <span>{text}</span>
    </Item>
  );
};

const Item = styled.div`
  flex: 1;
  height: 100%;
  background: #fafafa;
  margin: auto;
  text-align: center;
  line-height: 30px;
  &.active {
    background: ${(props) => props.theme.gradient};
    color: ${(props) => props.theme.textLight};
  }
  &.hover {
    background: #eeeeee;
    color: ${(props) => props.theme.textDark};
  }
`;

export default MenuItem;
