export const Comp111  = {
    compID: 111,
    compType: 1,
    title: "Vehicle Counts",
    styling: {
      dots: true,
    },
    settings: {
      hasDropdown: false,
    },
    databox: [
      // row 1
      {
        title: "Overview",
        stats: [
          {
            title: "Total Count",
            type: "totalCount",
          },
          {
            title: "Daily Average",
            type: "dailyAvg",
          },
          {
            title: "PHF (AM)",
            type: "phf",
            option: "am",
          },
          {
            title: "PHF (PM)",
            type: "phf",
            option: "pm",
          },
        ],
      },
      // row 2
      {
        title: "Peaks",
        stats: [
          {
            title: "Peak Hour (AM)",
            type: "peakHour",
            option: "am",
          },
          {
            title: "Peak Hour (PM)",
            type: "peakHour",
            option: "pm",
          },
          {
            title: "Peak Quarter (AM)",
            type: "peakQuarter",
            option: "am",
          },
          {
            title: "Peak Quarter (PM)",
            type: "peakQuarter",
            option: "pm",
          },
        ],
      },
      {
        title: "Median Speed per Motion",
        stats: [
          {
            title: "Median Speed",
            type: "medianSpeed",
          },
        ],
      },
    ],
    lookupPath: {
      dropdown: {
        type: "other",
        formatting: "count",
      },
      legend: {
        type: "other",
        formatting: "count",
      },
      tooltip: {
        type: "other",
        formatting: "count",
      },
    },
  }