import {
  curry,
  pipe,
  pluck,
  all,
  prop,
  filter,
  map,
  flatten,
  values,
  toPairs,
  fromPairs,
  difference,
  keys,
  reject,
  equals,
  uniq,
  uniqBy,
  propEq,
  dissoc,
  ascend,
  sortWith,
} from "ramda";

export const getAllStudies = pipe(map(prop("studies")), flatten);

// export const getStudyById = curry((study_id, studies) =>
//   pipe(find(propEq("study_id", study_id)))(studies)
// );

// export const isCorrectStudy = curry((study_id, intersection) =>
//   pipe(prop("studies"), getStudyById(study_id))(intersection)
// );

// export const getIntersectionById = curry((study_id, intersections) =>
//   pipe(find(isCorrectStudy(study_id)))(intersections)
// );

export const getCurrentUser = curry((currentUser, intersections) => {
  const filteredIntersections = filter(
    propEq("client_id", currentUser.client_id),
    intersections
  );
  return {
    ...currentUser,
    intersections: filteredIntersections,
  };
});

export const getAllUniqueUsers = pipe(
  map((x) => ({ client_id: x.client_id, client_name: x.client_name })),
  uniqBy(prop("client_id")),
  sortWith([ascend(prop("client_name"))])
);

export const flattenRoutes = pipe(pluck("routes"), flatten);

export const queriesList = (state, routeId) => {
  if (state.routes[routeId]) {
    return Object.values(state.routes[routeId]);
  }
  return null;
};

export const shouldRequest = curry((study_id, msg) => {
  // new study or not yet dispatched
  if (msg.study_id !== study_id || !msg.status) {
    return true;
  }
  return false;
});

export const allDataRecieved = curry((dataState, routeId, component) =>
  pipe(
    prop("data"),
    values,
    all(({ id }) => dataState.routes[routeId][id])
  )(component)
);

// export const refreshToken = async (Auth, onSuccess, onError) => {
//   await Auth.currentSession()
//     .then((res) => console.log(`refresh`))
//     .catch((err) => onError(true));
//   return;
// };

export const filterActiveComponents = (components, active_comps) =>
  components.filter((comp) => active_comps.includes(comp.compID));

export const componentsToRender = curry((hasAllData, components) =>
  pipe(
    filter((comp) => comp.data),
    filter(hasAllData)
  )(components)
);

export const transformAndSum = curry((sumFn, pair) => {
  const [key, objGrouped] = pair;

  const summedRow = pipe(
    toPairs,
    map(([key, array]) => [key, sumFn(array)]),
    fromPairs
  )(objGrouped);

  return {
    date: key,
    ...summedRow,
  };
});

export const addKeys = curry((allKeys, row) => {
  const keysToAdd = difference(
    map((d) => d.toString(), allKeys),
    pipe(keys, reject(equals("date")))(row)
  );

  const newKeysAsObject = pipe(
    map((key) => [key, null]),
    fromPairs
  )(keysToAdd);

  return keysToAdd.length
    ? {
        ...row,
        ...newKeysAsObject,
      }
    : row;
});

export const uniqGroup = curry((groupKey, joinWith, data) => {
  const joinIfNeeded = curry((joinWith, key) =>
    joinWith ? `${joinWith}${key}` : key
  )(joinWith);

  return pipe(
    pluck(groupKey),
    uniq,
    reject(equals(-1)),
    map(joinIfNeeded)
  )(data);
});

export const appendKeys = curry((joinWith, object) =>
  joinWith
    ? pipe(
        toPairs,
        map(([key, value]) => [`${joinWith}${key}`, value]),
        fromPairs
      )(object)
    : object
);

export const Domain = (data) => {
  // ---------------------------------------------------------------------------
  // takes data from each possible dataset and returns list with min/max values.
  // she'll round upwards to the closest even number
  // ---------------------------------------------------------------------------

  const getMaxMin = (array) => {
    const roundUp = (n) => (n % 10 === 0 ? n : roundUp(n + 1));
    const max = Math.floor(Math.max(...array));

    return [0, max < 1 ? 10 : roundUp(max)];
  };

  return pipe(
    values,
    map(map(dissoc("date"))),
    map(map(values)),
    flatten,
    getMaxMin
  )(data);
};

export const isIframe = () => (window.name !== "" ? true : false);

export const generateLegendKeys = (data) => {
  return pipe(
    map(keys),
    flatten,
    uniq,
    filter((x) => x !== "date")
  )(data);
};

export const toolTipFormat = (val) => {
  if (val % 1 === 0) {
    return val;
  }
  return val.toFixed(2);
};
