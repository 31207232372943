import React from "react";
import GpsMap from "./Comps/GpsMap/GpsMap";
import SafetyLineChart from "./Comps/LineChart/SafetyLineChart";
import ImageDropdown from "./Comps/ImageDropdown/ImageDropdown";
import GpsDropdown from "./Comps/GpsDropdown/GpsDropdown";
import LineChart from "./Comps/LineChart/LineChart";
import FadeIn from "../../utils/FadeIn";

// flight control component

export default ({ comp, i, thisStudy, authUser, theme }) => {


  if (comp.compType === 1)
    return (
      <FadeIn order={i} key={i}>
        <LineChart
          comp={comp}
          thisStudy={thisStudy}
          authUser={authUser}
          theme={theme}
        />
      </FadeIn>
    );

  if (comp.compType === 2)
    return (
      <FadeIn order={i} key={i}>
        <GpsMap
          comp={comp}
          thisStudy={thisStudy}
          authUser={authUser}
          theme={theme}
        />
      </FadeIn>
    );

  if (comp.compType === 3)
    return (
      <FadeIn order={i} key={i}>
        <SafetyLineChart
          comp={comp}
          thisStudy={thisStudy}
          authUser={authUser}
          theme={theme}
        />
      </FadeIn>
    );

  if (comp.compType === 4)
    return (
      <FadeIn order={i} key={i}>
        <ImageDropdown
          comp={comp}
          thisStudy={thisStudy}
          authUser={authUser}
          theme={theme}
        />
      </FadeIn>
    );

  if (comp.compType === 5)
    return (
      <FadeIn order={i} key={i}>
        <GpsDropdown
          comp={comp}
          thisStudy={thisStudy}
          authUser={authUser}
          theme={theme}
        />
      </FadeIn>
    );
};
