import React from "react";
import styled from "styled-components";

export default ({ children, open, setOpen, theme }) => {
  return (
    <>
      <OuterWrapper>
        <MenuWrapper
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          className={open && "open"}
          theme={theme}
        >
          {children}
        </MenuWrapper>
      </OuterWrapper>
      <BgDarkener className={open && "open"} theme={theme} />
    </>
  );
};

const OuterWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 50px;
  left: 0;
  height: calc(100% - 50px);
  overflow: visible;
`;

const MenuWrapper = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.textLight};
  border-right: 1px solid ${(props) => props.theme.whiteBoxBorder};
  overflow: auto;
  display: flex;
  flex-direction: column;

  width: 70px;
  transition: 250ms cubic-bezier(0.24, 0.75, 0.5, 0.95);
  &.open {
    width: 250px;
  }
`;

const BgDarkener = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.textDark};
  user-select: none;

  transition: opacity 250ms linear, visibility 0ms linear 250ms;
  opacity: 0;
  visibility: hidden;
  &.open {
    opacity: 0.2;
    visibility: visible;
    transition: opacity 250ms linear, visibility 0ms linear 0ms;
  }
`;
