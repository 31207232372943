import React from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import styled from "styled-components";
import Coloring from "../Utils/Coloring";
import Naming from "../Utils/Naming";

const CustomPieChart = ({ data, thisStudy, lookupPath, title, theme }) => {
  const labelText = (value) => {
    return `${(value.percent * 100).toFixed(2)}% (${value.value})`;
  };

  return (
    <Wrapper>
      <Title>
        <span>{title}</span>
      </Title>
      <PieChartWrapper>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="40%"
              outerRadius="65%"
              innerRadius="0%"
              label={labelText}
            >
              {data.map((slice, i) => (
                <Cell
                  fill={Coloring({ thisStudy, lookupPath, key: slice.name })}
                  key={i}
                />
              ))}
            </Pie>
            <Legend
              verticalAlign="middle"
              align="right"
              width={200}
              iconType="line"
              layout="vertical"
              formatter={(value, entry, index) =>
                Naming({ thisStudy, lookupPath, key: value, part: "piechart" })
              }
            />
          </PieChart>
        </ResponsiveContainer>
      </PieChartWrapper>
    </Wrapper>
  );
};

const Title = styled.div`
  flex: 1 25%;
  padding-top: 10%;
  font-size: 16px;
  font-weight: 550;
  text-align: center;
`;

const PieChartWrapper = styled.div`
  flex: 1 75%;
  position: relative;
  overflow: hidden;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default CustomPieChart;
