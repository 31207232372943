import { useState, } from "react";
import { useQuery } from "@apollo/react-hooks";
import * as queries from "./Queries";

export default ({ compID, thisStudy }) => {
  const study_id = thisStudy.study_id;
  const QUERY = queries["q" + compID].QUERY;
  const dataHandler = queries["q" + compID].dataHandler;
  const [dataFormatted, setDataFormatted] = useState(null);

  const { error, loading} = useQuery(QUERY,{
    errorPolicy:"all",
    fetchPolicy:"no-cache",
    variables:{
      study_id
    },
    onCompleted: data => {
      setDataFormatted(dataHandler(data))
    },
    onError: async err => {
      console.log(err)
    }
  })


  return { dataFormatted, loading, error };
};