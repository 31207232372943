export const Comp242 = {
  compID: 242,
  compType: 3,
  title: "Red-Light Running Per Approach",
  styling: {
    dots: true,
  },
  settings: {
    quarterStatsTransformation: {
      type: "byApproach",
      countDuplicates: true,
    },
    hasDropdown: false,
  },
  databox: [],
  filtering: [
    {
      title: "General",
      filters: [
        {
          dataKeys: ["videoFileNamesSmall"],
          dataType: "boolean",
          title: "Events with Video",
        },
        {
          dataKeys: ["t_posix"],
          dataType: "range",
          width: 400,
          title: "Time Period",
          lookup: {
            type: "time",
            formatting: "short",
          },
        },
        {
          dataKeys: ["tMargin"],
          dataType: "range",
          width: 400,
          title: "Running Time",
          lookup: {
            type: "number",
            formatting: 1,
          },
        },
      ],
    },
    {
      title: "User 1",
      filters: [
        {
          dataKeys: ["class1"],
          dataType: "discreet",
          title: "Type",
          lookup: {
            type: "roadUsers",
            formatting: "full",
          },
        },
        {
          dataKeys: ["arm1A"],
          dataType: "discreet",
          title: "Approach",
          lookup: {
            type: "approaches",
            formatting: "namesFull",
          },
        },
        {
          dataKeys: ["arm1A", "arm1R"],
          dataType: "discreet",
          title: "Motion",
          lookup: {
            type: "motions",
            formatting: "namesFull",
          },
        },
        {
          dataKeys: ["v1"],
          dataType: "range",
          width: 400,
          title: "Speed",
          lookup: {
            type: "number",
            formatting: 1,
          },
        },
      ],
    },
  ],
  lookupPath: {
    dropdown: {
      type: "other",
      formatting: "count",
    },
    legend: {
      type: "approaches",
      formatting: "namesFull",
    },
    tooltip: {
      type: "approaches",
      formatting: "namesFull",
    },
  },
};
