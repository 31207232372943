import _ from "lodash";
import QuarterRange from "../utils/QuarterRange";
import * as d3 from "d3";
import CleanData from "../../Handlers/QuarterStats/CleanData";

const QuarterStatsify = ({ data, type, countDuplicates }) => {
  // ------------------------------------------------
  // take Eventsdata and transform into QuarterStats
  // ------------------------------------------------


  if (data.length) {
    let construct = [];

    const users = groupUsers(data);
    const options = allOptions({ type, data });

    // create a timeseries range based on the events data
    const timeseries = QuarterRange(
      d3.min(data, (d) => d.t_posix),
      d3.max(data, (d) => d.t_posix),
      true
    );


    // iterate through each timestamp
    timeseries.forEach((time, i) => {
      // find all events in this 15 min time frame
      const slicedData = data.filter(
        (d) => d.t_posix >= time && d.t_posix < time + 900
      );

      let counter;

      if (type === "byEvent") {
        if (slicedData.length) {
          counter = {
            byEvent: {
              armA:-1,
              armR:-1,
              roadUserClass:-1,
              value: slicedData.length
            }
          }
        } else {
          counter = {
            byEvent: {
              armA:-1,
              armR:-1,
              roadUserClass:-1,
              value:0
            }
          }
        }
      } 
      else {

        counter = countEvents({
          timestamp: time,
          slicedData,
          users,
          allOptions: options,
          type,
          countDuplicates,
        });

      }

      // add the counts to construct
      if (counter) {
        Object.values(counter).forEach((row) => {
          construct.push({
            posixTime: time,
            armA: row.armA,
            armR: row.armR,
            roadUserClass: row.roadUserClass,
            value: row.value,
          });
        });
      }

    });
    return CleanData(construct);
  } else {
    return null;
  }
};

const countEvents = ({
  timestamp,
  users,
  slicedData,
  allOptions,
  type,
  countDuplicates,
}) => {
  const constructID = (type, option) => {
    if (type === "byApproach") {
      return `armA-${option}`;
    } else if (type === "byMotion") {
      return `armA-${option[0]}armR-${option[1]}`;
    } else if (type === "byUsers") {
      return `armA-${option[0]}armR-${option[1]}roadUserClass-${option[2]}`;
    } else {
      return null;
    }
  };


  const rowCounter = (row, type, users, countDuplicates) => {
    let options = [];


    users.forEach((user) => {
      if (type === "byApproach") {
        if (row[user.armA] !== null) {
          options.push(row[user.armA]);
        }
      } else if (type === "byMotion") {
        if (row[user.armA] !== null && row[user.armR] !== null) {
          options.push([row[user.armA], row[user.armR]]);
        }
      } else if (type === "byUsers") {
        if (
          row[user.armA] !== null &&
          row[user.armR] !== null &&
          row[user.roadUserClass] !== null
        ) {
          options.push([
            row[user.armA],
            row[user.armR],
            row[user.roadUserClass],
          ]);
        }
      }
    });

    let construct;

    if (!countDuplicates) {
      construct = _.uniq(options.map((d) => JSON.stringify(d)))
        .map((d) => JSON.parse(d))
        .map((d) => constructID(type, d));
    } else {
      construct = options
        .map((d) => JSON.stringify(d))
        .map((d) => JSON.parse(d))
        .map((d) => constructID(type, d));
    }

    return construct;
  };

  let counter = {};

  // prefill
  allOptions.forEach((option) => {
    const id = constructID(type, option);
    if (type === "byApproach") {
      counter[id] = {
        armA: option,
        armR: -1,
        roadUserClass: -1,
        value: 0,
      };
    } else if (type === "byMotion") {
      counter[id] = {
        armA: option[0],
        armR: option[1],
        roadUserClass: -1,
        value: 0,
      };
    } else if (type === "byUsers") {
      counter[id] = {
        armA: option[0],
        armR: option[1],
        roadUserClass: option[2],
        value: 0,
      };
    } 
  });

  // count
  if (slicedData.length) {
    slicedData.forEach((row) => {
      rowCounter(row, type, users, countDuplicates).forEach((id) => {
          counter[id].value++;
        }); 
    });
  }

  return counter;
};

const groupUsers = (data) => {
  let construct = [];

  const allKeys = Object.keys(data[0]);

  const users = _.uniq(
    allKeys.map((key) => {
      const number = key.replace(/\D/g, "");

      if (number) {
        return Number(number);
      } else {
        return null;
      }
    })
  ).filter((d) => d !== null);

  users.map((user) =>
    construct.push({
      armA: `arm${user}A`,
      armR: `arm${user}R`,
      roadUserClass: `class${user}`,
    })
  );

  return construct;
};

const allOptions = ({ type, data }) => {
  let options = [];

  if (type === "byApproach") {
    groupUsers(data).forEach((user) => {
      data.forEach((d) => {
        options.push(d[user.armA]);
      });
    });
  } else if (type === "byMotion") {
    groupUsers(data).forEach((user) => {
      data.forEach((d) => {
        if (d[user.armA] !== null && d[user.armR] !== null) {
          options.push([d[user.armA], d[user.armR]]);
        }
      });
    });
  } else if (type === "byUsers") {
    groupUsers(data).forEach((user) => {
      data.forEach((d) => {
        if (
          d[user.armA] !== null &&
          d[user.armR] !== null &&
          d[user.roadUserClass] !== null
        ) {
          options.push([d[user.armA], d[user.armR], d[user.roadUserClass]]);
        }
      });
    });
  }

  return _.uniq(options.filter((d) => d !== null));
};

const flattenEvents = (data) => {
  let construct = [];

  const users = groupUsers(data);

  if (data.length) {
    data.forEach((row) => {
      users.forEach((user) => {
        construct.push({
          t_posix: row.t_posix,
          armA: row[user.armA],
          armR: row[user.armR],
          roadUserClass: row[user.roadUserClass],
        });
      });
    });
  }

  return construct;
};

export default QuarterStatsify;
export { flattenEvents, groupUsers, allOptions, countEvents };
