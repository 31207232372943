import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import GlobalTopBar from "../Studies/TopBar/GlobalTopBar";
import { useMutation } from "@apollo/react-hooks";
import Signup from "../../Data/Fetching/QueryLibrary/Signup";
import { useMachine } from "@xstate/react";
import { signup__machine } from "../../machines/signup";

const SignUp = ({ authUser }) => {
  const [state, send] = useMachine(signup__machine);
  const [createUser, { data }] = useMutation(Signup, {
    onError: (error) =>
      send({
        type: "REJECTED",
        payload: {
          error,
        },
      }),
  });

  useEffect(() => {
    if (state.matches("submitting")) {
      const { email, name, admin } = state.context;
      createUser({
        variables: {
          email,
          client_name: name,
          admin,
          created_by: authUser.client_id,
        },
      });
    }
    return () => {
      //
    };
  }, [state]);

  useEffect(() => {
    if (data) {
      send({
        type: "SUCCESS",
      });
    }

    return () => {
      //
    };
  }, [data]);

  return (
    <React.Fragment>
      <GlobalTopBar authUser={authUser} />

      <Wrapper>
        <Form>
          {state.matches("success") ? (
            <h2>Thanks!</h2>
          ) : (
            <React.Fragment>
              <Header>Sign up</Header>
              {state.context.error && <Error>{state.context.error}</Error>}

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                autoComplete="email"
                autoFocus
                value={state.context.email}
                onChange={(e) =>
                  send({
                    type: "EDIT_EMAIL",
                    payload: {
                      target: e.target.value,
                    },
                  })
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="client_name"
                label="Client Name"
                autoFocus
                value={state.context.name}
                onChange={(e) =>
                  send({
                    type: "EDIT_NAME",
                    payload: {
                      target: e.target.value,
                    },
                  })
                }
              />
              <Flex>
                <Label>Admin</Label>
                <Checkbox
                  checked={state.context.admin}
                  onChange={() =>
                    send({
                      type: "EDIT_ADMIN",
                      payload: {
                        target: !state.context.admin,
                      },
                    })
                  }
                />
              </Flex>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() =>
                  send({
                    type: "SUBMIT",
                  })
                }
              >
                {state.matches("submitting") ? "Submitting..." : "Sign Up"}
              </Button>
            </React.Fragment>
          )}
        </Form>
      </Wrapper>
    </React.Fragment>
  );
};

const Form = styled.div`
  width: 600px;
  padding: 60px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px -4px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
`;

const Header = styled.h1`
  font-size: 36px;
  font-family: "Roboto" sans-serif;
  line-height: 40px;
  font-weight: 500;
`;

const Flex = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 8px;
`;

const Label = styled.h3`
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto" sans-serif;
  margin: 0;
`;

const Error = styled.h3`
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto" sans-serif;
  margin: 8px 0px;
  color: red;
`;

export default SignUp;
