import styled from 'styled-components';

const ScreenshotBtn = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  background-color: ${props => props.isDisabled ? "rbga(0,0,0,.3)" : props.theme.secondaryDark};

  &:hover {
    opacity: .8;
  } 
  
`

export default ScreenshotBtn;
