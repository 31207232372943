export const Comp132 = {
    compID: 132,
    compType: 5,
    styling: {
      align: "flex-start",
    },
    piechart: {
      display: true,
      title: "Road Users Usage",
    },
    title: "Vulnerable Road Users Heatmap",
    lookupPath: {
      dropdown: {
        type: "roadUsers",
        formatting: "full",
      },
      piechart: {
        type: "approaches",
        formatting: "namesFull",
      },
    },
  }