import Nullify from '../utils/Nullify'
import roundTimestamp from '../utils/roundTimestamp';
import moment from 'moment'

function CleanEvents(data) {
  
  if (data) {
    if (data.length) {
      return data.map(d => ({
        study_id:d.study_id,
        row_id: d.row_id,
        arm1A: d.arm1A,
        arm1R: Nullify(d.arm1R),
        arm2A: Nullify(d.arm2A),
        arm2R: Nullify(d.arm2R),
        class1: Nullify(d.class1),
        class2: Nullify(d.class2),
        dMargin: Nullify(d.dMargin),
        deltaVMag: Nullify(d.deltaVMag),
        dwellTime: Nullify(d.dwellTime),
        eventClass: d.eventClass,
        eventId: Nullify(d.eventId),
        eventSeverity: Nullify(d.eventSeverity),
        tMargin: Nullify(d.tMargin),
        t_posix: Nullify(roundTimestamp(d.t_posix)),
        t_posixRaw: Nullify(d.t_posix),
        t_posixFormatted:moment.unix(d.t_posix).utc().format("h:mm:ss"),
        id1: d.id1,
        id2: d.id2,
        v1: Nullify(d.v1),
        v2: Nullify(d.v2),
        videoFileNamesSmall: d.videoFileNamesSmall,
        x_pix: d.x_pix,
        y_pix: d.y_pix
      }))
    }
  } 

  return "empty"  
   
}
export default CleanEvents;
