import gql from "graphql-tag";
import {
  CleanData,
  Aggregated
} from "../../Handlers/DataHandler";


const QUERY = gql`
  query quarterStats($study_id: ID!, $lastkey:lastkey) {
    quarterStats(study_id: $study_id, lastkey: $lastkey, dataClass: [1], roadUserClass: [0]) {
      data {
        row_id
        posixTime
        value
        armA
        armR
        roadUserClass
      }
      lastkey {
        study_id
        row_id
      }
    }
  }
`;

const dataHandler = data => {
  const output = Aggregated(CleanData(data));
  return output;
};

const q111 = {
  QUERY,
  dataHandler
};

export default q111;
