import { formatDate } from "./DateHelper";
import _ from "lodash";

const formatColumn = ({ column, thisStudy, row }) => {

  // -----------------------------------------
  // formats a string to be shown in the table
  // -----------------------------------------

  if (column.data) {
    let formatted = column.data.map(d => {
      // motion?
      if (d.dataKeys.length > 1) {

        const [key1, key2] = d.dataKeys;


        const newKey = (row[key1] !== null && row[key2] !== null) ? `${row[key1]}_${row[key2]}` : ""



        if (newKey.length > 1 && thisStudy.lookup[d.type][newKey]) {
          return thisStudy.lookup[d.type][newKey][d.formatting];
        } else {
          return "n/a";
        }
      }
      else {
        if (d.type === "time") {
          return formatDate(row[d.dataKeys], d.formatting);
        }
        else if (d.type === "number") {
          const currentValue = row[d.dataKeys];
          if (currentValue !== null) {
            return currentValue.toFixed(d.formatting);
          }
          else {
            return `n/a`;
          }
        }
        else if (d.type === "text") {
          const currentValue = row[d.dataKeys];
          if (currentValue) {
            return currentValue;
          } else {
            return "n/a";
          }
        }
        else if (d.type === "link") {
          const currentValue = row[d.dataKeys];
          if (currentValue && currentValue !== "-1") {
            // append path
            return currentValue;
          } else {
            return null;
          }
        }
        else {
          const key = row[d.dataKeys].toString();
          if (key !== "-1" && thisStudy.lookup[d.type][key]) {
            return thisStudy.lookup[d.type][key][d.formatting];
          } else {
            return `n/a`;
          }
        }
      }
    });

    if (column.data.length) {
      return formatted.join(column.delimiter);
    }
    else {
      return formatted[0];
    }
  }
  else {
    return null
  }


};

const validateColumns = ({ columns, data }) => {
  // -----------------------------------------------------------
  // removes any columns defined that doesn't exist in the data
  // -----------------------------------------------------------

  if (data.length) {
    let filtered = [];
    columns.map(column => {
      let ignore = false;
      column.data.map(d => {
        d.dataKeys.map(key => {
          if (!Object.keys(data[0]).includes(key)) {
            ignore = true;
          }
        });
      });
      if (!ignore) {
        filtered.push(column);
      }
    });

    return filtered;
  } else {
    return null;
  }
};

const createSortedTableRows = ({ orderBy, data, thisStudy, order, page, rowsPerPage }) => {

  const makeNumber = (el) => {
    if (!el) {
      return "n/a"
    }
    else if (!isNaN(Number(el))) {
      return Number(el)
    }
    return el
  }

  if (orderBy.data[0].dataKeys[0] === "t_posix") {
    data.map(row => {
      console.log(makeNumber(row.t_posix))
    })
  }



  if (orderBy) {
    const sorted = _.orderBy(
      data
        .map(row => {
          return {
            row,
            index: makeNumber(
              // sort timestamps?
              orderBy.data[0].dataKeys[0] === "t_posix" ? row.t_posix
                :
                // format column first
                formatColumn({ column: orderBy, thisStudy, row })
            )
          }
        }),
      "index",
      order
    ).map(d => d.row);

    const sliced = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    return sliced
  } else {
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  }
};

export { formatColumn, validateColumns, createSortedTableRows };
