import { createMachine, assign } from 'xstate';
import { modalPagination } from '../../../utils/modalpagination';


export const modalMachine = createMachine({
  id: 'modalMachine',
  context: {
    signedUrl: null,
    data: [],
    activeVideo: null,
    nextVideo: null,
    prevVideo: null,
  },
  initial: 'closed',
  states: {
    closed: {
      on: {
        OPEN: {
          actions: 'setIndices',
          target: 'loading',

        },
        SET_DATA: {
          actions: 'setData'
        }
      }
    },
    loading: {
      invoke: {
        src: 'makeSignedUrl',
        onDone: {
          actions: assign((_, e) => {
            return {
              signedUrl: e.data.data.signedURL.url
            }
          }),
          target: 'opened'
        },
        onError: {
          actions: () => console.log('ERROR'),
          target: 'closed'
        }
      },

    },
    opened: {

      on: {
        CLOSE: {
          target: 'closed'
        },
        PAGINATE: {
          actions: 'setIndices',
          target: 'loading',
          cond: 'isValid'
        },
        SET_DATA: {
          actions: 'setData'
        }
      }
    }

  }
}, {
  actions: {
    setData: assign((_, e) => {
      const { newData } = e.payload;
      return {
        data: newData
      }
    }),
    setIndices: assign((c, e) => {
      const { url } = e.payload;
      const { data } = c;

      const urls = modalPagination(data, url);

      return {
        nextVideo: urls.nextVideoUrl,
        prevVideo: urls.prevVideoUrl,
        activeVideo: url
      }
    }),
  },
  guards: {
    isValid: (_, e) => {
      return e.payload.url !== null;
    }
  }
})

