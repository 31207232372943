import React, { useState } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";

const View2 = ({
  authUser,
  setStep,
  setVerificationCode,
  email,
  errorMsg,
  setErrorMsg,
  theme,
}) => {
  const [code, setCode] = useState("");
  const [verified, setVerified] = useState(true);

  const resendCode = async () => {
    try {
      await Auth.forgotPassword(email);
    } catch (err) {
      console.log(err);
    }
  };

  const verifyCode = (value) => {
    const pattern = /^\d{6}$/;
    if (value.match(pattern)) {
      setVerified(true);
    } else {
      setVerified(false);
    }
    setCode(value);
  };

  const submitCode = () => {
    if (verified && code !== "") {
      setVerificationCode(code);
      setStep(3);
    } else {
      setErrorMsg(`Verification code must be 6 digits`);
    }
  };
  return (
    <>
      <SubText theme={theme} style={{ marginBottom: "25px" }}>
        Enter the 6 digit code from the email we just sent you
      </SubText>
      {errorMsg && <ErrorMsg theme={theme}>{errorMsg}</ErrorMsg>}
      <Input
        theme={theme}
        error={!verified}
        required
        label="Verification Code"
        autoFocus
        value={code}
        onChange={(e) => verifyCode(e.target.value)}
      />
      <Resend theme={theme} onClick={resendCode}>
        I didn't recieve a code, please resend
      </Resend>
      <Button theme={theme} onClick={submitCode}>
        Submit
      </Button>
    </>
  );
};

const Resend = styled.div`
  text-align: right;
  font-size: 16px;
  margin-bottom: 25px;
  color: #4051b5;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
`;

const Heading = styled.div`
  padding-top: 25px;
  font-size: 36px;
  text-align: center;
`;

const SubText = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 1.2em;
  font-weight: 500;
  color: ${(props) => props.theme.textDark};
`;

const Button = styled.button`
  width: 100%;
  height: 5vh;
  font-size: 0.9em;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  padding: 6px 16px;
  min-width: 64px;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.textLight};
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: ${(props) => props.theme.primaryDark};
  }
`;

const Input = styled.input`
  border: solid 1px ${(props) => props.theme.secondaryLight};
  margin-bottom: 20px;
  width: 100%;
  height: 6vh;
  border-radius: 5px;
  padding: 10px;
  background-color: ${(props) => props.theme.greyScale};
  color: ${(props) => props.theme.textDark};
  font-size: 1em;
  font-weight: 400;

  &:hover {
    border: solid 1px #000;
  }

  &:focus {
    border: solid 2px ${(props) => props.theme.secondaryDark};
  }
`;

const TopSection = styled.div`
  background: ${(props) => props.theme.gradient};
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopText = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 20px;

  color: ${(props) => props.theme.textLight};
  font-size: 5vw;
`;

const ErrorMsg = styled.div`
  color: red;
  font-size: 14px;
`;

export default View2;
