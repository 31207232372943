import gql from "graphql-tag";
import { CleanData, ByGrouping} from "../../Handlers/DataHandler";

const QUERY = gql`
  query quarterStats($study_id: ID!, $lastkey:lastkey) {
    quarterStats(study_id: $study_id, lastkey: $lastkey, dataClass: [3], roadUserClass: [1,2]) {
      data {
        row_id
        posixTime
        value
        armA
        armR
        roadUserClass
      }
      lastkey {
        study_id
        row_id
      }
    }
  }
`;

const dataHandler = data => ByGrouping({data: CleanData(data), column: "roadUserClass",includeCombined:true});

const q133 = {
  QUERY,
  dataHandler
};

export default q133;
