import React, { useState } from "react";
import styled from "styled-components";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Naming from "../Utils/Naming";
import * as compose from "lodash.flowright";
import { graphql } from "react-apollo";
import GetDPI from "../../../../Data/Fetching/QueryLibrary/GetDPI";
import UpdateDPI from "../../../../Data/Fetching/QueryLibrary/UpdateDPI";

const Settings = ({
  comp,
  thisStudy,
  lookupPath,
  showSchematic,
  schematicSwitch,
  withDropdown,
  data,
  interactive,
  setInteractive,
  withInteractiveSwitch,
  setImageUrls,
  showBackground,
  setShowBackground,
  UpdateDPI,
  dpiSetting,
  theme,
}) => {
  const [value, setvalue] = useState(0);
  const [switchToggle, setswitchToggle] = useState(interactive);



  const handleChange = (e) => {
    setvalue(e.target.value);
    setImageUrls(data[e.target.value].urls);
  };

  const handleSwitch = () => {
    setswitchToggle(!switchToggle);
    setInteractive(!interactive);
  };

  return (
    <Wrapper>
            {withDropdown ? (
        <Dropdown>
          <Select value={value} onChange={handleChange} style={{ width: 200 }}>
            {data.map((image, i) => (
              <MenuItem key={i} value={i} theme={theme}>
                {image.dropdown.type === "special"
                  ? comp.lookupPath.dropdown.specialLookup[image.dropdown.key]
                  : Naming({
                    thisStudy,
                    lookupPath,
                    key: image.dropdown.key,
                    part: "dropdown",
                  })}
              </MenuItem>
            ))}
          </Select>
        </Dropdown>
      ) : null}

      <SwitchWrapper>
        <Switcher
          color={"secondary.light"}
          type="checkbox"
          checked={showSchematic}
          onChange={schematicSwitch}
        />
        <>Schematic</>
      </SwitchWrapper>

      <SwitchWrapper>
        <Switcher
          color={"secondary.light"}
          type="checkbox"
          checked={showBackground}
          onChange={() => setShowBackground(!showBackground)}
        />
        <>Background</>
      </SwitchWrapper>

      {withInteractiveSwitch ? (
        <SwitchWrapper>
          <Interactive>
            <Switcher
              color={"secondary.light"}
              type="checkbox"
              checked={switchToggle}
              onChange={handleSwitch}
            />
            <>Interactive</>
            {!interactive ? (
              // <Tooltip title="Can't see the traces? Click here">
              <ButtonApply
                theme={theme}
                onClick={() =>
                  UpdateDPI({
                    variables: {
                      value: dpiSetting.value === 1 ? 2 : 1,
                    },
                  })
                }
              >
                Can't see the traces? Click here
              </ButtonApply>
            ) : // </Tooltip>
              null}
          </Interactive>
        </SwitchWrapper>
      ) : null}
    </Wrapper>
  );
};

export default compose(
  graphql(UpdateDPI, { name: "UpdateDPI" }),
  graphql(GetDPI, {
    props: ({ data: { dpiSetting } }) => ({
      dpiSetting,
    }),
  })
)(Settings);

const SwitchWrapper = styled.div`
  font-size: 12px;
  padding: 10px;
`;

const Interactive = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
  &:hover {
    opacity: 0.8;
  }
`;

const ButtonApply = styled(Button)`
  background-color: ${(props) => props.theme.primary};
  font-size: 9px;
`;

const Switcher = styled(Checkbox)`
  margin-right: 4px;
`;

const Dropdown = styled.div``;

const Wrapper = styled.div`
  flex: 1 100%;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  border-bottom: 1px solid #eee;
`;



