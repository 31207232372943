import {
  peakHour,
  SplitByDay,
  HasValues,
  DailyAvg,
  TotalCount,
  peakQuarter,
  PHF,
  generateMotions,
  AmPm,
} from "./utils";
import { formatDateRange } from "../utils/DateHelper";

const Stats = ({ data, databox, thisStudy, approach }) => {
  // ------------------------------------------------------------------------------------
  // takes an array of stats to be generated from the data. returns an array of objects.
  // ------------------------------------------------------------------------------------

  if (data.length) {
    let construct = [];

    // setup needed variables
    let totalPeakHour = {
      start: null,
      stop: null,
      count: null,
    };
    let totalPeakQuarter = {
      start: null,
      count: null,
    };
    let amPeakHour = {
      start: null,
      stop: null,
      count: null,
    };
    let pmPeakHour = {
      start: null,
      stop: null,
      count: null,
    };
    let amPeakQuarter = {
      start: null,
      stop: null,
      count: null,
    };
    let pmPeakQuarter = {
      start: null,
      stop: null,
      count: null,
    };

    // split data by day
    const ByDay = SplitByDay(data);

    // iterate through each day
    Object.keys(ByDay).forEach((day) => {
      // day has data?
      if (HasValues(ByDay[day])) {
        // split by am/pm
        const AmPmSplit = AmPm(ByDay[day]);

        // get peaks for current day
        const currentPeakHour = peakHour(ByDay[day]);
        const currentPeakQuarter = peakQuarter(ByDay[day]);
        const currentAmPeakHour = peakHour(AmPmSplit.AM);
        const currentPmPeakHour = peakHour(AmPmSplit.PM);
        const currentAmPeakQuarter = peakQuarter(AmPmSplit.AM);
        const currentPmPeakQuarter = peakQuarter(AmPmSplit.PM);

        // update variables
        if (currentPeakHour.count > totalPeakHour.count) {
          totalPeakHour = currentPeakHour;
        }
        if (currentAmPeakHour.count > amPeakHour.count) {
          amPeakHour = currentAmPeakHour;
        }
        if (currentPmPeakHour.count > pmPeakHour.count) {
          pmPeakHour = currentPmPeakHour;
        }
        if (currentPeakQuarter.count > totalPeakQuarter.count) {
          totalPeakQuarter = currentPeakQuarter;
        }
        if (currentAmPeakQuarter.count > amPeakQuarter.count) {
          amPeakQuarter = currentAmPeakQuarter;
        }
        if (currentPmPeakQuarter.count > pmPeakQuarter.count) {
          pmPeakQuarter = currentPmPeakQuarter;
        }
      }
    });

    // iterate through rows
    databox.forEach((row) => {
      let tempRow = {
        title: row.title,
        stats: [],
      };
      row.stats.forEach((stat) => {
        if (stat.type === "peakHour") {
          if (stat.option === "am") {
            if (amPeakHour.start) {
              tempRow.stats.push({
                title: stat.title,
                data: formatDateRange(
                  amPeakHour.start,
                  amPeakHour.stop,
                  "full"
                ),
              });
            }
          } else if (stat.option === "pm") {
            if (pmPeakHour.start) {
              tempRow.stats.push({
                title: stat.title,
                data: formatDateRange(
                  pmPeakHour.start,
                  pmPeakHour.stop,
                  "full"
                ),
              });
            }
          }
        } else if (stat.type === "peakQuarter") {
          if (stat.option === "am") {
            if (amPeakQuarter.start) {
              tempRow.stats.push({
                title: stat.title,
                data: formatDateRange(
                  amPeakQuarter.start,
                  amPeakQuarter.stop,
                  "full"
                ),
              });
            }
          } else if (stat.option === "pm") {
            if (pmPeakQuarter.start) {
              tempRow.stats.push({
                title: stat.title,
                data: formatDateRange(
                  pmPeakQuarter.start,
                  pmPeakQuarter.stop,
                  "full"
                ),
              });
            }
          }
        } else if (stat.type === "dailyAvg") {
          const dailyAvg = DailyAvg(data)
          if (dailyAvg) {
            tempRow.stats.push({
              title: stat.title,
              data: dailyAvg,
            });
          }

        } else if (stat.type === "phf") {
          if (stat.option === "am") {
            const pHF = PHF(amPeakHour.count, amPeakQuarter.count);
            if (pHF) {
              tempRow.stats.push({
                title: stat.title,
                data: pHF,
              });
            }
          } else if (stat.option === "pm") {
            const pHF = PHF(pmPeakHour.count, pmPeakQuarter.count);
            if (pHF) {
              tempRow.stats.push({
                title: stat.title,
                data: pHF,
              });
            }
          }
        } else if (stat.type === "totalCount") {
          tempRow.stats.push({
            title: stat.title,
            data: TotalCount(data),
          });
        } else if (stat.type === "medianSpeed") {
          const motions = generateMotions(data);
          if (motions && thisStudy) {
            motions.forEach((motion) => {
              const speed = thisStudy.lookup.motions[motion].medianSpeedMph;
              tempRow.stats.push({
                title: thisStudy.lookup.motions[motion].namesFull,
                data:
                  speed >= 0 ? `${Number(speed).toFixed(2)} Mph` : "Not Avail",
              });
            });
          }
        } else if (stat.type === "speedLimit") {
          const all_streets = thisStudy.all_streets;

          if (all_streets) {
            all_streets.forEach((street) => {
              tempRow.stats.push({
                title: street.street,
                data: `${street.speed_limit} Mph`,
              });
            });
          }
        }
      });
      if (tempRow.stats.length) {
        construct.push(tempRow);
      }
    });
    return construct;
  } else {
    return null;
  }
};

export default Stats;
