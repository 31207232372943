export const Comp113  =  {
    compID: 113,
    compType: 1,
    title: "Counts Per Motion",
    styling: {
      dots: true,
    },
    settings: {
      hasDropdown: true,
    },
    databox: [],
    lookupPath: {
      dropdown: {
        type: "approaches",
        formatting: "namesFull",
      },
      legend: {
        type: "motions",
        formatting: "namesFull",
      },
      tooltip: {
        type: "motions",
        formatting: "namesFull",
      },
    },
  }