import gql from "graphql-tag";

export default gql`
  {
    iframeClient {
      client_id
      client_name
      datetime
      admin
      first_login
      theme {
        primary
        primaryLight
        primaryDark
        secondary
        secondaryDark
        secondaryLight
        error
        gradient
        textDark
        textLight
        whiteBoxBorder
        greyScale
        negative
        positive
      }
      intersections {
        intersection_id
        client_id
        client_name
        intersection
        formatted_address
        all_streets {
          speed_limit
          street
        }
        studies {
          study_id
          active_comps {
            comp
          }
          active_routes {
            route
          }
          lookup
          client_id
          intersection_id
          study_start
          study_end
          downloadables {
            name
            file
          }
          imageUrl
          hideMenu
        }
      }
    }
  }
`;
