function IntersectionFromStudy(authUser, study_id) {
    let currentInt;

    authUser.intersections.forEach(inter=>{
        inter.studies.filter(study=>{
            if (study.study_id === study_id) {
                currentInt = inter;
            }
            return;
        })
    })

    return currentInt
}

export default IntersectionFromStudy