import React, { useEffect, useState } from "react";
import{fromPairs,pipe,filter,toPairs,reduce} from 'ramda';
import {ThemeProvider,createTheme} from '@material-ui/core/styles';
import { Route } from "react-router";
import GetIframeClient from "./Data/Fetching/QueryLibrary/GetIframeClient";
import CleanClient from "./Data/Handlers/Client/CleanClient";
import { useLazyQuery } from "@apollo/react-hooks";
import Spinner from "./Views/Components/Spinner";

export default ({
  authStatus,
  theme,
  updateTheme,
  component: Component,
  ...rest
}) => {
  const [authUser, setAuthUser] = useState(null);

  const iframe_token = window.name;
  // const [reload, setReload] = useState(false);
  const [getClient, { data, loading, error }] = useLazyQuery(GetIframeClient, {
    fetchPolicy: "network-only",
  });

     const materialTheme =  createTheme({
        palette:{
            primary:{
                light: theme.primaryLight,
                main: theme.primary,
                dark: theme.primaryDark,
            },
            secondary:{
                light: theme.secondaryLight,
                main: theme.secondary,
                dark: theme.secondaryDark
            }
        }
    })


  // set initial iframe token
  useEffect(() => {
    const token = localStorage.getItem("iframe-token");
    if (token !== iframe_token) {
      localStorage.setItem("iframe-token", iframe_token);
    }
    getClient();
  }, [getClient, iframe_token]);

  // updateTheme
  useEffect(() => {
    if (data) {
      setAuthUser(CleanClient(data.iframeClient));

      if (data.iframeClient.theme) {
           const trimObj=pipe(
              toPairs,
              filter(([key,value])=>value!==null),
              fromPairs
           )

            const updatedTheme= pipe(
              reduce((acc,curr)=>({
                ...trimObj(acc),
                ...trimObj(curr)
                      }),{})
            )([theme,data.iframeClient.theme])

       updateTheme({
          type: "UPDATE_THEME",
          payload: {
            theme: updatedTheme 
          },
        });
      }
    } else if (error) {
      console.log(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error, updateTheme ]);

  if (error) {
    return <>Sorry, we couldn't authenticate you. Please contact admin.</>;
  }

  if (loading || !authUser) {
    return <Spinner theme={theme} />;
  }

  if (authUser) {
    return (
      <Route
        {...rest}
        render={(props) => (
        <ThemeProvider theme={materialTheme}>
              <Component
                {...props}
                authUser={authUser}
                theme={theme}
                updateTheme={updateTheme}
                iframeView={true}
              />
          </ThemeProvider>
        )}
      />
    );
  }
};
