import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";

export default ({ icon, title, route, open, setOpen, theme }) => {
  const [hover, setHover] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [isHome] = useState(route === "/");

  let wrapperClasses = [];
  if (pressed) wrapperClasses.push("pressed");
  if (isHome) wrapperClasses.push("hide");
  if (hover) wrapperClasses.push("hover");

  return (
    <Wrapper
      theme={theme}
      exact={isHome || route ? true : false}
      to={route}
      onMouseEnter={() => {
        setHover(true);
        setOpen(true);
      }}
      onMouseLeave={() => setHover(false)}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => {
        setPressed(false);
        setOpen(false);
      }}
      className={wrapperClasses.join(" ")}
    >
      <Icon className={open ? "navOpen" : "navClosed"}>{icon}</Icon>
      <Title className={hover && "hover"}>{title}</Title>
      {hover && (
        <ShineWrap>
          <ShineFx />
        </ShineWrap>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(NavLink)`
  flex-shrink: 0;
  display: flex;

  transition: transform 80ms ease-in-out, opacity 80ms ease-in-out;

  &.hover,
  &.active {
    background: ${(props) => props.theme.gradient};
    color: ${(props) => props.theme.textLight};
  }
  transform: translateY(0);
  &.active {
    &.hide {
      transform: translateY(100%);
      background: none;
    }
    .navClosed {
      opacity: 1;
    }
  }
  &.pressed {
    opacity: 0.8;
    transition: transform 80ms ease-in-out, opacity 10ms ease-in-out;
  }
`;

const Icon = styled.div`
  flex-shrink: 0;
  width: 70px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  transition: opacity 250ms ease-in-out;
  opacity: 0.5;
  &.navOpen {
    opacity: 1;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 0;
  flex-shrink: 1;
  white-space: nowrap;
  font-size: 0.9em;
  font-weight: 500;
  transition: transform 80ms ease-in-out;

  &.hover {
    transform: translateX(3px);
  }
`;

const ShineAni = keyframes`
  from {
    transform: translateX(-100%) rotate(20deg);
    opacity: 1;
  }
  to {
    transform: translateX(0%) rotate(20deg);
    opacity: 0;
  }
`;

const ShineWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const ShineFx = styled.div`
  position: absolute;
  top: -200%;
  left: 0;
  height: 400%;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(250, 250, 250, 0),
    rgba(250, 250, 250, 0.3)
  );
  animation: ${ShineAni} 500ms cubic-bezier(0.38, 0.73, 0.46, 0.93) 0ms both;
`;
