import _ from "lodash";
import CleanLines from "../../Handlers/Events/CleanLines";
import CleanEvents from "../../Handlers/Events/CleanEvents";

const AddLines = (events, lines) => {

  if (events.length) {
    const cleanEvents = CleanEvents(events);
    const cleanLines = CleanLines(lines);

    const linesData = _.groupBy(cleanLines, "u_id");

    let construct = [];

    cleanEvents.forEach((row) => {
      let rowData = {
        ...row,
        lines: {
          line1: [],
          line2: [],
        },
      };

      if (row.id1) {
        if (linesData[row.id1]) {
          let line = linesData[row.id1].filter(
            (d) => d.eventId === row.eventId
          );
          rowData.lines.line1 = _.sortBy(line, "t");
        }
      }
      if (row.id2) {
        if (linesData[row.id2]) {
          let line = linesData[row.id2].filter(
            (d) => d.eventId === row.eventId
          );
          rowData.lines.line2 = _.sortBy(line, "t");
        }
      }

      construct.push(rowData);
    });

    return construct;
  }

  return null;
};

export default AddLines;
