export const Comp123 =    {
    compID: 123,
    compType: 4,
    title: "Red-To-Green Speed Transitions For Through Motions",
    styling: {
      divSize: 75,
      justifyContent: "center",
    },
    lookupPath: {
      combined: false,
      dropdown: {
        type: "approaches",
        formatting: "namesFull",
      },
    },
  }