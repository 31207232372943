import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Schematic from "./Schematic";
import SvgTraces from "./SvgTraces";
import CanvasTraces from "./CanvasTraces";
import SignURL from "../../../../Data/Fetching/QueryLibrary/SignURL";
import { useLazyQuery } from "@apollo/react-hooks";
import _ from "lodash";

export default ({
  eventsDataFiltered,
  schematicData,
  withTraces,
  showSchematic,
  showBackground,
  hoveringRow,
  setHoveringRow,
  selectedRows,
  setSelectedRows,
  interactive,
  tableRows,
  withOverlayImage,
  imageUrls,
  comp,
  study_id,
  theme,
}) => {
  const [signURL, { loading, data }] = useLazyQuery(SignURL, {
    fetchPolicy: "no-cache",
    variables: {
      url: null,
    },
  });
  const [processedURL, setProcessedURL] = useState(null);
  const [width, setWidth] = useState(25);
  const [height, setHeight] = useState(25);
  const ref = useRef();


  // set initial size
  useEffect(() => {
    if (ref) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetWidth);
    }
  }, []);

  // resize SVG
  useEffect(() => {
    const handleResize = () => {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetWidth);
    };

    if (ref) {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  });

  // trigger url signing
  useEffect(() => {
    if (schematicData && schematicData.imageUrl) {
      signURL({
        variables: {
          url: `client_studies/${study_id}/matlab_results/visPlatform/satellite/original.png`,
        },
      });
    }
  }, [schematicData, signURL, study_id]);

  useEffect(() => {
    if (data && data.signedURL.url) {
      setProcessedURL(data.signedURL.url);
    }
  }, [loading, data]);

  const urlsWithIndex = _.sortBy(imageUrls, "imageID")
    .reverse()
    .map((d, i) => ({
      url: d.url,
      zIndex: i + 2,
    }));

  return (
    <Wrapper>
      <Fixer>
        <Inner id={`gps-map-${comp.compID}`} ref={ref}>
          {withOverlayImage
            ? urlsWithIndex.map((overlay, i) => (
              <OverlayImage
                src={overlay.url}
                zIndex={overlay.zIndex}
                key={i}
              />
            ))
            : null}

          {showBackground ? <Background src={processedURL} /> : null}

          {!interactive && withTraces ? (
            <CanvasTraces
              theme={theme}
              width={width}
              height={height}
              data={eventsDataFiltered}
            />
          ) : null}

          <Svg viewbox={`0 0 ${width} ${height}`}>
            {showSchematic ? (
              <Schematic
                data={schematicData.data}
                width={width}
                height={height}
              />
            ) : null}
            {interactive && withTraces ? (
              <SvgTraces
                data={tableRows}
                width={width}
                height={height}
                hoveringRow={hoveringRow}
                setHoveringRow={setHoveringRow}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                comp={comp}
                theme={theme}
              />
            ) : null}
          </Svg>
        </Inner>
      </Fixer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 0 40%;
  margin: 10px;
  min-height: 300px;
  min-width: 300px;
  max-height: 600px;
  max-width: 600px;
  background-color: grey;
`;

const Fixer = styled.div`
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Background = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  filter: grayscale(1) brightness(0.6);
`;

const OverlayImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${(props) => props.zIndex};
`;

const Svg = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
