import React from "react";
import styled from "styled-components";

const Image = ({ src, zIndex }) => {


  return (
    <div>
      <StyledImage
        src={src}
        zIndex={zIndex}
      />
    </div>
  );
};

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  z-index: ${(props) => props.zIndex};
  display: ${(props) => (props.imageLoading ? "none" : "")};
`;

export default Image;
