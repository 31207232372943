import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import styled from "styled-components";
import DropDownSwitch from "../Utils/DropDownSwitch";
import MergeFilters from "../Utils/MergeFilters";
import _ from "lodash";

const Dropdown = ({ setFilters, filter, currentFilters }) => {
  const [value, setValue] = useState({ label: "all", value: -1 });
  const filters = _.cloneDeep(currentFilters);
  const options = filters[filter.dataKeys.join("")].options;

  useEffect(() => {
    // reset filters
    if (!currentFilters.applied.filters) {
      setValue({ label: "all", value: -1 });
    }
  }, [currentFilters]);

  const handleChange = (e) => {
    let approach = null;

    if (
      filter.dataKeys.length === 1 &&
      filter.dataKeys[0].slice(0, 3) === "arm"
    ) {
      approach = filter.dataKeys;
    }

    const stateChange = DropDownSwitch({
      state: value,
      appliedFilters: filters.applied,
      selection: e,
      options,
      approach,
    });

    setValue(stateChange.dropdown);

    const mergedFilters = MergeFilters({
      newApplied: stateChange.appliedFilters,
      oldApplied: currentFilters.applied,
    });

    const newFilters = {
      ...currentFilters,
      applied: mergedFilters,
    };

    const newCopied = _.cloneDeep(newFilters);

    setFilters(newCopied);
  };

  return (
    <Wrapper>
      {filter.title}:
      <ReactMultiSelectCheckboxes
        style={{ zIndex: 99 }}
        options={Object.keys(options).map((option) => {
          return {
            label: options[option].label,
            value: options[option].index,
          };
        })}
        onChange={handleChange}
        value={value}
      />
    </Wrapper>
  );
};

export default Dropdown;

const Wrapper = styled.div`
  margin-right: 2em;
`;
