import _ from 'lodash';

const MapFunctionality = ({hoveringRow, selectedRows,row_id, settings}) => {

    // -----------------------------------------------------------------------------------
    // takes hovered row, selected row as input and returns object with how to turn on off
    // -----------------------------------------------------------------------------------

    let newSettings = _.cloneDeep(settings)

    // if hoveringRow
    if (hoveringRow) {
        // this row being hovered, show both circle and line
        if (hoveringRow.id===row_id) {
            newSettings.circle=true;
            newSettings.lines=true
        // hovering on the map, show only the current circle
        } else if (hoveringRow.location==="map") {
            newSettings.circle=true;
            newSettings.lines=false;
        // row not hovered and not on map, don't show line
        } else {
            newSettings.circle = false;
            newSettings.lines=false;
        }
    }

    // at least one row selected
    if (selectedRows.length) {
        // the current row is selected, display
        if (selectedRows.includes(row_id)) {
            newSettings.circle = true;
            newSettings.lines = true;
        // current row not selected
        } else {
            if (hoveringRow) {
                if (hoveringRow.id===row_id) {
                    newSettings.circle=true
                    newSettings.lines=true
                } 
            } else {
                newSettings.circle=false
                newSettings.lines=false
            }
        }
    }

    return newSettings;
}

export default MapFunctionality