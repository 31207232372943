import _ from "lodash";
import * as d3 from "d3";
import { isEmpty, isNil, curry, map, pipe, join } from "ramda";

function ByColumn({ data, filtering, thisStudy }) {
  // takes an array of events data, and an array of filters
  // rearranges the data to generate data for the filtering component


  const filters = flattenFilters(filtering);

  let construct = {
    applied: {
      settings: {
        approachSelected: null,
      },
      filters: null,
    },
  };

  // iterate through filters
  filters.forEach((filter) => {
    // validate accurate filter
    let requiredKeys = ["dataKeys", "dataType", "title"];
    if (filter.dataType === "discreet" || filter.dataType === "range") {
      requiredKeys.push("lookup");
      if (filter.dataType === "range") {
        requiredKeys.push("width");
      }
    }

    if (_.isEqual(requiredKeys.sort(), Object.keys(filter).sort())) {
      // generate filter options
      // const filterOptions = createOptions(data, filter);
      const filterOptions = generateOptions({ data, filter, thisStudy });

      if (!isEmpty(filterOptions) && !isNil(filterOptions)) {
        const name = filter.dataKeys.join("");
        construct[name] = {
          options: filterOptions,
          type: filter.dataType,
          title: filter.title,
        };

        // add lookup if discreet or range
        if (filter.dataType === "discreet" || filter.dataType === "range") {
          construct[name].lookup = {
            type: filter.lookup.type,
            formatting: filter.lookup.formatting,
          };
          if (filter.dataType === "range") {
            construct[name].width = filter.width;
          }
        }
      }
    }
  });

  return construct;
}

function createOptions(data, filter) {
  // returns the range for a specific filter, depending on dataType
  // returns null if no data exist, i.e bad filter input

  const acceptableDataTypes = ["discreet", "boolean", "range"];
  const acceptableDataKeys = Object.keys(data[0]);

  if (!acceptableDataTypes.includes(filter.dataType)) {
    console.log(
      `invalid dataType, acceptable type are: ${acceptableDataTypes}`
    );
    return false;
  }

  // dataKeys OK ?
  if (
    !_.every(
      filter.dataKeys.map((key) => {
        return acceptableDataKeys.includes(key);
      })
    )
  ) {
    return null;
  }

  if (filter.dataType === "boolean") {
    return {
      bool: [true, false],
    };
  } else if (filter.dataType === "range") {
    return {
      [filter.dataKeys]: [
        d3.min(data.map((d) => d[filter.dataKeys])),
        d3.max(data.map((d) => d[filter.dataKeys])),
      ],
    };
    // discreet data ?
  } else {
    return handleDiscreet({ data, filter });
  }
}

function flattenFilters(filters) {
  // ignores row structure and flattens data

  const construct = [];

  filters.forEach((row) => {
    row.filters.forEach((filter) => {
      construct.push(filter);
    });
  });

  return construct;
}

const handleDiscreet = ({ data, filter }) => {
  let construct = {};

  filter.dataKeys.forEach((dataKey) => {
    construct[dataKey] = _.uniq(
      data.map((d) => d[dataKey]).filter((d) => d !== null)
    );
  });

  return construct;
};


const getDiscreetFilters = (data, filter, thisStudy) => {
  let construct = {};

  const lookupMap = thisStudy.lookup[filter.lookup.type]

  data.forEach((row) => {
    // get the name
    const generatedKey = constructKey({ row, keys: filter.dataKeys });

    let name = lookupMap[generatedKey];


    if (name) {
      name = name[filter.lookup.formatting];
      if (construct[name]) {
        // append to filterValues
        filter.dataKeys.forEach((key) => {
          if (!construct[name].filterValues[key].includes(row[key])) {
            construct[name].filterValues[key].push(row[key]);
          }
        });
      } else {
        let values = {};
        filter.dataKeys.forEach((key) => {
          if (values[key]) {
            values[key].push(row[key]);
          } else {
            values[key] = [row[key]];
          }
        });
        construct[name] = {
          filterValues: values,
          label: name,
          type: "discreet",
          lookup: filter.lookup,
        };
      }
    }
  });

  let idx = 0;
  // index ordering
  Object.keys(construct).forEach((d) => {
    construct[d].index = idx;
    idx = idx + 1;
  });

  construct["all"] = { label: "all", index: -1 };


  return construct;
}

const getRangeFilters = (data, filter) => {

  const sortedData = data.map(r => r[filter.dataKeys]).filter(r => r !== null).sort((a, b) => a - b);

  return sortedData.length >= 2 ? {
    min: sortedData[0],
    max: sortedData[sortedData.length - 1],
    type: 'range'
  } : {}

}


const generateOptions = ({ data, filter, thisStudy }) => {
  const { dataType } = filter;

  switch (dataType) {
    case 'discreet': {
      return getDiscreetFilters(data, filter, thisStudy);
    }
    case 'range': {
      return getRangeFilters(data, filter);
    }
    case 'boolean': {
      return {
        bool: [true, false],
        type: 'boolean'
      }
    }
    default: {
      return {};
    }
  }

};

const constructKey = curry(({ keys, row }) =>
  pipe(
    map((key) => row[key]),
    join("_")
  )(keys)
);

export default ByColumn;

export {
  createOptions,
  flattenFilters,
  handleDiscreet,
  generateOptions,
  constructKey,
};
