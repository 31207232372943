import { useState, useEffect } from 'react';
import {
  validateColumns,
  createSortedTableRows,
} from "../../../../Data/Handlers/utils/TableHelper";


const useTableLogic = ({
  columns,
  data,
  setSelectedRows,
  selectedRows,
  setTableRows,
  thisStudy,
}) => {
  // table state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState({
    title: "Event Id",
    data: [{ dataKeys: ["eventId"], type: "number", formatting: 0 }],
  });


  const adjustedColumns = validateColumns({ columns, data });

  const selectAll = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((d) => d.row_id);
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const select = (_, i) => {
    const selectedIndex = selectedRows.indexOf(i);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, i);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 1) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelected);
  };



  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const changePage = (_, newPage) => {
    setPage(newPage);
  };

  const changeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (id) => {
    return selectedRows.indexOf(id) !== -1;
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };



  // effects

  useEffect(() => {
    setTableRows(
      createSortedTableRows({
        orderBy,
        data,
        thisStudy,
        order,
        page,
        rowsPerPage,
      })
    );
  }, [page, rowsPerPage, data, order, orderBy, setTableRows, thisStudy]);

  useEffect(() => {
    setPage(0);
  }, [data]);


  return {
    page,
    adjustedColumns,
    selectAll,
    orderBy,
    order,
    createSortHandler,
    isSelected,
    select,
    changePage,
    changeRowsPerPage,
    rowsPerPage,
  }
};

export default useTableLogic;
