import gql from "graphql-tag";
import { CleanData, ByGrouping } from "../../Handlers/DataHandler";

const QUERY = gql`
  query quarterStats($study_id: ID!, $lastkey: lastkey) {
    quarterStats(study_id: $study_id, lastkey: $lastkey, dataClass: 2, roadUserClass: -1) {
      data {
        row_id
        posixTime
        value
        armA
        armR
        roadUserClass
      }
      lastkey {
        study_id
        row_id
      }
    }
  }
`;

const dataHandler = data => ByGrouping({data:CleanData(data),column:'armA'});

const q121 = {
  QUERY,
  dataHandler
};

export default q121;
