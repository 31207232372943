import _ from "lodash";
import { trimData } from "../utils/trimData";
import sumRows from "./sumRows";

const Aggregated = (data) => {
  // -------------------------------------------------
  // takes array of data and aggregates all approaches
  // -------------------------------------------------

  if (data) {
    if (data.length) {
      let construct = [];

      // group by time
      const byTime = _.groupBy(data, (d) => d.posixTime);

      // iterate each timestamp
      Object.keys(byTime).forEach((timestamp) => {
        construct.push({
          date: timestamp,
          aggregated: sumRows(byTime[timestamp], "value"),
        });
      });

      return {
        aggregated: trimData(construct, true),
      };
    }
  }
  return "empty";
};

export default Aggregated;
