import gql from "graphql-tag";

const QUERY = gql`
  query events($study_id: ID!, $lastkey: lastkey) {
    events(study_id: $study_id, lastkey: $lastkey, eventClass: [4], class1:[1,2],conditionals:[
      {
        key:"eventId",
        condition:"<=",
        value:1000
      }
    ]) {
      data {
        study_id
        row_id
        arm1A
        arm1R
        arm2A
        arm2R
        class1
        class2
        id1
        id2
        dMargin
        deltaVMag
        eventId
        dwellTime
        eventClass
        eventSeverity
        tMargin
        t_posix
        v1
        v2
        x_pix
        y_pix
        videoFileNamesSmall
      }
      lastkey {
        study_id
        row_id
      }
    }
  }
`;

const dataHandler = data => data;

const q222events = {
  QUERY,
  dataHandler
};

export default q222events;
