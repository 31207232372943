import moment from "moment";
import roundTimestamp from './roundTimestamp';

const QuarterRange = (start, end, trim = false) => {
  let quarterArray = [];

  const startTime = moment.utc(
    `${moment
      .utc(new Date(start * 1000).toUTCString())
      .format("YYYY-MM-DD")} 00:00`
  );
  const endTime = moment.utc(
    `${moment
      .utc(new Date(end * 1000).toUTCString())
      .format("YYYY-MM-DD")} 23:45`
  );

  let currentTime = startTime;

  while (currentTime <= endTime) {
    quarterArray.push(parseInt(currentTime.format("X")));
    currentTime = moment(currentTime).add(15, "minutes");
  }


  if (trim) {
    const adjStart = roundQuarter(start,"start")
    const adjEnd = roundQuarter(end,"end")

    quarterArray = quarterArray.filter(d=>d>=adjStart&&d<adjEnd)
  }

  return quarterArray;
};

const roundQuarter = (timestamp, type) => {
  const quarters = [0, 15, 30, 45];
  const roundedTimestamp = roundTimestamp(timestamp);
  const mins = moment
    .unix(roundedTimestamp)
    .utc()
    .minutes();
  
  let adjustment = null;

  if (type === "start") {
    quarters.filter(d=>d<=mins).forEach(d => {
      const closeness = Math.abs(mins - d);
      if (adjustment) {
        if (closeness < adjustment) {
          adjustment = closeness;
        }
      } else {
        adjustment = closeness;
      }
    });
    return parseInt(
      moment
        .unix(timestamp)
        .utc()
        .subtract(adjustment, "minutes")
        .format("X")
    );
  } 
  else if (type === "end") {
    quarters.forEach(d => {
      const closeness = Math.abs(d - mins);
      if (adjustment) {
        if (closeness < adjustment) {
          adjustment = closeness;
        }
      } else {
        adjustment = closeness;
      }
    });
    if (adjustment===0){
      adjustment=adjustment+15
    }
    return parseInt(
        moment.unix(roundedTimestamp).utc().add(adjustment,'minutes').format("X")
    )
  }
};

export default QuarterRange;
export { roundQuarter };
