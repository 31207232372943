import React from "react";
import styled from "styled-components";
import Downloadable from "../Home/Downloadable";

export default ({ downloadables, theme }) => {
  return (
    <Downloadables>
      {downloadables.map((resource, i) => (
        <Downloadable resource={resource} key={i} />
      ))}
    </Downloadables>
  );
};

const Downloadables = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding-left: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
`;
