export const Comp222 = {
  compID: 222,
  compType: 2,
  title: "Pedestrians And Bicyclists Off Crosswalk Trajectories And Videos",
  settings: {
    quarterStatsTransformation: {
      type: "byApproach",
      countDuplicates: true,
    },
    hasDropdown: false,
  },
  styling: {
    align: "left",
    mapDefaults: {
      circle: true,
      lines: true,
    },
  },
  filtering: [
    {
      title: "General",
      filters: [
        {
          dataKeys: ["videoFileNamesSmall"],
          dataType: "boolean",
          title: "Events with Video",
        },
        {
          dataKeys: ["t_posix"],
          dataType: "range",
          width: 400,
          title: "Time Period",
          lookup: {
            type: "time",
            formatting: "short",
          },
        },
        {
          dataKeys: ["tMargin"],
          dataType: "range",
          width: 400,
          title: "Crossing Time",
          lookup: {
            type: "number",
            formatting: 1,
          },
        },
      ],
    },
    {
      title: "User 1",
      filters: [
        {
          dataKeys: ["class1"],
          dataType: "discreet",
          title: "Type",
          lookup: {
            type: "roadUsers",
            formatting: "full",
          },
        },
        {
          dataKeys: ["arm1A"],
          dataType: "discreet",
          title: "Crossing Location",
          lookup: {
            type: "approaches",
            formatting: "namesFull",
          },
        },
      ],
    },
  ],
  table: {
    columns: [
      {
        title: "Id",
        data: [
          {
            dataKeys: ["eventId"],
            type: "number",
            formatting: 0,
          },
        ],
        delimiter: "",
        align: "left",
        fontSize: "14px",
        maxWidth: "",
        minWidth: "10px",
      },
      {
        title: "",
        data: [
          {
            dataKeys: ["videoFileNamesSmall"],
            type: "link",
            formatting: "",
          },
        ],
        align: "left",
        fontSize: "14px",
        maxWidth: "",
        minWidth: "50px",
      },
      {
        title: "Crossing Time",
        data: [
          {
            dataKeys: ["tMargin"],
            type: "number",
            formatting: 1,
          },
        ],
        align: "left",
      },
      {
        title: "Event Type",
        data: [
          {
            dataKeys: ["class1"],
            type: "roadUsers",
            formatting: "short",
          },
        ],
        delimiter: "-",
        align: "left",
        fontSize: "14px",
        maxWidth: "",
        minWidth: "10px",
      },
      {
        title: "Crossing Location",
        data: [
          {
            dataKeys: ["arm1A"],
            type: "approaches",
            formatting: "namesFull",
          },
        ],
        align: "left",
        fontSize: "14px",
        maxWidth: "",
        minWidth: "50px",
      },
      {
        title: "Time",
        data: [
          {
            dataKeys: ["t_posix"],
            type: "time",
            formatting: "full",
          },
        ],
        align: "left",
        fontSize: "14px",
        maxWidth: "",
        minWidth: "100px",
      },
    ],
  },
  overview: {
    piechart: {
      title: "Road Users Involved",
    },
  },
  lookupPath: {
    piechart: {
      type: "roadUsers",
      formatting: "full",
    },
  },
};
