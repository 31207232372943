import Nullify from '../utils/Nullify'

const CleanLines = (data)=> {
    if (data) {
        return data.map(d=>({
            t:Nullify(d.t),
            u_id:Nullify(d.u_id),
            eventId:Nullify(d.eventId),
            eventClass:Nullify(d.eventClass),
            x_pix:d.x_pix,
            y_pix:d.y_pix
        }))
    } else {
        return "empty"  
    }
}

export default CleanLines;