export const Comp131 =  {
    compID: 131,
    compType: 1,
    title: "Vulnerable Road Users Counts",
    styling: {
      dots: true,
    },
    settings: {
      hasDropdown: false,
    },
    databox: [
      {
        title: "Overview",
        stats: [
          {
            title: "Study Total",
            type: "totalCount",
          },
          {
            title: "Daily Avg",
            type: "dailyAvg",
          },
        ],
      },
      {
        title: "Peaks",
        stats: [
          {
            title: "Peak Hour (AM)",
            type: "peakHour",
            option: "am",
          },
          {
            title: "Peak Hour (PM)",
            type: "peakHour",
            option: "pm",
          },
          {
            title: "Peak Quarter (AM)",
            type: "peakQuarter",
            option: "am",
          },
          {
            title: "Peak Quarter (PM)",
            type: "peakQuarter",
            option: "pm",
          },
        ],
      },
    ],
    lookupPath: {
      dropdown: {
        type: "other",
        formatting: "count",
      },
      legend: {
        type: "other",
        formatting: "count",
      },
      tooltip: {
        type: "other",
        formatting: "count",
      },
    },
  }