import { useState } from 'react';


const useMapLogic = () => {
  const [showSchematic, setshowSchematic] = useState(true);
  const [hoveringRow, setHoveringRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [interactive, setInteractive] = useState(true);
  const [tableRows, setTableRows] = useState(null);
  const [showBackground, setShowBackground] = useState(true);


  // functionality
  const backgroundSwitch = () => {
    setShowBackground(!showBackground);
  };

  const schematicSwitch = () => {
    setshowSchematic(!showSchematic);
  };



  return {
    hoveringRow,
    setHoveringRow,
    backgroundSwitch,
    schematicSwitch,
    tableRows,
    setTableRows,
    selectedRows,
    showSchematic,
    showBackground,
    interactive,
    setInteractive,
    setShowBackground,
    setSelectedRows
  }
};

export default useMapLogic;
