export const Comp213 = {
  compID: 213,
  compType: 4,
  title: "Conflict plot by motion-conflict pair",
  styling: {
    divSize: 60,
    justifyContent: "center",
  },
  lookupPath: {
    combined: false,
    dropdown: {
      type: "special",
      formatting: "",
      specialLookup: {
        1: "",
      },
    },
  },
};
