import styled from 'styled-components';
import React, { useRef, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '../Button';


const VideoModal = ({ modalState, send }) => {
  const ref = useRef(null);
  const { signedUrl, nextVideo, prevVideo } = modalState.context;


  // reload video on new video URL
  useEffect(() => {

    if (ref.current) {
      const player = ref.current;
      let source = player.querySelector('source');
      source.src = signedUrl;
      player.load();
    }


  }, [ref, signedUrl])

  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="main-modal"
      open={modalState.matches('opened')}
      closeAfterTransition
      onEscapeKeyDown={() => send({
        type: "CLOSE",
      })}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: 'main-modal__backdrop'
      }}
    >
      <StyledFade in={modalState.matches('opened')}>

        <ContentWrapper>
          <Top>
            <CloseIcon
              onClick={() => send({
                type: "CLOSE",
              })}
              width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="3.12132" y1="3" x2="21" y2="20.8787" stroke="black" stroke-width="3" stroke-linecap="round" />
              <path d="M21 2.99995L2.99998 20.9999" stroke="black" stroke-width="3" stroke-linecap="round" />
            </CloseIcon>
          </Top>
          <VideoPlayer ref={ref} controls autoPlay>
            <source src={signedUrl} type="video/mp4" />
                Your browser does not support the video tag. I suggest you upgrade your browser.
              </VideoPlayer>
          <Bottom>
            <NavigationIcon isActive={prevVideo} onClick={() => send({
              type: "PAGINATE",
              payload: {
                url: prevVideo,
              }
            })} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M37.4999 12.4999L19.7915 24.9999L37.4999 37.4999V12.4999ZM16.6665 37.4999V12.4999H12.4999V37.4999H16.6665Z" fill="black" />
            </NavigationIcon>
            <NavigationIcon isActive={nextVideo} style={{ transform: 'rotate(180deg)' }} onClick={() => send({
              type: "PAGINATE",
              payload: {
                url: nextVideo,
              }
            })} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M37.4999 12.4999L19.7915 24.9999L37.4999 37.4999V12.4999ZM16.6665 37.4999V12.4999H12.4999V37.4999H16.6665Z" fill="black" />
            </NavigationIcon>

          </Bottom>
        </ContentWrapper>


      </StyledFade>
    </StyledModal>


  )
};


const StyledModal = styled(Modal)`
  display:flex;
  align-items:center;
  justify-content:center;

  .main-modal__backdrop {
    background-color: rgba(0,0,0,.7);
  }
`

const Top = styled.div`
  height: 100px;
  width: 100%;
  display:flex;
  justify-content: right;
  padding-right: 40px;
  align-items: center;
`

const CloseIcon = styled.svg`
  path, line {
    stroke: #fff;
  }

  cursor: pointer;
  
`

const Bottom = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
`
const NavigationIcon = styled.svg`
  cursor:pointer;
  path {
    fill: ${props => props.isActive ? "#fff" : "#999999"};
  }
  margin: 0px 16px;
`

const StyledFade = styled(Fade)`
  &:focus {
    outline: none;
  }
`


const ContentWrapper = styled.div`
  max-width: 70em;
  background:#000;
  display:flex;
  flex-flow:column;
  border-radius: 24px;
`


const VideoPlayer = styled.video`
  width: 100%;

  &:focus {
    outline:none;
  }

`
export default VideoModal;
