import { assign, createMachine } from "xstate";

export const theme__machine = createMachine(
  {
    id: "theme__machine",
    context: {
      theme: {
          gradient: "linear-gradient(90deg, #86F8FB, #03989E)",
      primary: "#FE9920",
    primaryLight: "#FFDE59",
    primaryDark: "#FE9920",
    secondary: "#03989E",
    secondaryLight: "red",
    secondaryDark: "rgba(0, 0, 0, 0.54)",
    whiteBoxBorder: "#e6e6e6",
    greyScale: "#F5F5F5",
    textDark: "#000",
    textLight: "#fff",
    error: "red",
    positive: "#02F7FF",
    negative: "#FF9624",
      },
    },
    initial: "idle",
    states: {
      idle: {
        on: {
          UPDATE_THEME: {
            actions: "updateTheme",
          },
        },
      },
    },
  },
  {
    actions: {
      updateTheme: assign((c, e) => {
        const { theme } = e.payload;
        return {
          theme: { ...theme },
        };
      }),
    },
  }
);
