import gql from "graphql-tag";

export default gql`
  {
    client {
      client_id
      client_name
      email
      datetime
      admin
      first_login
      theme {
        primary
        primaryLight
        primaryDark
        secondary
        secondaryDark
        secondaryLight
        error
        gradient
        textDark
        textLight
        whiteBoxBorder
        greyScale
        negative
        positive
      }
    }
  }
`;
