import React, { useState } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const View3 = ({
  authUser,
  theme,
  setStep,
  verificationCode,
  email,
  errorMsg,
  setErrorMsg,
}) => {
  const [password, setPassword] = useState("");
  const [verified, setVerified] = useState(true);

  const resetPassword = async () => {
    try {
      if (verified && password !== "" && verificationCode) {
        await Auth.forgotPasswordSubmit(email, verificationCode, password);
        setStep(4);
      } else if (!verified || password === "") {
        setErrorMsg(`Password not valid`);
      } else if (!verificationCode) {
        setErrorMsg(`Verification Code missing, please try again`);
      }
    } catch (err) {
      console.log(err);
      setErrorMsg(
        `Could not reset password, please try again or contact admin`
      );
    }
  };

  const verifyPassword = (value) => {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    if (value.match(pattern)) {
      setVerified(true);
    } else {
      setVerified(false);
    }
    setPassword(value);
    setErrorMsg(null);
  };
  return (
    <>
      <SubText theme={theme}>Enter your desired password.</SubText>
      <PasswordCriterias theme={theme}>
        <p>Note that we require:</p>
        <ul>
          <li>at least 8 characters</li>
          <li>at least 1 number</li>
          <li>at least 1 lowercase</li>
          <li>at least 1 uppercase</li>
        </ul>
      </PasswordCriterias>
      {errorMsg && <ErrorMsg theme={theme}>{errorMsg}</ErrorMsg>}
      <TextField
        theme={theme}
        variant="outlined"
        margin="normal"
        error={!verified}
        required
        fullWidth
        id="password"
        label="New Password"
        type="password"
        value={password}
        onChange={(e) => verifyPassword(e.target.value)}
      />
      <Button
        theme={theme}
        onClick={resetPassword}
        fullWidth
        variant="contained"
        color="primary"
      >
        Submit
      </Button>
    </>
  );
};

const PasswordCriterias = styled.div`
  font-size: 14px;
`;

const SubText = styled.div`
  margin-top: 25px;
  font-size: 1.2em;
  margin-bottom: 50px;
  text-align: center;
`;

const ErrorMsg = styled.div`
  color: ${(props) => props.theme.error};
  font-size: 14px;
`;

export default View3;
