import React from "react";
import Spinner from "../Components/Spinner";
import GlobalTopBar from "../Studies/TopBar/GlobalTopBar";
import UpdateTitle from "../../utils/UpdateTitle";
import Onboarding from "../Users/Onboarding";
import Grid from "../../styling/Grid";
import IntersectionCards from "./IntersectionCards";
import TopSection from "../Components/TopSection";

export default ({ authUser, theme }) => {
  if (!authUser) {
    return <Spinner theme={theme} />;
  }

  if (authUser.first_login) {
    return <Onboarding theme={theme} authUser={authUser} />;
  }

  return (
    <>
      <GlobalTopBar theme={theme} authUser={authUser} />
      <Grid>
        <TopSection theme={theme} />
        <UpdateTitle title={authUser.client_name} />
        <IntersectionCards theme={theme} authUser={authUser} />
      </Grid>
    </>
  );
};
