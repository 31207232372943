import _ from 'lodash'

const PieChartifyQuarterStats = ({data,thisStudy,lookupPath}) => {

    // -----------------------------------------------------------------------------
    // takes data array and lookup object and transforms data to work with PieChart
    // -----------------------------------------------------------------------------

    let construct = [];

    const groups = Object.keys(data[0]).filter(key=>key!=="date")

    groups.forEach(group=>{
        construct.push({
            name: group,
            value: _.sum(data.map(d=>d[group]))
        })
    })

    return construct

}

const PieChartifyEvents = ({data,thisStudy,lookupPath}) => {

    // -----------------------------------------------------------------------------
    // takes data array and lookup object and transforms data to work with PieChart
    // -----------------------------------------------------------------------------

    let construct = [];

    let count = {};
    data.forEach(d=>{
        if (d.class1 >= 0 && d.class1 !== null) {
            if (count[d.class1]) {
                count[d.class1] ++
            } else {
                count[d.class1] = 1
            }
        }
        if (d.class2 >= 0 && d.class2 !== null) {
            if (count[d.class2]) {
                count[d.class2] ++
            } else {
                count[d.class2] = 1
            }
        }
    })

    Object.keys(count).forEach(d=> {
        construct.push({
            name: d,
            value: count[d]
        })
    })

    return construct;
}

export {
    PieChartifyQuarterStats,
    PieChartifyEvents,
}