import moment from "moment";
import SumRow from "./SumRow";

const trimData = (data, strict) => {
    
  // --------------------------------------------------------
  // takes data array and trims to return a "zoomed in" view
  // --------------------------------------------------------

  const recordedData = data.filter(d => SumRow(d) !== null).map(d => d.date);

  let start;
  let stop;

  if (strict) {
    start = recordedData[0]
    stop = recordedData[recordedData.length-1]

  } else {
    start = getEvenHour(recordedData[0], "prev");
    stop = getEvenHour(recordedData[recordedData.length - 1], "next");
  }

  return data.filter(d => d.date >= start && d.date <= stop);
};

const isEvenHour = timestamp => {
  if (
    moment
      .unix(timestamp)
      .utc()
      .format("mm") === "00"
  ) {
    return true;
  } else {
    return false;
  }
};

const getEvenHour = (timestamp, direction) => {
  if (!isEvenHour(timestamp)) {
    const mins = Number(
      moment
        .unix(timestamp)
        .utc()
        .format("mm")
    );
    // previous even hour
    if (direction === "prev") {
      const msOffset = mins * 60 + 3600;
      return timestamp - msOffset;
    }
    // next even hour
    else if (direction === "next") {
      const msOffset = (60 - mins) * 60 + 3600;
      return timestamp + msOffset;
    }
  } else {
    if (direction === "prev") {
      return timestamp - 3600;
    } else if (direction === "next") {
      return timestamp + 3600;
    }
  }
};

export { trimData, isEvenHour, getEvenHour };
