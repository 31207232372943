import React, { useEffect } from "react";
import styled from "styled-components";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Spinner from "../../../Components/Spinner";
import {
  formatColumn,
} from "../../../../Data/Handlers/utils/TableHelper";
import RowLink from "./RowLink";
import useTableLogic from './useTableLogic';
import VideoModal from '../../../Components/Modal/videoModal';
import useModal from '../../../Components/Modal/useModal';

export default ({
  data,
  setHoveringRow,
  selectedRows,
  setSelectedRows,
  tableRows,
  setTableRows,
  columns,
  display,
  thisStudy,
  theme,
}) => {

  const { page, rowsPerPage, isSelected, select, changePage, changeRowsPerPage, adjustedColumns, selectAll, orderBy, order, createSortHandler } = useTableLogic({
    columns,
    data,
    setSelectedRows,
    selectedRows,
    setTableRows,
    thisStudy,
  });

  const [modalState, send] = useModal({ data: tableRows, study_id: thisStudy.study_id });


  if (!tableRows) {
    return <Spinner theme={theme} />;
  }

  return (
    <Wrapper style={display ? {} : { display: "none" }}>
      <VideoModal
        modalState={modalState}
        send={send}
      />
      <TableWrapper>
        <MainTable stickyHeader size="small" aria-label="gpsTable">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < data.length
                  }
                  checked={selectedRows.length === data.length}
                  onChange={selectAll}
                />
              </TableCell>
              {adjustedColumns.map((column, i) => (
                <TableCell
                  key={i}
                  padding={column.title === "" ? "none" : "default"}
                  align={column.align}
                  sortDirection={orderBy.title === column.title ? order : false}
                >
                  <TableSortLabel
                    active={orderBy.title === column.title}
                    direction={orderBy.title === column.title ? order : "asc"}
                    onClick={createSortHandler(column)}
                  >
                    {column.title}
                    {orderBy.title === column.title ? (
                      <SortArrow>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </SortArrow>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((row, i) => {
              const isItemSelected = isSelected(row.row_id);
              const labelId = `gpsTable-checkbox-${i}`;

              return (
                <StyledTableRow
                  theme={theme}
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.row_id}
                  selected={isItemSelected}
                  onMouseEnter={() =>
                    setHoveringRow({ location: "table", id: row.row_id })
                  }
                  onMouseLeave={() => setHoveringRow(null)}
                >
                  <TableCell
                    padding="checkbox"
                    onClick={(event) => modalState.matches('closed') && select(event, row.row_id)}
                  >
                    <Checkbox
                      theme={theme}
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </TableCell>

                  {adjustedColumns.map((column, i) => (
                    <React.Fragment key={i}>
                      {column.data.length === 1 &&
                        column.data[0].type === "link" &&
                        row ? (
                          <TableCell key={i} align={column.align} padding="none">
                            <RowLink
                              key={row.row_id}
                              url={formatColumn({ column, thisStudy, row })}
                              send={send}
                            />
                          </TableCell>
                        ) : (
                          <TableCell
                            key={i}
                            align={column.align}
                            onClick={(event) => modalState.matches('closed') && select(event, row.row_id)}
                          >
                            <div>
                              <TableText
                                minWidth={column.minWidth}
                                fontSize={column.fontSize}
                              >
                                {formatColumn({ column, thisStudy, row })}
                              </TableText>
                            </div>
                          </TableCell>
                        )}
                    </React.Fragment>
                  ))}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </MainTable>
      </TableWrapper>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "next-page",
        }}
        nextIconButtonProps={{
          "aria-label": "previous-page",
        }}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1 50%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const TableWrapper = styled.div`
  flex: 1 80%;
  overflow: auto;
  white-space: nowrap;
  position: relative;
`;

const MainTable = styled(Table)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`;

const StyledTableRow = styled(TableRow)`
  && {
    &:hover {
      background-color: ${(props) => props.theme.primaryLight} !important;
    }

    &.Mui-selected {
      background-color: ${(props) => props.theme.primaryLight};
    }
  }
`;

const SortArrow = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`;

const TableText = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1em")};
  padding: 0;
  margin: 0;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
`;
