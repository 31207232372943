export const Comp121  = {
    compID: 121,
    compType: 1,
    title: "Average Speed By Motion",
    styling: {
      dots: true,
    },
    settings: {
      hasDropdown: true,
    },
    databox: [
      {
        title: "Median Speed per Motion",
        stats: [
          {
            title: "MedianSpeed",
            type: "medianSpeed",
          },
        ],
      },
      {
        title: "Speed Limits",
        stats: [
          {
            title: "Speed Limit",
            type: "speedLimit",
          },
        ],
      },
    ],
    lookupPath: {
      dropdown: {
        type: "approaches",
        formatting: "namesFull",
      },
      legend: {
        type: "motions",
        formatting: "namesFull",
      },
      tooltip: {
        type: "motions",
        formatting: "namesFull",
      },
    },
  }