import React, { useState } from "react";
import GlobalTopBar from "../Studies/TopBar/GlobalTopBar";
import UpdateTitle from "../../utils/UpdateTitle";
import styled from "styled-components";
import Spinner from "../Components/Spinner";
import View1 from "./Views/View1";
import View2 from "./Views/View2";
import View3 from "./Views/View3";
import View4 from "./Views/View4";

export default ({ authUser, theme }) => {
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1);
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  return (
    <>
      <GlobalTopBar authUser={authUser} />
      <TopSection>
        <TopText>
          Street
          <br />
          Simplified
        </TopText>
      </TopSection>
      <UpdateTitle title={authUser ? authUser.client_name : ""} />

      {loading ? (
        <Spinner theme={theme} />
      ) : (
        <Wrapper>
          {step === 1 && (
            <View1
              authUser={authUser}
              setStep={setStep}
              setLoading={setLoading}
              email={email}
              setEmail={setEmail}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
            />
          )}
          {step === 2 && (
            <View2
              authUser={authUser}
              setStep={setStep}
              setVerificationCode={setVerificationCode}
              setLoading={setLoading}
              email={email}
              setEmail={setEmail}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
            />
          )}
          {step === 3 && (
            <View3
              authUser={authUser}
              setStep={setStep}
              verificationCode={verificationCode}
              setLoading={setLoading}
              email={email}
              setEmail={setEmail}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
            />
          )}
          {step === 4 && <View4 authUser={authUser} />}
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
`;

const Heading = styled.div`
  padding-top: 25px;
  font-size: 36px;
  text-align: center;
`;

const SubText = styled.div`
  margin-top: 25px;
  font-size: 18px;
  margin-bottom: 50px;
  text-align: center;
`;

const TopSection = styled.div`
  background: ${(props) => props.theme.gradient};
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopText = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 20px;

  color: ${(props) => props.theme.textLight};
  font-size: 5vw;
`;

const ErrorMsg = styled.div`
  color: red;
  font-size: 14px;
`;
