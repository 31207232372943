

const MergeFilters = ({ newApplied, oldApplied }) => {
  let construct = {
    settings: newApplied.settings,
    filters: oldApplied.filters
  };

  if (!newApplied.filters) {
    construct.filters = null;
  }

  // deal with keys
  Object.keys(newApplied.filters).forEach(filter => {
    if (!newApplied.filters[filter]) {
      delete construct.filters[filter];
    } else {
      // each data key
      Object.keys(newApplied.filters[filter].keys).forEach(filterKey => {
        if (construct.filters) {
          if (construct.filters[filter]) {
            if (construct.filters[filter].keys[filterKey]) {
              construct.filters[filter].keys[filterKey].filterValues =
                newApplied.filters[filter].keys[filterKey].filterValues;
              construct.filters[filter].names =
                newApplied.filters[filter].names;
            } else {
              // not needed...
            }
          } else {
            // filter doesn't exist, add
            construct.filters[filter] = {
              keys: newApplied.filters[filter].keys,
              names: newApplied.filters[filter].names
            };
          }
        } else {
          construct.filters = {
            [filter]: {
              keys: newApplied.filters[filter].keys,
              names: newApplied.filters[filter].names
            }
          };
        }
      });
    }
  });

  return construct;
};

export default MergeFilters;
