function CleanClient(data) {
  if (data) {
    let d = data;

    return {
      client_id: d.client_id,
      client_name: d.client_name,
      datetime: d.datetime,
      email: d.email,
      admin: d.admin,
      first_login: d.first_login,
      theme: d.theme ? { ...d.theme } : null,
    };
  }
}

export default CleanClient;
