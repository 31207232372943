import React, { useState, useEffect } from "react";
import CompStyleWrapper from "../style_components/CompStyleWrapper";
import Spinner from "../../../Components/Spinner";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useGetData from "../../../../Data/Fetching/useGetData";
import styled from "styled-components";
import Naming from "../Utils/Naming";
import ImagesByDiv from "../Utils/ImagesByDiv";
import Image from "./Image";

const ImageDropdown = ({ comp, thisStudy, theme }) => {
  const { dataFormatted: data, loading, error } = useGetData({
    compID: comp.compID,
    thisStudy,
  });
  const [index, setindex] = useState(0);
  const [status, setStatus] = useState("loading");

  const handleChange = (e) => {
    setindex(e.target.value);
  };

  useEffect(() => {
    if (loading || (!data && !error)) {
      setStatus("loading");
    } else if (error || data === "empty") {
      setStatus("error");
    } else if (data) {
      setStatus("success");
    }
  }, [data, loading, error]);



  // loading?
  if (status === "loading") {
    return (
      <CompStyleWrapper title={`${comp.title}`} theme={theme}>
        <Spinner theme={theme} />
      </CompStyleWrapper>
    );
  }

  // error of empty?
  if (status === "error") {
    return <></>;
  }

  // successful
  if (status === "success") {
    return (
      <CompStyleWrapper theme={theme} title={`${comp.title}`}>
        <TopBar theme={theme}>
          {/* creates dropdown selection */}
          <Select style={{ width: 250 }} onChange={handleChange} value={index}>
            {data.images.map((row, i) => (
              <MenuItem theme={theme} key={i} value={i}>
                {row.dropdown.type === "special"
                  ? comp.lookupPath.dropdown.specialLookup[row.dropdown.key]
                  : Naming({
                    thisStudy,
                    lookupPath: comp.lookupPath,
                    key: row.dropdown.key,
                    part: "dropdown",
                    approach: null,
                    combined: comp.lookupPath.combined,
                  })}
              </MenuItem>
            ))}
          </Select>
        </TopBar>
        <ImageSection justifyContent={comp.styling.justifyContent}>
          {data.images.map((selection, idx) => (
            <React.Fragment key={idx}>
              {ImagesByDiv(selection.urls).map((div) => (
                <ImageDiv
                  key={div.divIndex}
                  divSize={comp.styling.divSize}
                  displayImage={idx === index}
                >
                  {div.images.map((img, i) => (
                    <Image
                      src={img.url}
                      key={i}
                      zIndex={img.zIndex}
                      imageLoading={true}
                    />
                  ))}
                </ImageDiv>
              ))}
            </React.Fragment>
          ))}
        </ImageSection>
      </CompStyleWrapper>
    );
  }
};

const TopBar = styled.div`
  width: 100%;
  padding: 10px;
`;

const ImageSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  flex-flow: row wrap;
`;

const ImageDiv = styled.div`
  flex: 0 1 ${(props) => props.divSize}%;
  display: ${(props) => (props.displayImage ? "" : "none")};
`;

export default ImageDropdown;
