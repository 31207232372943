import React, { useEffect, useState } from "react";
import { pipe, filter, toPairs, fromPairs, reduce } from 'ramda';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import * as compose from "lodash.flowright";
import { graphql } from "react-apollo";
import GetAuth from "./Data/Fetching/QueryLibrary/GetAuth";
import { Redirect, Route } from "react-router";
import GetClient from "./Data/Fetching/QueryLibrary/GetClient";
import CleanClient from "./Data/Handlers/Client/CleanClient";
import { useApolloClient } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import Spinner from "./Views/Components/Spinner";
import { Auth } from "aws-amplify";

const SecureRoute = ({
  authStatus,
  component: Component,
  theme,
  updateTheme,
  ...rest
}) => {
  const [authUser, setAuthUser] = useState(null);
  const [reload, setReload] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const client = useApolloClient();
  const { data, loading, error } = useQuery(GetClient, {
    fetchPolicy: "no-cache",
  });
  const materialTheme = createTheme({
    palette: {
      primary: {
        light: theme.primaryLight,
        main: theme.primary,
        dark: theme.primaryDark,
      },
      secondary: {
        light: theme.secondaryLight,
        main: theme.secondary,
        dark: theme.secondaryDark
      }
    }
  })


  useEffect(() => {

    if (data) {
      setAuthUser(CleanClient(data.client));

      if (data.client.theme) {
        const trimObj = pipe(
          toPairs,
          filter(([, value]) => value !== null),
          fromPairs
        )

        const updatedTheme = pipe(
          reduce((acc, curr) => ({
            ...trimObj(acc),
            ...trimObj(curr)
          }), {})
        )([theme, data.client.theme])

        updateTheme({
          type: "UPDATE_THEME",
          payload: {
            theme: updatedTheme
          },
        });
      }
    } else if (error) {
      console.log(error);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error, reload, redirect, updateTheme]);

  // Refresh Token
  const refreshToken = async () => {
    try {
      const data = await Auth.currentSession();
      if (localStorage.getItem("auth-token") !== data.idToken.jwtToken) {
        localStorage.setItem("auth-token", data.idToken.jwtToken);
        setReload(!reload);
      }
    } catch (err) {
      setRedirect(true);
    }
  };

  if (!redirect) {
    refreshToken();
  }

  if (authStatus.status === "loggedOut") {
    // reset cache
    client.resetStore();
    return <Redirect to="/login" />;
  } else if (authStatus.status === "loggedIn") {
    if (!authUser) {
      return <Spinner theme={theme} />;
    }
    // else if (authUser.first_login) {
    //   return <Onboarding authUser={authUser} />
    // }

    else {
      return (
        <Route
          {...rest}
          render={(props) => (
            <ThemeProvider theme={materialTheme}>
              <Component
                {...props}
                authUser={authUser}
                theme={theme}
                updateTheme={updateTheme}
              />


            </ThemeProvider>
          )}
        />
      );
    }
  }
};

export default compose(
  graphql(GetAuth, {
    props: ({ data: { authStatus } }) => ({
      authStatus,
    }),
  })
)(SecureRoute);
