import _ from 'lodash';

export default (data) => {

    let construct = []
    
    // how many unique divs?
    const divs = _.uniqBy(data, 'divIndex').map(d=>d.divIndex)

    divs.forEach(div=>{

        let item  = {
            divIndex:div,
            images:[]
        }

        const images = zIndexOrder(data.filter(d=>d.divIndex===div))

        if (images.length) {
            item.images = images

            construct.push(item)
        }

    })

    return construct

}

const zIndexOrder = (urls) => {
    
    let construct = []

    _.sortBy(urls, 'imageID').reverse().forEach((img,i)=>{
        construct.push({
            url:img.url,
            zIndex:i+2
        })
    })

    return construct

}

export {
    zIndexOrder
}

