import React from "react";
import styled from "styled-components";
import CustomPieChart from "../GpsDropdown/CustomPieChart";

const OverviewBox = ({ data, comp, thisStudy, display, theme }) => {
  return (
    <Wrapper style={display ? {} : { display: "none" }}>
      <CustomPieChart
        theme={theme}
        data={data}
        title={comp.overview.piechart.title}
        lookupPath={comp.lookupPath}
        thisStudy={thisStudy}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1 50%;
  position: relative;
`;

export default OverviewBox;
