import moment from "moment";

const formatDate = (timestamp, format) => {
  if (format === "full") {
    return moment.unix(timestamp).utc().format("ddd, MMM DD h:mmA");
  } else if (format === "short") {
    return moment.unix(timestamp).utc().format("M/DD h:mmA");
  } else if (format === "time") {
    return moment.unix(timestamp).utc().format("h:mmA");
  } else if (format === "date") {
    return moment.unix(timestamp).utc().format("ddd, MMM DD, YYYY");
  } else if (format === "fullWithYear") {
    return moment.unix(timestamp).utc().format("LLLL");
  } else {
    return null;
  }
};

const formatDateRange = (start, stop, format) => {
  if (format === "full") {
    return `${moment
      .unix(start)
      .utc()
      .format("ddd, MMM DD h:mm")}-${moment.unix(stop).utc().format("h:mmA")}`;
  } else if (format === "short") {
    return `${moment.unix(start).utc().format("M/DD h:mm")}-${moment
      .unix(stop)
      .utc()
      .format("h:mmA")}`;
  } else if (format === "time") {
    return `${moment.unix(start).utc().format("h:mm")}-${moment
      .unix(stop)
      .utc()
      .format("h:mmA")}`;
  }
};

export { formatDate, formatDateRange };
