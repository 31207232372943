export function modalPagination(data, activeVideoUrl) {

  const hasValidUrl = data.filter((x) => x.videoFileNamesSmall && x.videoFileNamesSmall !== "");

  const indexOfActive = hasValidUrl.map((x) => x.videoFileNamesSmall).indexOf(activeVideoUrl)

  const firstHalf = hasValidUrl.slice(0, indexOfActive);
  const secondHalf = hasValidUrl.slice(indexOfActive + 1, hasValidUrl.length);


  return {
    nextVideoUrl: secondHalf.length ? secondHalf[0].videoFileNamesSmall : null,
    prevVideoUrl: firstHalf.length ? firstHalf[firstHalf.length - 1].videoFileNamesSmall : null
  }
}
