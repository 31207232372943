import React from "react";
import {
  FiLayers,
  FiShare2,
  FiTruck,
  FiActivity,
  FiTriangle,
  FiWind,
  FiShield,
} from "react-icons/fi";
import {
  FaBicycle,
  FaCarCrash,
  FaWalking,
  FaTrafficLight,
} from "react-icons/fa";

import { Comp111 } from "./components/111";
import { Comp112 } from "./components/112";
import { Comp113 } from "./components/113";
import { Comp114 } from "./components/114";
import { Comp115 } from "./components/115";
import { Comp121 } from "./components/121";
import { Comp122 } from "./components/122";
import { Comp123 } from "./components/123";
import { Comp124 } from "./components/124";
import { Comp125 } from "./components/125";
import { Comp131 } from "./components/131";
import { Comp132 } from "./components/132";
import { Comp133 } from "./components/133";
import { Comp134 } from "./components/134";
import { Comp141 } from "./components/141";
import { Comp142 } from "./components/142";
import { Comp213 } from "./components/213";
import { Comp211 } from "./components/211";
import { Comp212 } from "./components/212";
import { Comp221 } from "./components/221";
import { Comp222 } from "./components/222";
import { Comp234 } from "./components/234";
import { Comp235 } from "./components/235";
import { Comp231 } from "./components/231";
import { Comp232 } from "./components/232";
import { Comp233 } from "./components/233";
import { Comp245 } from "./components/245";
import { Comp246 } from "./components/246";
import { Comp241 } from "./components/241";
import { Comp242 } from "./components/242";
import { Comp243 } from "./components/243";
import { Comp244 } from "./components/244";

export const SiteStructure = [
  // SECTIONS
  {
    titleShort: "Summary",
    titleLong: "Street Summary",
    routes: [
      {
        id: 31,
        path: "street/summary",
        title: "Summary",
        icon: <FiShield />,
        components: [
          {
            compID: 311,
            compType: 4,
            title: "Top 80% of Near Misses",
            styling: {
              divSize: 80,
              justifyContent: "center",
            },
            lookupPath: {
              combined: false,
              dropdown: {
                type: "special",
                formatting: "",
                specialLookup: {
                  1: "",
                },
              },
            },
          },
          {
            compID: 312,
            compType: 4,
            title: "Causal Factor Risk Plot",
            styling: {
              divSize: 60,
              justifyContent: "center",
            },
            lookupPath: {
              combined: false,
              dropdown: {
                type: "special",
                formatting: "",
                specialLookup: {
                  1: "",
                },
              },
            },
          },
          {
            compID: 313,
            compType: 4,
            title: "Crash Diagram",
            styling: {
              divSize: 80,
              justifyContent: "center",
            },
            lookupPath: {
              combined: false,
              dropdown: {
                type: "special",
                formatting: "",
                specialLookup: {
                  1: "",
                },
              },
            },
          },
        ],
      },
    ],
  },
  {
    titleShort: "Street",
    titleLong: "Street Analytics",
    routes: [
      {
        id: 11,
        path: "street/vehicle-counts",
        title: "Vehicle Counts",
        icon: <FiLayers />,
        components: [
          { ...Comp111 },
          { ...Comp112 },
          { ...Comp113 },
          { ...Comp114 },
          { ...Comp115 },
        ],
      },
      {
        id: 12,
        path: "street/vehicle-speed",
        title: "Vehicle Speed",
        icon: <FiShare2 />,
        components: [
          { ...Comp121 },
          { ...Comp122 },
          { ...Comp123 },
          { ...Comp124 },
          { ...Comp125 },
        ],
      },
      {
        id: 13,
        path: "street/crosswalk",
        title: "Crosswalk",
        icon: <FiTriangle />,
        components: [
          { ...Comp131 },
          { ...Comp132 },
          { ...Comp133 },
          { ...Comp134 },
        ],
      },
      {
        id: 14,
        path: "street/bikes-on-street",
        title: "Bikes On Street",
        icon: <FaBicycle />,
        components: [{ ...Comp141 }, { ...Comp142 }],
      },
    ],
  },
  {
    titleShort: "Safety",
    titleLong: "Safety Analytics",
    routes: [
      {
        id: 21,
        path: "safety/near-misses",
        title: "Near Misses",
        icon: <FaCarCrash />,
        components: [
          { ...Comp213 },
          { ...Comp211 },
          { ...Comp212 }
        ],
      },
      {
        id: 22,
        path: "safety/ped-off-xwalk",
        title: "Ped Off Xwalk",
        icon: <FaWalking />,
        components: [{ ...Comp221 }, { ...Comp222 }],
      },
      {
        id: 23,
        path: "safety/ped-xing-on-red",
        title: "Ped Xing On Red",
        icon: <FiActivity />,
        components: [
          { ...Comp234 },
          { ...Comp235 },
          { ...Comp231 },
          { ...Comp232 },
          { ...Comp233 },
        ],
      },
      {
        id: 24,
        path: "safety/red-light-running",
        title: "Red-light Running",
        icon: <FaTrafficLight />,
        components: [
          { ...Comp245 },
          { ...Comp246 },
          { ...Comp241 },
          { ...Comp242 },
          { ...Comp243 },
          { ...Comp244 },
        ],
      },
      {
        id: 25,
        path: "safety/speeding",
        title: "Speeding",
        icon: <FiWind />,
        components: [
          {
            compID: 254,
            compType: 4,
            title: "Speeding TMC",
            styling: {
              divSize: 40,
              justifyContent: "center",
            },
            lookupPath: {
              combined: false,
              dropdown: {
                type: "special",
                formatting: "",
                specialLookup: {
                  1: "",
                },
              },
            },
          },
          {
            compID: 255,
            compType: 4,
            title: "Speeding bar plot by direction",
            styling: {
              divSize: 60,
              justifyContent: "center",
            },
            lookupPath: {
              combined: false,
              dropdown: {
                type: "special",
                formatting: "",
                specialLookup: {
                  1: "",
                },
              },
            },
          },
          {
            compID: 251,
            compType: 3,
            title: "Speeding Counts (Road Users going 10mph+ over limit)",
            styling: {
              dots: true,
            },
            settings: {
              quarterStatsTransformation: {
                type: "byEvent",
                countDuplicates: false,
              },
              hasDropdown: false,
            },
            databox: [
              {
                title: "Overview",
                stats: [
                  {
                    title: "Total Count",
                    type: "totalCount",
                  },
                  {
                    title: "Daily Avg",
                    type: "dailyAvg",
                  },
                ],
              },
              {
                title: "Peaks",
                stats: [
                  {
                    title: "Peak Hour (AM)",
                    type: "peakHour",
                    option: "am",
                  },
                  {
                    title: "Peak Hour (PM)",
                    type: "peakHour",
                    option: "pm",
                  },
                ],
              },
              {
                title: "Speed Limits",
                stats: [
                  {
                    title: "Speed Limit",
                    type: "speedLimit",
                  },
                ],
              },
            ],
            filtering: [
              {
                title: "General",
                filters: [
                  {
                    dataKeys: ["videoFileNamesSmall"],
                    dataType: "boolean",
                    title: "Events with Video",
                  },
                  {
                    dataKeys: ["t_posix"],
                    dataType: "range",
                    width: 400,
                    title: "Time Period",
                    lookup: {
                      type: "time",
                      formatting: "short",
                    },
                  },
                ],
              },
              {
                title: "User 1",
                filters: [
                  {
                    dataKeys: ["class1"],
                    dataType: "discreet",
                    title: "Type",
                    lookup: {
                      type: "roadUsers",
                      formatting: "full",
                    },
                  },
                  {
                    dataKeys: ["arm1A"],
                    dataType: "discreet",
                    title: "Approach",
                    lookup: {
                      type: "approaches",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["arm1A", "arm1R"],
                    dataType: "discreet",
                    title: "Motion",
                    lookup: {
                      type: "motions",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["v1"],
                    dataType: "range",
                    width: 400,
                    title: "Speed",
                    lookup: {
                      type: "number",
                      formatting: 1,
                    },
                  },
                ],
              },
            ],
            lookupPath: {
              dropdown: {
                type: "other",
                formatting: "count",
              },
              legend: {
                type: "other",
                formatting: "count",
              },
              tooltip: {
                type: "other",
                formatting: "count",
              },
            },
          },
          {
            compID: 252,
            compType: 2,
            title: "Speeding Trajectories (Road Users going 10mph+ over limit)",
            styling: {
              dots: true,
              align: "left",
              mapDefaults: {
                circle: true,
                lines: true,
              },
            },
            filtering: [
              {
                title: "General",
                filters: [
                  {
                    dataKeys: ["videoFileNamesSmall"],
                    dataType: "boolean",
                    title: "Events with Video",
                  },
                  {
                    dataKeys: ["t_posix"],
                    dataType: "range",
                    width: 400,
                    title: "Time Period",
                    lookup: {
                      type: "time",
                      formatting: "short",
                    },
                  },
                ],
              },
              {
                title: "User 1",
                filters: [
                  {
                    dataKeys: ["class1"],
                    dataType: "discreet",
                    title: "Type",
                    lookup: {
                      type: "roadUsers",
                      formatting: "full",
                    },
                  },
                  {
                    dataKeys: ["arm1A"],
                    dataType: "discreet",
                    title: "Approach",
                    lookup: {
                      type: "approaches",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["arm1A", "arm1R"],
                    dataType: "discreet",
                    title: "Motion",
                    lookup: {
                      type: "motions",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["v1"],
                    dataType: "range",
                    width: 400,
                    title: "Speed",
                    lookup: {
                      type: "number",
                      formatting: 1,
                    },
                  },
                ],
              },
            ],
            table: {
              columns: [
                {
                  title: "Id",
                  data: [
                    {
                      dataKeys: ["eventId"],
                      type: "number",
                      formatting: 0,
                    },
                  ],
                  delimiter: "",
                  align: "left",
                  fontSize: "14px",
                  maxWidth: "",
                  minWidth: "10px",
                },
                {
                  title: "",
                  data: [
                    {
                      dataKeys: ["videoFileNamesSmall"],
                      type: "link",
                      formatting: "",
                    },
                  ],
                  align: "left",
                  fontSize: "14px",
                  maxWidth: "",
                  minWidth: "50px",
                },
                {
                  title: "Event Type",
                  data: [
                    {
                      dataKeys: ["class1"],
                      type: "roadUsers",
                      formatting: "short",
                    },
                  ],
                  delimiter: "-",
                  align: "left",
                  fontSize: "14px",
                  maxWidth: "",
                  minWidth: "10px",
                },
                {
                  title: "Motion",
                  data: [
                    {
                      dataKeys: ["arm1A"],
                      type: "approaches",
                      formatting: "namesFull",
                    },
                    {
                      dataKeys: ["arm1A", "arm1R"],
                      type: "motions",
                      formatting: "namesFull",
                    },
                  ],
                  delimiter: "-",
                  align: "left",
                  fontSize: "14px",
                  maxWidth: "",
                  minWidth: "50px",
                },
                {
                  title: "Speed (mph)",
                  data: [
                    {
                      dataKeys: ["v1"],
                      type: "number",
                      formatting: 1,
                    },
                  ],
                  align: "left",
                },
                {
                  title: "Time",
                  data: [
                    {
                      dataKeys: ["t_posix"],
                      type: "time",
                      formatting: "full",
                    },
                  ],
                  align: "left",
                  fontSize: "14px",
                  maxWidth: "",
                  minWidth: "100px",
                },
              ],
            },
            overview: {
              piechart: {
                title: "Road Users Involved",
              },
            },
            lookupPath: {
              piechart: {
                type: "roadUsers",
                formatting: "full",
              },
            },
          },
          {
            compID: 253,
            compType: 3,
            title:
              "Speeding Counts By Approach (Road Users going 10mph+ over limit)",
            styling: {
              dots: true,
            },
            settings: {
              quarterStatsTransformation: {
                type: "byApproach",
                countDuplicates: true,
              },
              hasDropdown: false,
            },
            databox: [
              {
                title: "Overview",
                stats: [
                  {
                    title: "Total Count",
                    type: "totalCount",
                  },
                  {
                    title: "Daily Avg",
                    type: "dailyAvg",
                  },
                ],
              },
              {
                title: "Peaks",
                stats: [
                  {
                    title: "Peak Hour (AM)",
                    type: "peakHour",
                    option: "am",
                  },
                  {
                    title: "Peak Hour (PM)",
                    type: "peakHour",
                    option: "pm",
                  },
                ],
              },
              {
                title: "Speed Limits",
                stats: [
                  {
                    title: "Speed Limit",
                    type: "speedLimit",
                  },
                ],
              },
            ],
            filtering: [
              {
                title: "General",
                filters: [
                  {
                    dataKeys: ["videoFileNamesSmall"],
                    dataType: "boolean",
                    title: "Events with Video",
                  },
                  {
                    dataKeys: ["t_posix"],
                    dataType: "range",
                    width: 400,
                    title: "Time Period",
                    lookup: {
                      type: "time",
                      formatting: "short",
                    },
                  },
                ],
              },
              {
                title: "User 1",
                filters: [
                  {
                    dataKeys: ["class1"],
                    dataType: "discreet",
                    title: "Type",
                    lookup: {
                      type: "roadUsers",
                      formatting: "full",
                    },
                  },
                  {
                    dataKeys: ["arm1A"],
                    dataType: "discreet",
                    title: "Approach",
                    lookup: {
                      type: "approaches",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["arm1A", "arm1R"],
                    dataType: "discreet",
                    title: "Motion",
                    lookup: {
                      type: "motions",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["v1"],
                    dataType: "range",
                    width: 400,
                    title: "Speed",
                    lookup: {
                      type: "number",
                      formatting: 1,
                    },
                  },
                ],
              },
            ],
            lookupPath: {
              dropdown: {
                type: "other",
                formatting: "count",
              },
              legend: {
                type: "approaches",
                formatting: "namesFull",
              },
              tooltip: {
                type: "approaches",
                formatting: "namesFull",
              },
            },
          },
        ],
      },
      {
        id: 26,
        path: "safety/intersection-blocking",
        title: "Intersection Blocking",
        icon: <FiTruck />,
        components: [
          {
            compID: 264,
            compType: 4,
            title: "Intersection Blocking TMC",
            styling: {
              divSize: 40,
              justifyContent: "center",
            },
            lookupPath: {
              combined: false,
              dropdown: {
                type: "special",
                formatting: "",
                specialLookup: {
                  1: "",
                },
              },
            },
          },
          {
            compID: 265,
            compType: 4,
            title: "Intersection Blocking bar plot by direction",
            styling: {
              divSize: 60,
              justifyContent: "center",
            },
            lookupPath: {
              combined: false,
              dropdown: {
                type: "special",
                formatting: "",
                specialLookup: {
                  1: "",
                },
              },
            },
          },
          {
            compID: 261,
            compType: 3,
            title: "Intersection Blocking (Opposing Light Is Green) Counts",
            styling: {
              dots: true,
            },
            settings: {
              quarterStatsTransformation: {
                type: "byEvent",
                countDuplicates: false,
              },
              hasDropdown: false,
            },
            databox: [
              {
                title: "Overview",
                stats: [
                  {
                    title: "Total Count",
                    type: "totalCount",
                  },
                  {
                    title: "Daily Avg",
                    type: "dailyAvg",
                  },
                ],
              },
              {
                title: "Peaks",
                stats: [
                  {
                    title: "Peak Hour (AM)",
                    type: "peakHour",
                    option: "am",
                  },
                  {
                    title: "Peak Hour (PM)",
                    type: "peakHour",
                    option: "pm",
                  },
                ],
              },
            ],
            filtering: [
              {
                title: "General",
                filters: [
                  {
                    dataKeys: ["videoFileNamesSmall"],
                    dataType: "boolean",
                    title: "Events with Video",
                  },
                  {
                    dataKeys: ["t_posix"],
                    dataType: "range",
                    width: 400,
                    title: "Time Period",
                    lookup: {
                      type: "time",
                      formatting: "short",
                    },
                  },
                ],
              },
              {
                title: "User 1",
                filters: [
                  {
                    dataKeys: ["class1"],
                    dataType: "discreet",
                    title: "Type",
                    lookup: {
                      type: "roadUsers",
                      formatting: "full",
                    },
                  },
                  {
                    dataKeys: ["arm1A"],
                    dataType: "discreet",
                    title: "Approach",
                    lookup: {
                      type: "approaches",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["arm1A", "arm1R"],
                    dataType: "discreet",
                    title: "Motion",
                    lookup: {
                      type: "motions",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["v1"],
                    dataType: "range",
                    width: 400,
                    title: "Speed",
                    lookup: {
                      type: "number",
                      formatting: 1,
                    },
                  },
                ],
              },
            ],
            lookupPath: {
              dropdown: {
                type: "other",
                formatting: "count",
              },
              legend: {
                type: "other",
                formatting: "count",
              },
              tooltip: {
                type: "other",
                formatting: "count",
              },
            },
          },
          {
            compID: 262,
            compType: 3,
            title: "Intersection Blocking Counts Per Approach",
            styling: {
              dots: true,
            },
            settings: {
              quarterStatsTransformation: {
                type: "byApproach",
                countDuplicates: true,
              },
              hasDropdown: false,
            },
            databox: [],
            filtering: [
              {
                title: "General",
                filters: [
                  {
                    dataKeys: ["videoFileNamesSmall"],
                    dataType: "boolean",
                    title: "Events with Video",
                  },
                  {
                    dataKeys: ["t_posix"],
                    dataType: "range",
                    width: 400,
                    title: "Time Period",
                    lookup: {
                      type: "time",
                      formatting: "short",
                    },
                  },
                ],
              },
              {
                title: "User 1",
                filters: [
                  {
                    dataKeys: ["class1"],
                    dataType: "discreet",
                    title: "Type",
                    lookup: {
                      type: "roadUsers",
                      formatting: "full",
                    },
                  },
                  {
                    dataKeys: ["arm1A"],
                    dataType: "discreet",
                    title: "Approach",
                    lookup: {
                      type: "approaches",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["arm1A", "arm1R"],
                    dataType: "discreet",
                    title: "Motion",
                    lookup: {
                      type: "motions",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["v1"],
                    dataType: "range",
                    width: 400,
                    title: "Speed",
                    lookup: {
                      type: "number",
                      formatting: 1,
                    },
                  },
                ],
              },
            ],
            lookupPath: {
              dropdown: {
                type: "other",
                formatting: "count",
              },
              legend: {
                type: "approaches",
                formatting: "namesFull",
              },
              tooltip: {
                type: "approaches",
                formatting: "namesFull",
              },
            },
          },
          {
            compID: 263,
            compType: 2,
            title: "Intersection Blocking Locations And Videos",
            styling: {
              dots: true,
              aling: "left",
              mapDefaults: {
                circle: true,
                lines: true,
              },
            },
            filtering: [
              {
                title: "General",
                filters: [
                  {
                    dataKeys: ["videoFileNamesSmall"],
                    dataType: "boolean",
                    title: "Events with Video",
                  },
                  {
                    dataKeys: ["t_posix"],
                    dataType: "range",
                    width: 400,
                    title: "Time Period",
                    lookup: {
                      type: "time",
                      formatting: "short",
                    },
                  },
                ],
              },
              {
                title: "User 1",
                filters: [
                  {
                    dataKeys: ["class1"],
                    dataType: "discreet",
                    title: "Type",
                    lookup: {
                      type: "roadUsers",
                      formatting: "full",
                    },
                  },
                  {
                    dataKeys: ["arm1A"],
                    dataType: "discreet",
                    title: "Approach",
                    lookup: {
                      type: "approaches",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["arm1A", "arm1R"],
                    dataType: "discreet",
                    title: "Motion",
                    lookup: {
                      type: "motions",
                      formatting: "namesFull",
                    },
                  },
                  {
                    dataKeys: ["v1"],
                    dataType: "range",
                    width: 400,
                    title: "Speed",
                    lookup: {
                      type: "number",
                      formatting: 1,
                    },
                  },
                ],
              },
            ],
            table: {
              columns: [
                {
                  title: "Id",
                  data: [
                    {
                      dataKeys: ["eventId"],
                      type: "number",
                      formatting: 0,
                    },
                  ],
                  delimiter: "",
                  align: "left",
                  fontSize: "14px",
                  maxWidth: "",
                  minWidth: "10px",
                },
                {
                  title: "",
                  data: [
                    {
                      dataKeys: ["videoFileNamesSmall"],
                      type: "link",
                      formatting: "",
                    },
                  ],
                  align: "left",
                  fontSize: "14px",
                  maxWidth: "",
                  minWidth: "10px",
                },
                {
                  title: "Event Type",
                  data: [
                    {
                      dataKeys: ["class1"],
                      type: "roadUsers",
                      formatting: "short",
                    },
                  ],
                  delimiter: "-",
                  align: "left",
                  fontSize: "14px",
                  maxWidth: "",
                  minWidth: "10px",
                },
                {
                  title: "Blocker Motion",
                  data: [
                    {
                      dataKeys: ["arm1A"],
                      type: "approaches",
                      formatting: "namesFull",
                    },
                    {
                      dataKeys: ["arm1A", "arm1R"],
                      type: "motions",
                      formatting: "namesFull",
                    },
                  ],
                  delimiter: "-",
                  align: "left",
                  fontSize: "14px",
                  maxWidth: "",
                  minWidth: "50px",
                },
                {
                  title: "Speed (mph)",
                  data: [
                    {
                      dataKeys: ["v1"],
                      type: "number",
                      formatting: 1,
                    },
                  ],
                  align: "left",
                },
                {
                  title: "Time",
                  data: [
                    {
                      dataKeys: ["t_posix"],
                      type: "time",
                      formatting: "full",
                    },
                  ],
                  align: "left",
                  fontSize: "14px",
                  maxWidth: "",
                  minWidth: "100px",
                },
              ],
            },
            overview: {
              piechart: {
                title: "Road Users Involved",
              },
            },
            lookupPath: {
              piechart: {
                type: "roadUsers",
                formatting: "full",
              },
            },
          },
        ],
      },
    ],
  },
];
