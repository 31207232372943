// design system, please keep naming


export const colors = {
  aggregated: "black",
  "n/a": "black",
  0: "#B2DF8A",
  1: "#FF7F00",
  2: "#1F78B4",
  3: "#33A02C",
  4: "#E31A1C",
  5: "#FB9A99",
  6: "#B15928",
  7: "#FDBF6F",
  8: "#6A3D9A",
  9: "#A6CEE3",
  10: "#FFFF99",
  11: "#B2DF8A",
  12: "CAB2D6",
  Left: "#FF7F00",
  "Slight Left": "#1F78B4",
  "Sharp Left": "#33A02C",
  Right: "#E31A1C",
  "Slight Right": "#FB9A99",
  "Sharp Right": "#B15928",
  Through: "#03989E",
  "U-Turn": "#6A3D9A",
  Wildcard: "#7187f1",
};
