function Nullify(value){

    const isInt = (n) => {
        return n % 1 === 0;
    }

    let newValue = isInt(value) ?  value : Number(value.toFixed(1))

    return newValue >= 0 ? newValue : null
}
export default Nullify;