export const Comp125 =    {
    compID: 125,
    compType: 4,
    title: "Speed Overview",
    styling: {
      divSize: 50,
      justifyContent: "center",
    },
    lookupPath: {
      combined: false,
      dropdown: {
        type: "special",
        formatting: "",
        specialLookup: {
          1: "AM Peak",
          2: "PM Peak",
          3: "All",
        },
      },
    },
  }