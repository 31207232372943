import React from "react";
import styled from "styled-components";
import { formatDate } from "../../Data/Handlers/utils/DateHelper";
import CompStyleWrapper from "../Studies/Comps/style_components/CompStyleWrapper";
import Downloadables from "./Downloadables";

export default ({ thisStudy, theme }) => {
  const intersection = thisStudy.intersection;


  return (
    <Wrapper>
      <Banner>
        <ImageWrapper>
          <ImageOverlay />
          <Image src={thisStudy.imageUrl} />
        </ImageWrapper>
        <Title>{intersection.intersection}</Title>
      </Banner>
      <Cards>
        <Card>
          <CompStyleWrapper key="stats" title="Overview" theme={theme}>
            <Stats>
              <Stat key={0}>
                <span style={{ fontWeight: "bold" }}>Study Start: </span>
                <span>{formatDate(thisStudy.study_start, "full")}</span>
              </Stat>
              <Stat key={1}>
                <span style={{ fontWeight: "bold" }}>Study End: </span>
                <span>{formatDate(thisStudy.study_end, "full")}</span>
              </Stat>
              <Stat key={2}>
                <span style={{ fontWeight: "bold" }}>Address: </span>
                <span>{intersection.formatted_address}</span>
              </Stat>
              <Spacer />
            </Stats>
          </CompStyleWrapper>
        </Card>

        {thisStudy.downloadables && (
          <Card>
            <CompStyleWrapper
              key="downloadables"
              title="Download Files"
              theme={theme}
            >
              <Downloadables
                downloadables={thisStudy.downloadables}
                theme={theme}
              />
            </CompStyleWrapper>
          </Card>
        )}
      </Cards>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ImageWrapper = styled.div`
  width: 100%;
  height: 319px;
  position:relative;
`;

const ImageOverlay = styled.div`
    z-index: 5;
   position:absolute;
  height: 100%;
  width: 100%;
    background-color: rgba(0,0,0,.3);

`;
const Image = styled.img`
  position:absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 60% 40%;
`;

const Banner = styled.div`
  max-height: 319px;
`;
const Stats = styled.div`
  display: flex;
  flex-direction: column;
`;

const Stat = styled.div`
  padding: 5px 10px;
  margin: 15px 25px 0px 25px;
  border-radius: 20px;
  background-color: #eee;
  text-align: center;
`;

const Spacer = styled.div`
  height: 50px;
`;

const Cards = styled.div`
  display: flex;
  justify-content: center;
`;

const Card = styled.div`
  flex: 50%;
`;

const Title = styled.div`
  z-index: 10;
  font-size: 40px;
  color: white;
  position: absolute;
  top: 290px;
  left: 100px;
`;

