import React, { useState, useEffect } from "react";
import styled from "styled-components";
import UpdateFirstLogin from "../../../Data/Fetching/QueryLibrary/UpdateFirstLogin";
import { useMutation } from "@apollo/react-hooks";
import Success from "../../Components/Success";

const View4 = ({ authUser, theme }) => {
  const [updateFirstLogin, {}] = useMutation(UpdateFirstLogin);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (authUser) {
      updateFirstLogin({
        variables: {
          toggle: false,
        },
      });
      setTimeout(() => {
        setRedirect(true);
        // window.location.reload();
      }, 2000);
    } else {
      setTimeout(() => {
        setRedirect(true);
      }, 2000);
    }
  }, []);

  if (redirect) {
    window.location.reload();
    // return <Redirect to="/" />
  }

  return (
    <>
      <Heading theme={theme}>You're all set!</Heading>
      <Success theme={theme} />
    </>
  );
};

const Heading = styled.div`
  padding-top: 25px;
  font-size: 28px;
  text-align: center;
`;

export default View4;
