export const Comp234 = {
  compID: 234,
  compType: 4,
  title: "Crossing on opposing green Crosswalk Count",
  styling: {
    divSize: 60,
    justifyContent: "center",
  },
  lookupPath: {
    combined: false,
    dropdown: {
      type: "special",
      formatting: "",
      specialLookup: {
        1: "",
      },
    },
  },
};
