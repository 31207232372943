import useGetPaginatedDataV2 from "../../../../Data/Fetching/useGetPaginatedDataV2";
import { useEffect, useState } from 'react';

const useLineChartController = ({ thisStudy, comp }) => {
  const { dataFormatted: data, loading, error } = useGetPaginatedDataV2({
    compID: comp.compID,
    thisStudy,
  });
  const [chartIndex, setChartIndex] = useState(0);
  const [volumeNormalizer, setvolumeNormalizer] = useState(false);
  const [activatedKeys, setActivatedKeys] = useState({});
  const [status, setStatus] = useState("loading");
  const {
    settings: { hasDropdown },
  } = comp;

  useEffect(() => {
    if (loading || (!data && !error)) {
      setStatus("loading");
    } else if (error || data === "empty") {
      setStatus("error");
    } else if (data) {
      setChartIndex(Object.keys(data).sort().reverse()[0]);
      setStatus("success");
    }
  }, [data, loading, error]);

  return {
    chartIndex,
    setChartIndex,
    data,
    status,
    hasDropdown,
    volumeNormalizer,
    setvolumeNormalizer,
    activatedKeys,
    setActivatedKeys
  }
}

export default useLineChartController;
