import React from "react";
import styled from "styled-components";

export default ({ resource }) => {
  const { file, name } = resource;
  return (
    <Downloadable href={file} download>
        {name}
    </Downloadable>
  );
};

const Downloadable = styled.a`
  flex: 0;
  display: flex;
  align-items:center;
  justify-content:center;
  height:30px;
  background-color:${({theme})=>theme.white};
  white-space: nowrap;
  border: 1px solid grey;
  border-radius: 20px;
  margin:.2em;
  font-size:.7em;
  cursor: pointer;
  padding:1em;

  &:hover {
    background-color: #eee;
  }


`;
