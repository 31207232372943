import React from "react";
import styled from "styled-components";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default ({ setIdx, allUsers, idx }) => {
  return (
    <SwitchUserDropdown value={idx} onChange={(e) => setIdx(e.target.value)}>
      {allUsers.map((user, i) => (
        <MenuItem key={i} value={i}>
          {user.client_name}
        </MenuItem>
      ))}
    </SwitchUserDropdown>
  );
};

const SwitchUserDropdown = styled(Select)`
  margin-top: 2em;
  margin-bottom: 2em;
  width: 60%;
`;
