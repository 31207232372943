import Naming from './Naming';
import _ from 'lodash'

const MotionOrder = (keys,thisStudy) => {

    // --------------------------------------------------------
    // takes a list of keys and reorders to a predefined order
    // --------------------------------------------------------

    let construct = []

    const ordering = {
        "Sharp Left":0,
        "Left":1,
        "Slight Left":2,
        "Through":3,
        "Slight Right":4,
        "Right":5,
        "Sharp Right":6,
        "U-Turn":7
    }

    const lookupPath = {
        generic:{
            type:"motions",
            formatting:"namesFull"
        }

    }

    keys.forEach(key=>{
        construct.push({
            index: ordering[Naming({thisStudy,lookupPath,key,part:"generic"})],
            value:key
        })
    })

    construct = _.sortBy(construct,'index').map(d=>d.value)

    return construct
}

export default MotionOrder;