import gql from "graphql-tag";

const QUERY = gql`
  query lines($study_id: ID!, $lastkey: lastkey) {
    lines(study_id: $study_id, lastkey: $lastkey, eventClass:[2],conditionals:[
      {
        key:"eventId",
        condition:"<=",
        value:1000
      }
    ] ) {
        data {
            row_id
            t
            u_id
            x_pix
            y_pix
            eventClass
            eventId
        }
        lastkey {
            study_id
            row_id
        }
    }
  }
`;

const dataHandler = data => data;

const q243lines= {
  QUERY,
  dataHandler
};

export default q243lines;
