import { useState, useEffect } from 'react';
import { flatten, pluck, pipe, map, filter, toPairs, fromPairs, reduce } from "ramda";
import { SiteStructure } from "../../SiteStructure";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const GET_STUDY = gql`
  query study($study_id: ID!) {
     study(study_id: $study_id) {
        study_id
        study_start
        study_end
        downloadables {
          file
          name
        }
        active_comps {
          comp
        }
        active_routes {
          route
        }
        show_on_viz
        imageUrl
        lookup {
          approaches {
            id
            namesAbbreviated
            namesFull
            namesShort
            sideEight
            sideFour
            viewName
          }
          motions {
            id
            medianSpeedMph
            motionAngles
            namesFull
            namesShort
          }
          other {
            id 
            count
          }
          roadUsers {
            id
            full
            short
          }
        }
        hideMenu
        intersection {
          intersection_id
          intersection
          all_streets {
            speed_limit
            street
          }
          formatted_address
        }
     } 
  }
`

const useStudiesRouterController = ({ study_id }) => {
  const { data } = useQuery(GET_STUDY, {
    variables: {
      study_id
    }
  });

  const [thisStudy, setThisStudy] = useState(null);
  const [routesToRender, setRoutesToRender] = useState(null);

  const studyWithTransformedLookup = pipe(
    x => ({
      ...x,
      lookup: pipe(
        toPairs,
        map(([key, array]) => [key, reduce((acc, val) => ({ ...acc, [val.id]: val }), {}, array)]),
        fromPairs
      )(x.lookup)
    })
  )


  // SET STUDY //
  useEffect(() => {
    if (data && data.study) {
      setThisStudy(studyWithTransformedLookup(data.study));
    }
  }, [data]);

  // SET ROUTES TO RENDER //
  useEffect(() => {
    if (thisStudy) {
      const activeComps = thisStudy.active_comps.map(x => Number(x.comp));
      const flattenRoutes = pipe(
        pluck("routes"),
        flatten,
        map(route => ({
          ...route,
          components: filter(comp => activeComps.includes(comp.compID))(route.components)
        })))

      const transformedRoutes = flattenRoutes(SiteStructure);


      setRoutesToRender(transformedRoutes);

    }
  }, [thisStudy])


  return {
    thisStudy,
    routesToRender
  }
}

export default useStudiesRouterController;
