import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Spinner from "../../../Components/Spinner";
import CompStyleWrapper from "../style_components/CompStyleWrapper";
import { ByColumn } from "../../../../Data/Handlers/DataHandler";
import { EventsFiltered } from "../../../../Data/Handlers/DataHandler";
import * as queries from "../../../../Data/Fetching/Queries";
import QuarterStatsify from "../../../../Data/Handlers/Events/QuarterStatsify";
import Chart from "./Chart";
import { Domain } from "../../../../Data/Handlers/utils/helpers";
import DataBox from "../LineChart/DataBox";
import FilterBar from "../LineChart/FilterBar";
import Filtering from "../Filtering";
import FilterError from "../Filtering/FilterError";
import _ from "lodash";
import useGetPaginatedDataV2 from "../../../../Data/Fetching/useGetPaginatedDataV2";

const SafetyLineChart = ({ comp, thisStudy, theme, authUser }) => {
  const [volumeNormalizer, setvolumeNormalizer] = useState(false);
  const [chartIndex, setChartIndex] = useState(null);
  const {
    dataFormatted: eventsData,
    eventsLoading,
    eventsError,
  } = useGetPaginatedDataV2({ compID: comp.compID, thisStudy });
  const dataHandler = queries[`q${comp.compID}`].extraDataHandler;
  const [eventsDataFiltered, setEventsDataFiltered] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [filters, setFilters] = useState(null);
  const [filterToggle, setfilterToggle] = useState(false);
  const [activatedKeys, setActivatedKeys] = useState({});
  const [status, setStatus] = useState("loading");
  const {
    settings: { hasDropdown },
  } = comp;

  // set data and initial filtering
  useEffect(() => {
    if (eventsLoading || (!eventsData && !eventsError)) {
      setStatus("loading");
    } else if (eventsError || eventsData === "empty") {
      setStatus("error");
    } else if (eventsData) {
      const fetchedFilters = ByColumn({
        data: eventsData,
        filtering: comp.filtering,
        thisStudy,
      });
      setFilters(fetchedFilters);
      setEventsDataFiltered(
        dataHandler(
          QuarterStatsify({
            data: EventsFiltered({
              data: eventsData,
              filters: fetchedFilters.applied,
              thisStudy,
            }),
            type: comp.settings.quarterStatsTransformation.type,
            countDuplicates:
              comp.settings.quarterStatsTransformation.countDuplicates,
          })
        )
      );
    }
  }, [
    eventsData,
    eventsLoading,
    eventsError,
    comp.filtering,
    comp.settings,
    dataHandler,
    thisStudy,
  ]);

  // set initial chartIndex
  useEffect(() => {
    if (eventsDataFiltered && !chartIndex) {
      setChartIndex(Object.keys(eventsDataFiltered)[0]);
      setStatus("success");
    }
  }, [eventsDataFiltered]);

  // update applied filters
  useEffect(() => {
    if (filters && eventsData) {
      setAppliedFilters(_.cloneDeep(filters.applied));
    }
  }, [filterToggle]);

  // update data on filters applied
  useEffect(() => {
    if (appliedFilters && eventsData) {
      setEventsDataFiltered(
        dataHandler(
          QuarterStatsify({
            data: EventsFiltered({
              data: eventsData,
              filters: appliedFilters,
              thisStudy,
            }),
            type: comp.settings.quarterStatsTransformation.type,
            countDuplicates:
              comp.settings.quarterStatsTransformation.countDuplicates,
          })
        )
      );
    }
  }, [appliedFilters, eventsData]);

  if (status === "error") {
    return <></>;
  }

  if (status === "loading") {
    return (
      <CompStyleWrapper theme={theme} title={`${comp.title}`}>
        <Spinner theme={theme} />
      </CompStyleWrapper>
    );
  }
  if (status === "success") {
    return (
      <CompStyleWrapper theme={theme} title={`${comp.title}`}>
        <Filtering
          currentFilters={filters}
          setFilters={setFilters}
          setfilterToggle={setfilterToggle}
          filterToggle={filterToggle}
          comp={comp}
          eventsData={eventsData}
          thisStudy={thisStudy}
          theme={theme}
          screenshotElement={window.document.getElementById(`linechart-${comp.compID}`)}
          allowScreenshot={authUser.admin}
        />
        <div id={`linechart-${comp.compID}`} style={{ margin: "10px" }}>
          {eventsDataFiltered !== "empty" && eventsDataFiltered[chartIndex] ? (
            <div>
              <DataBox
                theme={theme}
                data={
                  eventsDataFiltered === "empty"
                    ? []
                    : eventsDataFiltered[chartIndex]
                }
                databox={comp.databox}
                approach={hasDropdown ? chartIndex : null}
                thisStudy={thisStudy}
              />
              <FilterBar
                theme={theme}
                hasDropdown={hasDropdown}
                data={eventsDataFiltered}
                chartIndex={chartIndex}
                setChartIndex={setChartIndex}
                setvolumeNormalizer={setvolumeNormalizer}
                volumeNormalizer={volumeNormalizer}
                lookupPath={comp.lookupPath}
                activatedKeys={activatedKeys}
                setActivatedKeys={setActivatedKeys}
                thisStudy={thisStudy}
              />
              <Chart
                theme={theme}
                data={
                  eventsDataFiltered === "empty"
                    ? []
                    : eventsDataFiltered[chartIndex]
                }
                study={thisStudy}
                domainY={
                  eventsDataFiltered === "empty"
                    ? Domain([])
                    : Domain(eventsDataFiltered)
                }
                volumeNormalizer={volumeNormalizer}
                lookupPath={comp.lookupPath}
                activatedKeys={activatedKeys}
                thisStudy={thisStudy}
                comp={comp}
              />
            </div>
          ) : (
              <FilterError
                theme={theme}
                msg="No data, please adjust your filters."
              />
            )}
        </div>
      </CompStyleWrapper>
    );
  }
};

const ErrorMsg = styled.div`
  padding: 50px;
  text-align: center;
`;

export default SafetyLineChart;
