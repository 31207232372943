import React from "react";
import * as compose from "lodash.flowright";
import { graphql } from "react-apollo";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { TiUser } from "react-icons/ti";
import StudySwitcher from "./StudySwitcher";
import { FiHelpCircle } from "react-icons/fi";
import Tooltip from "@material-ui/core/Tooltip";
import GetAuth from "../../../Data/Fetching/QueryLibrary/GetAuth";
import LogOut from "../../../Data/Fetching/QueryLibrary/LogOut";
import Spinner from "../../Components/Spinner";

export default compose(
  graphql(GetAuth, {
    props: ({ data: { authStatus } }) => ({
      authStatus,
    }),
  }),
  graphql(LogOut, { name: "LogOut" })
)(
  withRouter(({ authStatus, LogOut, authUser, iframeView, theme }) => {
    if (iframeView) {
      return (
        <Wrapper>
          {/* help section */}
          <Tooltip title="Experiencing issues? Click here to shoot us an email.">
            <HelpBox>
              <a href="mailto:andrew@streetsimplified.com">
                <FiHelpCircle />
              </a>
            </HelpBox>
          </Tooltip>
        </Wrapper>
      );
    } else if (authStatus.status === "loggedOut") {
      return (
        <Wrapper>
          <Button>
            <Link to={"/"}>Login</Link>
          </Button>
        </Wrapper>
      );
    } else if (authStatus.status === "loggedIn" && !authUser) {
      return <Spinner theme={theme} />;
    } else {
      return (
        <Wrapper>
          {/* user logo & name */}
          <User>
            <TiUser />
            <UserName>
              <Link to="/">{authUser.client_name}</Link>
            </UserName>
            {/* logout button */}
            <Button
              theme={theme}
              onClick={() =>
                LogOut({
                  variables: {
                    index: "status",
                    value: "loggedOut",
                  },
                })
              }
            >
              Log out
            </Button>

            {/* conditional admin options */}
            {authUser.admin && !authUser.first_login ? (
              <Button theme={theme}>
                <Link to={`/admin/signup`}>New User</Link>
              </Button>
            ) : null}
          </User>

          {/* study information */}
          <Container>
            {/* <StudySwitcher authUser={authUser} /> */}
          </Container>

          {/* help section */}
          <Tooltip title="Experiencing issues? Click here to shoot us an email.">
            <HelpBox>
              <a href="mailto:andrew@streetsimplified.com">
                <FiHelpCircle />
              </a>
            </HelpBox>
          </Tooltip>
        </Wrapper>
      );
    }
  })
);

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 1100;
  padding: 0 10px;
  width: 100%;
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px -4px;
`;

const HelpBox = styled.div`
  flex-basis: 10% auto;
  padding: 10px;
`;

const User = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const UserName = styled.div`
  font-size: 0.8em;
  margin: 0 4px;
  opacity: 0.5;
`;

const Container = styled.div`
  flex-grow: 2;
  justify-content: flex-end;
  display: flex;
  padding: 10px;
`;

const Button = styled.div`
  padding: 7px 12px;
  border-radius: 100px;
  margin: 0 10px;
  font-size: 0.8em;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
    color: white;
    background: ${(props) => props.theme.gradient};
  }
`;
