import React from "react";
import styled from "styled-components";
import Intersection from "./Intersection";
import SwitchUserDropdown from "./SwitchUserDropdown";
import { useIntersectionCardsController } from './useintersectioncardscontroller';

export default ({ authUser, theme }) => {
  const { intersections, idx, setIdx, allUsers, showDropdown } = useIntersectionCardsController(authUser);


  return (
    <StyledIntersectionCards className="intersection-cards" theme={theme}>
      {showDropdown && (
        <SwitchUserDropdown
          theme={theme}
          setIdx={setIdx}
          allUsers={allUsers}
          idx={idx}
        />
      )}
      {intersections.length > 0 ? (
        <div className="intersection-cards__intersections">
          {intersections.map((intersection) => (
            <Intersection
              theme={theme}
              key={intersection.intersection_id}
              intersection={intersection}
            />
          ))}
        </div>
      ) : (
          <p>No studies found</p>
        )}
    </StyledIntersectionCards>
  );
};

const StyledIntersectionCards = styled.div`
  grid-area: 3/2/4/12;
`;
