import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import MainRouter from "./MainRouter";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { withClientState } from "apollo-link-state";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import Amplify, { Auth } from "aws-amplify";
import { persistCache } from "apollo-cache-persist";
import GetAuth from "./Data/Fetching/QueryLibrary/GetAuth";
import localForage from "localforage";
import { isIframe } from "./Data/Handlers/utils/helpers";
import HttpsRedirect from "react-https-redirect";

localForage.config({
  driver: localForage.WEBSQL, // Force WebSQL; same as using setDriver()
  name: "Street Simplified",
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: "streetsimplified", // Should be alphanumeric, with underscores.
  description: "some description",
});

const store = localForage.createInstance({
  name: "StreetSimplified",
});

function generateKeys(env) {

  if (env.NODE_ENV === "production" || env.REACT_APP_NODE_ENV === "production") {
    return {
      region: env.REACT_APP_REGION,
      userPoolId: env.REACT_APP_USER_POOL_ID_PROD,
      userPoolWebClientId: env.REACT_APP_USER_POOL_WEBCLIENT_ID_PROD,
      uri: env.REACT_APP_GRAPHQL_ENDPOINT_PROD2
    }
  }

  return {
    region: env.REACT_APP_REGION,
    userPoolId: env.REACT_APP_USER_POOL_ID_DEV,
    userPoolWebClientId: env.REACT_APP_USER_POOL_WEBCLIENT_ID_DEV,
    uri: env.REACT_APP_GRAPHQL_ENDPOINT_DEV2
  }
}

const { region, userPoolId, userPoolWebClientId, uri } = generateKeys(window._env_);

// configure Amplify
Amplify.configure({
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId
  },
});

class App extends Component {
  state = {
    client: null,
    loaded: false,
  };

  async componentDidMount() {
    const cache = new InMemoryCache();

    // Setup your Apollo Link, and any other Apollo packages here.

    const defaultState = {
      authStatus: {
        __typename: "authStatus",
        status: "loggedOut",
      },
      dpiSetting: {
        __typename: "dpiSetting",
        value: 2,
      },
      // expireCache: {
      //   __typename: "expireCache",
      //   timestamp: ""
      // }
    };

    const stateLink = withClientState({
      cache,
      defaults: defaultState,
      resolvers: {
        Mutation: {
          LogOut: async (_, { index, value }, { cache }) => {
            try {
              const userPayload = await Auth.signOut();

              // remove token
              localStorage.removeItem("auth-token");

              // update authStatus
              const previousState = cache.readQuery({ query: GetAuth });
              const data = {
                ...previousState,
                authStatus: {
                  ...previousState.authStatus,
                  [index]: value,
                },
              };
              cache.writeQuery({ query: GetAuth, data });
            } catch (err) {
              console.log(err);
            }

            return null;
          },
          LogIn: async (_, { email, password }, { cache }) => {
            // Login via Amplify
            try {
              const payload = await Auth.signIn({
                username: email,
                password: password,
              });

              // add token
              localStorage.setItem(
                "auth-token",
                payload.signInUserSession.idToken.jwtToken
              );

              // update authStatus
              const previousState = cache.readQuery({ query: GetAuth });
              // const expireCacheState = cache.readQuery({query: GetExpiration })

              const data = {
                ...previousState,
                authStatus: {
                  ...previousState.authStatus,
                  status: "loggedIn",
                },
              };

              cache.writeQuery({ query: GetAuth, data });
              localStorage.removeItem("login-error");
            } catch (err) {
              // handle issues
              localStorage.setItem("login-error", err.message);
              throw new Error("Not Authorized");
            }

            return null;
          },
          CleanUp: (_, { index }, { cache }) => {
            Object.keys(cache.data.data).forEach((key) => {
              if (
                key.match(/quarterStats/) ||
                key.match(/events/) ||
                key.match(/schematic/) ||
                key.match(/images/)
              ) {
                cache.data.delete(key);
              }
            });
            return null;
          },
          UpdateDPI: (_, { value }, { cache }) => {
            cache.writeData({
              data: {
                dpiSetting: {
                  __typename: "dpiSetting",
                  value: value,
                },
              },
            });
            return null;
          },
        },
      },
    });

    const authMiddleWare = new ApolloLink((operation, forward) => {
      //Refresh Token
      Auth.currentSession()
        .then((data) => {
          localStorage.setItem("auth-token", data.idToken.jwtToken);
        })
        .catch((err) => {
          localStorage.removeItem("auth-token");
          cache.writeData({
            data: {
              authStatus: {
                __typename: "authStatus",
                status: "loggedOut",
              },
            },
          });
        });

      const token = isIframe()
        ? localStorage.getItem("iframe-token")
        : localStorage.getItem("auth-token");
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      }));
      return forward(operation);
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      console.log(`error link`);
      if (graphQLErrors) {
        graphQLErrors.map(({ message, extensions }) => {
          if (extensions.code === "UNAUTHENTICATED") {
            // Refresh Token
            Auth.currentSession()
              .then((data) => {
                localStorage.setItem("auth-token", data.idToken.jwtToken);
              })
              .catch((err) => {
                localStorage.removeItem("auth-token");
                cache.writeData({
                  data: {
                    authStatus: {
                      __typename: "authStatus",
                      status: "loggedOut",
                    },
                  },
                });
              });
          }
        });
      } else if (networkError) {
        // handle networkErrors
        console.log(networkError);
      }
    });

    const client = new ApolloClient({
      link: ApolloLink.from([
        authMiddleWare,
        errorLink,
        stateLink,
        new HttpLink({
          uri
        }),
      ]),
      cache,
    });

    try {
      // See above for additional options, including other storage providers.
      await persistCache({
        cache,
        storage: store,
        maxSize: false,
      });
    } catch (error) {
      console.error("Error restoring Apollo cache", error);
    }

    this.setState({
      client,
      loaded: true,
    });
  }

  render() {
    const { client, loaded } = this.state;

    if (!loaded) {
      return <div></div>;
    }

    return (
      <HttpsRedirect>
        <ApolloProvider client={client}>
          {/* <ThemeProvider theme={theme}> */}
          <MainRouter />
          {/* </ThemeProvider> */}
        </ApolloProvider>
      </HttpsRedirect>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
