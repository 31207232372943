import React from "react";
import styled from "styled-components";
import { FaVideo } from "react-icons/fa";


const RowLink = ({ send, url }) => {

  if (url) {
    return (
      <VideoButton onClick={() => send({
        type: "OPEN",
        payload: {
          url
        }
      })}>
        <FaVideo size={20} />
      </VideoButton>
    );
  } else {
    return <></>
  }

};


const VideoButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`


export default RowLink;
