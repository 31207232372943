import React from "react";
import styled from "styled-components";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Naming from "../Utils/Naming";
import Legend from "./Legend";
import FilterError from "../Filtering/FilterError";

export default ({
  data,
  chartIndex,
  setChartIndex,
  lookupPath,
  activatedKeys,
  setActivatedKeys,
  thisStudy,
  hasDropdown,
  theme,
}) => {
  const views = Object.keys(data);
  const lines = data[chartIndex];

  if (!lines) {
    return <FilterError msg="No data, please adjust your filters." />;
  }

  return (
    <Wrapper>
      {hasDropdown && (
        <Select
          value={chartIndex}
          onChange={(e) => setChartIndex(e.target.value)}
          style={{ width: 200 }}
        >
          {views.map((chartName, i) => (
            <MenuItem key={i} value={chartName} theme={theme}>
              {Naming({
                thisStudy,
                lookupPath,
                key: chartName,
                part: "dropdown",
              })}
            </MenuItem>
          ))}
        </Select>
      )}

      <Spacer />
      <Legend
        lines={lines}
        thisStudy={thisStudy}
        lookupPath={lookupPath}
        activatedKeys={activatedKeys}
        setActivatedKeys={setActivatedKeys}
        theme={theme}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 7px 10px;
  margin-bottom: 10px;
  display: flex;
`;

const Spacer = styled.div`
  flex: 1;
`;
