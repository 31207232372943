import moment from 'moment'
import isTimestamp from '../utils/isTimestamp'

export default (timestamp) => {

    if (timestamp) {
        // is this a timestamp?
        if (isTimestamp(timestamp)) {
            // what's seconds?
            const sec = moment.unix(timestamp).utc().get('second');
            
            if (sec < 30) {
                return Math.floor(timestamp / 60) * 60
            }
                return (Math.floor(timestamp / 60) * 60)+60
        } else {
            return timestamp
        } 
    }
    return null
}