import React from "react";
import styled, { keyframes } from "styled-components";

export default function FadeIn({ children, order }) {
  return <Wrapper order={order}>{children}</Wrapper>;
}

const aniIn = keyframes`
    from {
      transform: translateY(10px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  `;

const Wrapper = styled.div`
  animation: ${aniIn} 600ms ease-out ${props => props.order * 300 + "ms"} both;
`;
