import React from "react";
import styled from "styled-components";
import { Stats } from "../../../../Data/Handlers/DataHandler";

const DataBox = ({ data, databox, thisStudy, approach, theme }) => {
  const generatedStats = Stats({
    data,
    databox,
    thisStudy,
    approach,
  });

  if (generatedStats) {
    return (
      <Wrapper>
        {generatedStats.map((row, i) => (
          <Row key={i}>
            <Subhead theme={theme} key={i}>
              {row.title}
            </Subhead>
            <table style={{ borderSpacing: "0" }}>
              <tbody>
                {row.stats.map((stat, i) => (
                  <Item key={i}>
                    <Td>{stat.title}: </Td>
                    <Td style={{ fontWeight: "bold" }}>{stat.data}</Td>
                  </Item>
                ))}
              </tbody>
            </table>
          </Row>
        ))}
      </Wrapper>
    );
  } else {
    return <></>;
  }
};

const Wrapper = styled.div`
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.tr`
  margin-right: 1rem;
`;

const Td = styled.td`
  margin-right: 1rem;
`;

const Row = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
`;

const Subhead = styled.div`
  font-weight: bold;
  margin-right: 1rem;
  border-radius: 20px;
  color: ${(props) => props.theme.primaryDark};
`;

export default DataBox;
