import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { formatDate } from "../../Data/Handlers/utils/DateHelper";

export default ({
  study,
  displayDownloadables,
  setDisplayDownloadables,
  hasDownloadables,
  theme,
}) => {
  return (
    <StudyInformation>
      <TextBox>
        <h3>Start: {formatDate(study.study_start, "fullWithYear")}</h3>
        <h3>End: {formatDate(study.study_end, "fullWithYear")}</h3>
      </TextBox>
      <Buttons>
        {hasDownloadables && (
          <Button
            theme={theme}
            onClick={() => setDisplayDownloadables(!displayDownloadables)}
            displayDownloadables={displayDownloadables}
          >
            Files
          </Button>
        )}
        <OpenLink theme={theme} to={`studies/${study.study_id}`}>
          Open Study
        </OpenLink>
      </Buttons>
    </StudyInformation>
  );
};

const StudyInformation = styled.div`
  padding: 1em;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
`;

const TextBox = styled.div`
  h3 {
    font-size: 1em;
    padding: 0.2em;
    margin: 0;
  }
`;

const Buttons = styled.div`
  flex: 0 1 16%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
`;

const Button = styled.div`
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  white-space: nowrap;
  font-size: 0.8em;
  color: ${({ theme }) => theme.textLight};
  background-color: ${({ theme }) => theme.secondaryDark};
  padding: 1em;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const OpenLink = styled(Link)`
  height: 30px;
  margin-left: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border-radius: 20px;
  white-space: nowrap;
  font-size: 0.8em;
  &:hover {
    opacity: 0.8;
  }
`;
